<script type="ts">
  import { metricIndices, sequenceMetrics } from '../../../../stores/stores';
  import { comparisonTrialData, trial } from '../../../../stores/trial';
  import { formatTime } from '../../../../utils/math';
  import LineChart from '../../charts/LineChart.svelte';
  import { chartSeriesColors } from '../../../../utils/charts';
  import type Highcharts from 'highcharts';
  import SeriesSelector from './components/SeriesSelector.svelte';
  import { frame, frames } from '../../../../stores/playerStore';

  export let data;
  let chartComponent: LineChart;

  const colors = chartSeriesColors();

  $: metrics = data.bma_metrics[1].Series;
  $: comparisonMetrics = $comparisonTrialData
    ? $comparisonTrialData.bma_metrics[1].Series
    : undefined;

  $: indexes = $sequenceMetrics.map(
    (_, i) => $metricIndices[$sequenceMetrics[i]]
  );

  $: series = buildSeries(indexes, metrics);
  $: comparisonSeries = comparisonMetrics
    ? buildSeries(indexes, comparisonMetrics)
    : undefined;

  $: yValues = buildYValues(series, $frame);

  $: comparisonYValues = comparisonSeries
    ? buildYValues(comparisonSeries, $frame)
    : undefined;

  // save values in local storage so metrics will default to previously-selected values
  $: localStorage.setItem('sequenceMetrics', JSON.stringify($sequenceMetrics));

  $: maxValues = series.map((it) => ({
    max: it.data.Max.toFixed(2),
    time: formatTime($trial.adjustFrameNumber(series[0].data.MaxF)),
  }));

  const config: Highcharts.Options = {
    yAxis: [
      {
        title: {
          text: 'Angular Velocity (deg/s)',
        },
      },
    ],
  };

  function buildSeries(serie, metric) {
    return serie.map(({ j, k }) => ({ data: metric[j].Series[k] }));
  }

  function buildYValues(serie, frame) {
    return serie.map((serie) => ({
      y: serie.data.Data[frame].toFixed(2),
      visible: true,
    }));
  }

  function handleSelect(e) {
    $sequenceMetrics[e.detail.index] = e.detail.value.Label; // this will store selected metric in local storage
    series[e.detail.index].data = JSON.parse(JSON.stringify(e.detail.value));
  }

  export function handleResize() {
    chartComponent.handleResize();
  }

</script>

<svelte:options accessors={true} />

<div class="chartContainer">
  <SeriesSelector
    {series}
    {comparisonSeries}
    {colors}
    {chartComponent}
    {metrics}
    {yValues}
    {comparisonYValues}
    on:select={handleSelect} />
  <div class="chart">
    <LineChart
      {series}
      {comparisonSeries}
      {colors}
      highchartsConfigOverride={config}
      bind:this={chartComponent}
      unit="ms" />
  </div>
</div>

<div class="peaks">
  <table>
    <tr>
      <th>Peak Value (deg/s)</th>
      {#each maxValues as val, index}
        <td>
          <span class="color" style="background: {colors[index]}" />
          <span>{val.max}</span>
        </td>
      {/each}
    </tr>
    <tr>
      <th>Peak Time</th>
      {#each maxValues as val, index}
        <td class="time"><span class="color" /> <span>{val.time}</span></td>
      {/each}
    </tr>
  </table>
</div>

<style>
  .chart {
    height: 70%;
  }

  .chartContainer {
    height: 100%;
  }

  .peaks {
    display: flex;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 8px 0px;
    background: var(--color-gray-extralight);
    border-top: 1px solid var(--color-gray-lighter);
    border-radius: 0 0 8px 0;
    font-size: var(--typography-size-smaller);
  }

  .peaks table {
    width: calc(100% - 32px);
    margin-left: 16px;
    border-collapse: collapse;
    line-height: 1rem;
  }

  .peaks th {
    width: 110px;
    padding-right: 8px;
    text-align: right;
    font-size: var(--typography-size-xsmall);
  }

  .color {
    display: inline-block;
    height: 8px;
    width: 8px;
    margin-right: 4px;
    border-radius: 4px;
  }

  .peaks tr {
    display: flex;
    justify-content: space-between;
  }

  .peaks td {
    display: flex;
    width: 16%;
    align-items: center;
    padding-right: 9px;
  }

  .peaks td.time {
    font-size: var(--typography-size-xsmall);
  }

  .peaks td.time span {
    padding-right: 2px;
  }

</style>
