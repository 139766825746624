<script lang="ts">
  import { createEventDispatcher } from 'svelte';
  import { JOINTS } from '../../../utils/config';
  import type { Plot } from '../../../utils/types';
  import { color } from '../../../utils/styles';

  const dispatch = createEventDispatcher();

  export let plots: Plot[];
  export let meshes: boolean;
  export let opaque: boolean;

  const paylaod = {
    index: 0,
    color: color('error-main'),
    scale: 1,
  };

</script>

<div class="wrapper">
  <div class="content">
    <div class="bg" />
    <div on:click={() => dispatch('close')} class="close">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
        <g
          fill="none"
          fill-rule="evenodd"
          stroke="#183E6D"
          stroke-width="2"
          transform="translate(1 1)">
          <line
            x1="1.24344979e-14"
            x2="15.4277843"
            y1="2.66453526e-15"
            y2="15.4277843"
            transform="matrix(-1 0 0 1 15.4278 0)" />
          <line
            x1="2.66453526e-15"
            x2="15.4277843"
            y1="2.66453526e-15"
            y2="15.4277843" />
        </g>
      </svg>
    </div>

    <section>
      <h2 class="typography-h2 border-bottom">Settings</h2>
      <div class="row pointer">
        <input
          type="checkbox"
          bind:checked={meshes}
          on:change={() => dispatch('meshes', meshes)} />
        <span>Show Mesh</span>
      </div>
      <div class="row pointer">
        <input
          type="checkbox"
          bind:checked={opaque}
          on:change={() => dispatch('opaque', opaque)} />
        <span>Opaque Mesh</span>
      </div>
    </section>

    <section class="section">
      <h2 class="typography-h2 border-bottom">Add Motion Path</h2>
      <div class="row">
        <select bind:value={paylaod.index}>
          {#each JOINTS as { name, index }}
            <option value={index}>{name}</option>
          {/each}
        </select>
        <input type="color" bind:value={paylaod.color} class="color" />
        <input type="range" min="-100" max="100" bind:value={paylaod.scale} />
        <button on:click={() => dispatch('plot', paylaod)}>Add Path</button>
      </div>
    </section>

    {#if plots.length}
      <section class="section">
        <h2 class="typography-h2">Active Motion Paths</h2>
        {#each plots as { label, color }, index}
          <div class="row plot">
            <div class="plot__color" style="background:{color};" />
            <div class="plot__label">{label}</div>
            <button
              class="delete"
              on:click={() => dispatch('remove', index)}>Remove</button>
          </div>
        {/each}
      </section>
    {/if}
  </div>
</div>

<style>
  .wrapper {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .bg {
    background-color: var(--color-gray-extralight);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    opacity: 0.95;
  }

  .content {
    position: relative;
    z-index: 1;
    height: 100%;
  }

  .close {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 16px;
    height: 16px;
  }

  section {
    padding: 60px;
    user-select: none;
  }
  section:not(:last-child) {
    padding-bottom: 0;
  }

  .border-bottom {
    border-bottom: 1px solid var(--color-gray-lighter);
  }

  .row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    user-select: none;
  }

  .row.pointer {
    cursor: pointer;
  }

  .row > * {
    margin-right: 30px;
    margin-bottom: 15px;
  }

  .plot__color {
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }

  .plot__label {
    min-width: 160px;
  }

  .color {
    min-width: 30px;
  }

</style>
