import { get } from 'svelte/store';
import { fps } from '../stores/playerStore';
import { add, apply, divide, reduce } from 'ramda';
export function normalize(value, min, max) {
    return (value - min) / (max - min);
}
export function pad(num) {
    num = num < 10 ? '0' + num : '' + num;
    return num;
}
export function clamp(min, max, v) {
    return Math.min(max, Math.max(min, v));
}
export function roundPad(value, decimal) {
    return parseFloat(String(Math.round(value * 100) / 100)).toFixed(decimal);
}
export function formatTime(frame) {
    let milliseconds;
    let minutes;
    let seconds;
    let hundredths;
    milliseconds = (frame * 1000) / get(fps); // get total milliseconds in video
    minutes = Math.floor(milliseconds / 1000 / 60);
    seconds = Math.floor((milliseconds - minutes * 1000 * 60) / 1000);
    hundredths = Math.floor((milliseconds - minutes * 1000 * 60 - seconds * 1000) / 100);
    milliseconds = Math.floor(milliseconds % 1000); // only leftover milliseconds
    const isMinutes = minutes > 0;
    // add leading zeros if necessary
    minutes = ('0' + minutes).slice(-2);
    seconds = ('0' + seconds).slice(-2);
    hundredths = ('0' + hundredths).slice(-2);
    milliseconds = ('00' + milliseconds).slice(-3);
    if (isMinutes) {
        return minutes + ':' + seconds + ':' + milliseconds;
    }
    else {
        return seconds + ':' + milliseconds;
    }
}
export const average = (a, b) => {
    if (a === undefined || b === undefined)
        return undefined;
    return (a + b) / 2;
};
export const listAverage = (numbers) => {
    return divide(reduce(add, 0, numbers), numbers.length);
};
export const maxValue = (data) => {
    return apply(Math.max, data);
};
