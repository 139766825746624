import { compose, evolve, find, findIndex, isNil, map, path, propEq, reject, } from 'ramda';
export function getDiscreteMetrics(data) {
    const metrics = {};
    data.bma_metrics.forEach((category) => {
        category.Series.forEach((point) => {
            point.Series.forEach((metric) => {
                metrics[metric.Label] = {
                    Min: metric.Min,
                    Max: metric.Max,
                    Units: metric.Units,
                };
            });
        });
    });
    return processMetrics(metrics);
}
const indicesToMetric = (trial, data) => ({ i, j, k }) => evolve({ Data: (d) => trial.trimFramesData(d) }, 
// Avoid crash when metric doesn't exist (i.e. when trial changes but metricIndices still doesn't)
path([i, 'Series', j, 'Series', k], data.bma_metrics));
export function updateSelectedMetrics({ selectedLiveMetrics, metricIndices, trial, data, }) {
    return compose(reject(isNil), map(indicesToMetric(trial, data)), reject(isNil), map((val) => metricIndices[val]))(selectedLiveMetrics);
}
export function findMetricByName(metricList, name) {
    return find(propEq('tabName', name))(metricList);
}
export function findMetricIndexByName(metricList, name) {
    return findIndex(propEq('tabName', name))(metricList);
}
function processMetrics(metrics) {
    let processedMetrics = {};
    try {
        processedMetrics = {
            // Angles
            'Ankle Flexion': {
                MinLeft: metrics['Left Ankle Flexion'].Min,
                MinRight: metrics['Right Ankle Flexion'].Min,
                MaxLeft: metrics['Left Ankle Flexion'].Max,
                MaxRight: metrics['Right Ankle Flexion'].Max,
                Units: metrics['Left Ankle Flexion'].Units,
            },
            'Knee Flexion': {
                MinLeft: metrics['Left Knee Flexion'].Min,
                MinRight: metrics['Right Knee Flexion'].Min,
                MaxLeft: metrics['Left Knee Flexion'].Max,
                MaxRight: metrics['Right Knee Flexion'].Max,
                Units: metrics['Left Knee Flexion'].Units,
            },
            'Knee Valgus': {
                MinLeft: metrics['Left Knee Valgus'].Min,
                MinRight: metrics['Right Knee Valgus'].Min,
                MaxLeft: metrics['Left Knee Valgus'].Max,
                MaxRight: metrics['Right Knee Valgus'].Max,
                Units: metrics['Left Knee Valgus'].Units,
            },
            'Hip Flexion': {
                MinLeft: metrics['Left Hip Flexion'].Min,
                MinRight: metrics['Right Hip Flexion'].Min,
                MaxLeft: metrics['Left Hip Flexion'].Max,
                MaxRight: metrics['Right Hip Flexion'].Max,
                Units: metrics['Left Hip Flexion'].Units,
            },
            'Hip Rotation': {
                MinLeft: metrics['Left Hip Rotation'].Min,
                MinRight: metrics['Right Hip Rotation'].Min,
                MaxLeft: metrics['Left Hip Rotation'].Max,
                MaxRight: metrics['Right Hip Rotation'].Max,
                Units: metrics['Left Hip Rotation'].Units,
            },
            'Hip Abduction': {
                MinLeft: metrics['Left Hip Abduction'].Min,
                MinRight: metrics['Right Hip Abduction'].Min,
                MaxLeft: metrics['Left Hip Abduction'].Max,
                MaxRight: metrics['Right Hip Abduction'].Max,
                Units: metrics['Left Hip Abduction'].Units,
            },
            'Shoulder Abduction': {
                MinLeft: metrics['Left Shoulder Abduction'].Min,
                MinRight: metrics['Right Shoulder Abduction'].Min,
                MaxLeft: metrics['Left Shoulder Abduction'].Max,
                MaxRight: metrics['Right Shoulder Abduction'].Max,
                Units: metrics['Left Shoulder Abduction'].Units,
            },
            'Shoulder Rotation': {
                MinLeft: metrics['Left Shoulder Rotation'].Min,
                MinRight: metrics['Right Shoulder Rotation'].Min,
                MaxLeft: metrics['Left Shoulder Rotation'].Max,
                MaxRight: metrics['Right Shoulder Rotation'].Max,
                Units: metrics['Left Shoulder Rotation'].Units,
            },
            'Shoulder Horizontal Abduction': {
                MinLeft: metrics['Left Shoulder Horizontal Abduction'].Min,
                MinRight: metrics['Right Shoulder Horizontal Abduction'].Min,
                MaxLeft: metrics['Left Shoulder Horizontal Abduction'].Max,
                MaxRight: metrics['Right Shoulder Horizontal Abduction'].Max,
                Units: metrics['Left Shoulder Horizontal Abduction'].Units,
            },
            'Elbow Flexion': {
                MinLeft: metrics['Left Elbow Flexion'].Min,
                MinRight: metrics['Right Elbow Flexion'].Min,
                MaxLeft: metrics['Left Elbow Flexion'].Max,
                MaxRight: metrics['Right Elbow Flexion'].Max,
                Units: metrics['Left Elbow Flexion'].Units,
            },
            'Spine Flexion': {
                Min: metrics['Spine Flexion'].Min,
                Max: metrics['Spine Flexion'].Max,
                Units: metrics['Spine Flexion'].Units,
            },
            'Spine Rotation': {
                Min: metrics['Spine Rotation'].Min,
                Max: metrics['Spine Rotation'].Max,
                Units: metrics['Spine Rotation'].Units,
            },
            'Spine Lateral Tilt': {
                Min: metrics['Spine Lateral Tilt'].Min,
                Max: metrics['Spine Lateral Tilt'].Max,
                Units: metrics['Spine Lateral Tilt'].Units,
            },
            'Pelvis Global Flexion': {
                Min: metrics['Pelvis Global Flexion'].Min,
                Max: metrics['Pelvis Global Flexion'].Max,
                Units: metrics['Pelvis Global Flexion'].Units,
            },
            'Pelvis Global Rotation': {
                Min: metrics['Pelvis Global Rotation'].Min,
                Max: metrics['Pelvis Global Rotation'].Max,
                Units: metrics['Pelvis Global Rotation'].Units,
            },
            'Pelvis Global Lateral Tilt': {
                Min: metrics['Pelvis Global Lateral Tilt'].Min,
                Max: metrics['Pelvis Global Lateral Tilt'].Max,
                Units: metrics['Pelvis Global Lateral Tilt'].Units,
            },
            'Trunk Global Flexion': {
                Min: metrics['Trunk Global Flexion'].Min,
                Max: metrics['Trunk Global Flexion'].Max,
                Units: metrics['Trunk Global Flexion'].Units,
            },
            'Trunk Global Rotation': {
                Min: metrics['Trunk Global Rotation'].Min,
                Max: metrics['Trunk Global Rotation'].Max,
                Units: metrics['Trunk Global Rotation'].Units,
            },
            'Trunk Global Lateral Tilt': {
                Min: metrics['Trunk Global Lateral Tilt'].Min,
                Max: metrics['Trunk Global Lateral Tilt'].Max,
                Units: metrics['Trunk Global Lateral Tilt'].Units,
            },
            // Angular Velocities
            'Ankle Flexion Velocity': {
                MinLeft: metrics['Left Ankle Flexion Velocity'].Min,
                MinRight: metrics['Right Ankle Flexion Velocity'].Min,
                MaxLeft: metrics['Left Ankle Flexion Velocity'].Max,
                MaxRight: metrics['Right Ankle Flexion Velocity'].Max,
                Units: metrics['Left Ankle Flexion Velocity'].Units,
            },
            'Knee Flexion Velocity': {
                MinLeft: metrics['Left Knee Flexion Velocity'].Min,
                MinRight: metrics['Right Knee Flexion Velocity'].Min,
                MaxLeft: metrics['Left Knee Flexion Velocity'].Max,
                MaxRight: metrics['Right Knee Flexion Velocity'].Max,
                Units: metrics['Left Knee Flexion Velocity'].Units,
            },
            'Hip Flexion Velocity': {
                MinLeft: metrics['Left Hip Flexion Velocity'].Min,
                MinRight: metrics['Right Hip Flexion Velocity'].Min,
                MaxLeft: metrics['Left Hip Flexion Velocity'].Max,
                MaxRight: metrics['Right Hip Flexion Velocity'].Max,
                Units: metrics['Left Hip Flexion Velocity'].Units,
            },
            'Hip Rotation Velocity': {
                MinLeft: metrics['Left Hip Rotation Velocity'].Min,
                MinRight: metrics['Right Hip Rotation Velocity'].Min,
                MaxLeft: metrics['Left Hip Rotation Velocity'].Max,
                MaxRight: metrics['Right Hip Rotation Velocity'].Max,
                Units: metrics['Left Hip Rotation Velocity'].Units,
            },
            'Hip Abduction Velocity': {
                MinLeft: metrics['Left Hip Abduction Velocity'].Min,
                MinRight: metrics['Right Hip Abduction Velocity'].Min,
                MaxLeft: metrics['Left Hip Abduction Velocity'].Max,
                MaxRight: metrics['Right Hip Abduction Velocity'].Max,
                Units: metrics['Left Hip Abduction Velocity'].Units,
            },
            'Shoulder Abduction Velocity': {
                MinLeft: metrics['Left Shoulder Abduction Velocity'].Min,
                MinRight: metrics['Right Shoulder Abduction Velocity'].Min,
                MaxLeft: metrics['Left Shoulder Abduction Velocity'].Max,
                MaxRight: metrics['Right Shoulder Abduction Velocity'].Max,
                Units: metrics['Left Shoulder Abduction Velocity'].Units,
            },
            'Shoulder Rotation Velocity': {
                MinLeft: metrics['Left Shoulder Rotation Velocity'].Min,
                MinRight: metrics['Right Shoulder Rotation Velocity'].Min,
                MaxLeft: metrics['Left Shoulder Rotation Velocity'].Max,
                MaxRight: metrics['Right Shoulder Rotation Velocity'].Max,
                Units: metrics['Left Shoulder Rotation Velocity'].Units,
            },
            'Shoulder Horizontal Abduction Velocity': {
                MinLeft: metrics['Left Shoulder Horizontal Abduction Velocity'].Min,
                MinRight: metrics['Right Shoulder Horizontal Abduction Velocity'].Min,
                MaxLeft: metrics['Left Shoulder Horizontal Abduction Velocity'].Max,
                MaxRight: metrics['Right Shoulder Horizontal Abduction Velocity'].Max,
                Units: metrics['Left Shoulder Horizontal Abduction Velocity'].Units,
            },
            'Elbow Flexion Velocity': {
                MinLeft: metrics['Left Elbow Flexion Velocity'].Min,
                MinRight: metrics['Right Elbow Flexion Velocity'].Min,
                MaxLeft: metrics['Left Elbow Flexion Velocity'].Max,
                MaxRight: metrics['Right Elbow Flexion Velocity'].Max,
                Units: metrics['Left Elbow Flexion Velocity'].Units,
            },
            'Spine Flexion Velocity': {
                Min: metrics['Spine Flexion Velocity'].Min,
                Max: metrics['Spine Flexion Velocity'].Max,
                Units: metrics['Spine Flexion Velocity'].Units,
            },
            'Spine Rotation Velocity': {
                Min: metrics['Spine Rotation Velocity'].Min,
                Max: metrics['Spine Rotation Velocity'].Max,
                Units: metrics['Spine Rotation Velocity'].Units,
            },
            'Spine Lateral Tilt Velocity': {
                Min: metrics['Spine Lateral Tilt Velocity'].Min,
                Max: metrics['Spine Lateral Tilt Velocity'].Max,
                Units: metrics['Spine Lateral Tilt Velocity'].Units,
            },
            'Pelvis Flexion Velocity': {
                Min: metrics['Pelvis Flexion Velocity'].Min,
                Max: metrics['Pelvis Flexion Velocity'].Max,
                Units: metrics['Pelvis Flexion Velocity'].Units,
            },
            'Pelvis Rotation Velocity': {
                Min: metrics['Pelvis Rotation Velocity'].Min,
                Max: metrics['Pelvis Rotation Velocity'].Max,
                Units: metrics['Pelvis Rotation Velocity'].Units,
            },
            'Pelvis Lateral Tilt Velocity': {
                Min: metrics['Pelvis Lateral Tilt Velocity'].Min,
                Max: metrics['Pelvis Lateral Tilt Velocity'].Max,
                Units: metrics['Pelvis Lateral Tilt Velocity'].Units,
            },
            'Trunk Flexion Velocity': {
                Min: metrics['Trunk Flexion Velocity'].Min,
                Max: metrics['Trunk Flexion Velocity'].Max,
                Units: metrics['Trunk Flexion Velocity'].Units,
            },
            'Trunk Rotation Velocity': {
                Min: metrics['Trunk Rotation Velocity'].Min,
                Max: metrics['Trunk Rotation Velocity'].Max,
                Units: metrics['Trunk Rotation Velocity'].Units,
            },
            'Trunk Lateral Tilt Velocity': {
                Min: metrics['Trunk Lateral Tilt Velocity'].Min,
                Max: metrics['Trunk Lateral Tilt Velocity'].Max,
                Units: metrics['Trunk Lateral Tilt Velocity'].Units,
            },
            'Foot Angular Velocity Magnitude': {
                MinLeft: metrics['Left Foot Angular Velocity Magnitude'].Min,
                MinRight: metrics['Right Foot Angular Velocity Magnitude'].Min,
                MaxLeft: metrics['Left Foot Angular Velocity Magnitude'].Max,
                MaxRight: metrics['Right Foot Angular Velocity Magnitude'].Max,
                Units: metrics['Left Foot Angular Velocity Magnitude'].Units,
            },
            'Shank Angular Velocity Magnitude': {
                MinLeft: metrics['Left Shank Angular Velocity Magnitude'].Min,
                MinRight: metrics['Right Shank Angular Velocity Magnitude'].Min,
                MaxLeft: metrics['Left Shank Angular Velocity Magnitude'].Max,
                MaxRight: metrics['Right Shank Angular Velocity Magnitude'].Max,
                Units: metrics['Left Shank Angular Velocity Magnitude'].Units,
            },
            'Thigh Angular Velocity Magnitude': {
                MinLeft: metrics['Left Thigh Angular Velocity Magnitude'].Min,
                MinRight: metrics['Right Thigh Angular Velocity Magnitude'].Min,
                MaxLeft: metrics['Left Thigh Angular Velocity Magnitude'].Max,
                MaxRight: metrics['Right Thigh Angular Velocity Magnitude'].Max,
                Units: metrics['Left Thigh Angular Velocity Magnitude'].Units,
            },
            'Bicep Angular Velocity Magnitude': {
                MinLeft: metrics['Left Bicep Angular Velocity Magnitude'].Min,
                MinRight: metrics['Right Bicep Angular Velocity Magnitude'].Min,
                MaxLeft: metrics['Left Bicep Angular Velocity Magnitude'].Max,
                MaxRight: metrics['Right Bicep Angular Velocity Magnitude'].Max,
                Units: metrics['Left Bicep Angular Velocity Magnitude'].Units,
            },
            'Forearm Angular Velocity Magnitude': {
                MinLeft: metrics['Left Forearm Angular Velocity Magnitude'].Min,
                MinRight: metrics['Right Forearm Angular Velocity Magnitude'].Min,
                MaxLeft: metrics['Left Forearm Angular Velocity Magnitude'].Max,
                MaxRight: metrics['Right Forearm Angular Velocity Magnitude'].Max,
                Units: metrics['Left Forearm Angular Velocity Magnitude'].Units,
            },
            // Linear Velocities
            'Toe Linear Velocity Magnitude': {
                MinLeft: metrics['Left Toe Linear Velocity Magnitude'].Min,
                MinRight: metrics['Right Toe Linear Velocity Magnitude'].Min,
                MaxLeft: metrics['Left Toe Linear Velocity Magnitude'].Max,
                MaxRight: metrics['Right Toe Linear Velocity Magnitude'].Max,
                Units: metrics['Left Toe Linear Velocity Magnitude'].Units,
            },
            'Heel Linear Velocity Magnitude': {
                MinLeft: metrics['Left Heel Linear Velocity Magnitude'].Min,
                MinRight: metrics['Right Heel Linear Velocity Magnitude'].Min,
                MaxLeft: metrics['Left Heel Linear Velocity Magnitude'].Max,
                MaxRight: metrics['Right Heel Linear Velocity Magnitude'].Max,
                Units: metrics['Left Heel Linear Velocity Magnitude'].Units,
            },
            'Ankle Linear Velocity Magnitude': {
                MinLeft: metrics['Left Ankle Linear Velocity Magnitude'].Min,
                MinRight: metrics['Right Ankle Linear Velocity Magnitude'].Min,
                MaxLeft: metrics['Left Ankle Linear Velocity Magnitude'].Max,
                MaxRight: metrics['Right Ankle Linear Velocity Magnitude'].Max,
                Units: metrics['Left Ankle Linear Velocity Magnitude'].Units,
            },
            'Knee Linear Velocity Magnitude': {
                MinLeft: metrics['Left Knee Linear Velocity Magnitude'].Min,
                MinRight: metrics['Right Knee Linear Velocity Magnitude'].Min,
                MaxLeft: metrics['Left Knee Linear Velocity Magnitude'].Max,
                MaxRight: metrics['Right Knee Linear Velocity Magnitude'].Max,
                Units: metrics['Left Knee Linear Velocity Magnitude'].Units,
            },
            'Hip Linear Velocity Magnitude': {
                MinLeft: metrics['Left Hip Linear Velocity Magnitude'].Min,
                MinRight: metrics['Right Hip Linear Velocity Magnitude'].Min,
                MaxLeft: metrics['Left Hip Linear Velocity Magnitude'].Max,
                MaxRight: metrics['Right Hip Linear Velocity Magnitude'].Max,
                Units: metrics['Left Hip Linear Velocity Magnitude'].Units,
            },
            'Pelvis Linear Velocity Magnitude': {
                Min: metrics['Pelvis Linear Velocity Magnitude'].Min,
                Max: metrics['Pelvis Linear Velocity Magnitude'].Max,
                Units: metrics['Pelvis Linear Velocity Magnitude'].Units,
            },
            'Shoulder Linear Velocity Magnitude': {
                MinLeft: metrics['Left Shoulder Linear Velocity Magnitude'].Min,
                MinRight: metrics['Right Shoulder Linear Velocity Magnitude'].Min,
                MinMid: metrics['Mid Shoulders Linear Velocity Magnitude'].Min,
                MaxLeft: metrics['Left Shoulder Linear Velocity Magnitude'].Max,
                MaxRight: metrics['Right Shoulder Linear Velocity Magnitude'].Max,
                MaxMid: metrics['Mid Shoulders Linear Velocity Magnitude'].Max,
                Units: metrics['Left Shoulder Linear Velocity Magnitude'].Units,
            },
            'Elbow Linear Velocity Magnitude': {
                MinLeft: metrics['Left Elbow Linear Velocity Magnitude'].Min,
                MinRight: metrics['Right Elbow Linear Velocity Magnitude'].Min,
                MaxLeft: metrics['Left Elbow Linear Velocity Magnitude'].Max,
                MaxRight: metrics['Right Elbow Linear Velocity Magnitude'].Max,
                Units: metrics['Left Elbow Linear Velocity Magnitude'].Units,
            },
            'Wrist Linear Velocity Magnitude': {
                MinLeft: metrics['Left Wrist Linear Velocity Magnitude'].Min,
                MinRight: metrics['Right Wrist Linear Velocity Magnitude'].Min,
                MaxLeft: metrics['Left Wrist Linear Velocity Magnitude'].Max,
                MaxRight: metrics['Right Wrist Linear Velocity Magnitude'].Max,
                Units: metrics['Left Wrist Linear Velocity Magnitude'].Units,
            },
            // Linear Accelerations
            'Toe Linear Acceleration Magnitude': {
                MinLeft: metrics['Left Toe Linear Acceleration Magnitude'].Min,
                MinRight: metrics['Right Toe Linear Acceleration Magnitude'].Min,
                MaxLeft: metrics['Left Toe Linear Acceleration Magnitude'].Max,
                MaxRight: metrics['Right Toe Linear Acceleration Magnitude'].Max,
                Units: metrics['Left Toe Linear Acceleration Magnitude'].Units,
            },
            'Heel Linear Acceleration Magnitude': {
                MinLeft: metrics['Left Heel Linear Acceleration Magnitude'].Min,
                MinRight: metrics['Right Heel Linear Acceleration Magnitude'].Min,
                MaxLeft: metrics['Left Heel Linear Acceleration Magnitude'].Max,
                MaxRight: metrics['Right Heel Linear Acceleration Magnitude'].Max,
                Units: metrics['Left Heel Linear Acceleration Magnitude'].Units,
            },
            'Ankle Linear Acceleration Magnitude': {
                MinLeft: metrics['Left Ankle Linear Acceleration Magnitude'].Min,
                MinRight: metrics['Right Ankle Linear Acceleration Magnitude'].Min,
                MaxLeft: metrics['Left Ankle Linear Acceleration Magnitude'].Max,
                MaxRight: metrics['Right Ankle Linear Acceleration Magnitude'].Max,
                Units: metrics['Left Ankle Linear Acceleration Magnitude'].Units,
            },
            'Knee Linear Acceleration Magnitude': {
                MinLeft: metrics['Left Knee Linear Acceleration Magnitude'].Min,
                MinRight: metrics['Right Knee Linear Acceleration Magnitude'].Min,
                MaxLeft: metrics['Left Knee Linear Acceleration Magnitude'].Max,
                MaxRight: metrics['Right Knee Linear Acceleration Magnitude'].Max,
                Units: metrics['Left Knee Linear Acceleration Magnitude'].Units,
            },
            'Hip Linear Acceleration Magnitude': {
                MinLeft: metrics['Left Hip Linear Acceleration Magnitude'].Min,
                MinRight: metrics['Right Hip Linear Acceleration Magnitude'].Min,
                MaxLeft: metrics['Left Hip Linear Acceleration Magnitude'].Max,
                MaxRight: metrics['Right Hip Linear Acceleration Magnitude'].Max,
                Units: metrics['Left Hip Linear Acceleration Magnitude'].Units,
            },
            'Pelvis Linear Acceleration Magnitude': {
                Min: metrics['Pelvis Linear Acceleration Magnitude'].Min,
                Max: metrics['Pelvis Linear Acceleration Magnitude'].Max,
                Units: metrics['Pelvis Linear Acceleration Magnitude'].Units,
            },
            'Shoulder Linear Acceleration Magnitude': {
                MinLeft: metrics['Left Shoulder Linear Acceleration Magnitude'].Min,
                MinRight: metrics['Right Shoulder Linear Acceleration Magnitude'].Min,
                MinMid: metrics['Mid Shoulders Linear Acceleration Magnitude'].Min,
                MaxLeft: metrics['Left Shoulder Linear Acceleration Magnitude'].Max,
                MaxRight: metrics['Right Shoulder Linear Acceleration Magnitude'].Max,
                MaxMid: metrics['Mid Shoulders Linear Acceleration Magnitude'].Max,
                Units: metrics['Left Shoulder Linear Acceleration Magnitude'].Units,
            },
            'Elbow Linear Acceleration Magnitude': {
                MinLeft: metrics['Left Elbow Linear Acceleration Magnitude'].Min,
                MinRight: metrics['Right Elbow Linear Acceleration Magnitude'].Min,
                MaxLeft: metrics['Left Elbow Linear Acceleration Magnitude'].Max,
                MaxRight: metrics['Right Elbow Linear Acceleration Magnitude'].Max,
                Units: metrics['Left Elbow Linear Acceleration Magnitude'].Units,
            },
            'Wrist Linear Acceleration Magnitude': {
                MinLeft: metrics['Left Wrist Linear Acceleration Magnitude'].Min,
                MinRight: metrics['Right Wrist Linear Acceleration Magnitude'].Min,
                MaxLeft: metrics['Left Wrist Linear Acceleration Magnitude'].Max,
                MaxRight: metrics['Right Wrist Linear Acceleration Magnitude'].Max,
                Units: metrics['Left Wrist Linear Acceleration Magnitude'].Units,
            },
        };
    }
    catch (error) {
        console.error('Error processing metrics: ', error);
    }
    return processedMetrics;
}
// metricIndices is a utility for getting the indices of the metrics in the JSON file.
// Example: metricIndices("Left Ankle Flexion") = {i: 0, j: 0, k: 0}
export function getMetricIndices(metrics) {
    const metricIndices = {};
    for (let i = 0; i < metrics.length; i++) {
        for (let j = 0; j < metrics[i].Series.length; j++) {
            for (let k = 0; k < metrics[i].Series[j].Series.length; k++) {
                metricIndices[metrics[i].Series[j].Series[k].Label] = {
                    i: i,
                    j: j,
                    k: k,
                };
            }
        }
    }
    return metricIndices;
}
