import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Text } from 'troika-three-text';
import tick from '../utils/tick';
import { frame } from '../stores/playerStore';
import { Vector3 } from 'three';
import { Character } from './character';
import { InfiniteGrid } from './grid';
const debug = ({ size = 0.04, name = 'Debug Point' } = {}) => {
    const mesh = new THREE.Mesh(new THREE.SphereBufferGeometry(size), new THREE.MeshNormalMaterial());
    mesh.name = name;
    return mesh;
};
const D1 = debug({ name: 'Debug 1' });
export class World {
    constructor(wrapper, data, trial, comparisonTrial) {
        this.comparisonTrial = comparisonTrial;
        // State
        this.frame = 0;
        this.characters = [];
        this.sizes = {
            width: 1,
            height: 1,
        };
        this.lookAt = new Vector3();
        this.lookAtTarget = new Vector3();
        this.numbers = [];
        // normalizde motion direction
        this.direction = new Vector3();
        // --- Event Handlers & Callbacks
        this.loop = (delta) => {
            this.characters.forEach((character) => {
                character.update(this.frame);
            });
            // Needed to get new look at positions berore moving camera.
            this.scene.updateMatrixWorld();
            if (this.cameraShouldLookAt) {
                this.lookAtTarget = this.defaultCharacter().localToWorld(this.defaultCharacter().headBase.vector);
                this.lookAt.lerp(this.lookAtTarget, 0.25);
                this.controls.target = this.lookAt;
            }
            if (this.cameraShouldFollow) {
                this.camera.position.x = this.defaultCharacter().headBase.vector.x;
            }
            this.numbers.forEach((n) => n.lookAt(this.camera.position));
            this.controls.update();
            this.renderer.render(this.scene, this.camera);
        };
        this.resize = () => {
            const size = this.wrapper.getBoundingClientRect();
            this.sizes.width = size.width;
            this.sizes.height = size.height;
            this.camera.aspect = size.width / size.height;
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(size.width, size.height);
        };
        this.wrapper = wrapper;
        this.data = data;
        this.trial = trial;
        this.unsubscribe = () => { };
        this.initRenderer();
        this.initScene();
        this.initKeypointsBoundingBox();
        this.initDirectionVector();
        this.initCamera();
        this.initWorld();
        this.initCharacters();
        this.initDistanceMarkers();
        this.initMetricMarkers();
        this.resize();
        this.initHandlers();
    }
    // --- Scene Init
    initRenderer() {
        const renderer = new THREE.WebGLRenderer();
        renderer.setPixelRatio(1.5);
        renderer.setSize(this.sizes.width, this.sizes.height);
        renderer.setClearColor(`#F1F1F1`);
        this.wrapper.appendChild(renderer.domElement);
        this.renderer = renderer;
    }
    initScene() {
        const scene = new THREE.Scene();
        this.scene = scene;
        const group = new THREE.Group();
        group.rotateX(Math.PI * 1.5);
        group.rotateZ(Math.PI); // Math video running direction. Run in opposit direction (-x)
        this.scene.add(group);
        this.group = group;
        window.scene = this.scene;
    }
    initCamera() {
        const camera = new THREE.PerspectiveCamera(75, this.sizes.width / this.sizes.height, 0.1, 100);
        camera.position.z = 5;
        camera.position.y = this.eyeLevel;
        // camera.position.x = this.boundingBoxSize.x / 2;
        this.camera = camera;
        this.scene.add(camera);
        const controls = new OrbitControls(this.camera, this.renderer.domElement);
        controls.enableDamping = true;
        controls.dampingFactor = 0.25;
        controls.minDistance = 0.5;
        controls.maxDistance = 25;
        controls.update();
        this.controls = controls;
    }
    initWorld() {
        const grid = new InfiniteGrid(1, 10, new THREE.Color(0x0068b6), 100);
        this.scene.add(grid);
        const axesHelper = new THREE.AxesHelper(5);
        // The X axis is red. The Y axis is green. The Z axis is blue.
        this.scene.add(axesHelper);
    }
    initHandlers() {
        this.unsubscribe = frame.subscribe((f) => (this.frame = f));
        tick.add(this.loop);
    }
    initKeypointsBoundingBox() {
        const geometry = new THREE.Geometry();
        for (const data of this.data['3D_keypoints_data']) {
            for (const [x, y, z] of data['3D_keypoints_data']) {
                geometry.vertices.push(new Vector3(x, y, z));
            }
        }
        geometry.computeBoundingBox();
        // Show bounding
        const helper = new THREE.Box3Helper(geometry.boundingBox, new THREE.Color(0xffff00));
        // this.group.add(helper);
        this.boundingBox = helper;
        // Position main in the center based on bounding box
        const size = geometry.boundingBox.max.clone().sub(geometry.boundingBox.min);
        this.boundingBoxSize = size;
        // Estimate eye-height from bounding box
        this.eyeLevel = size.z * 0.8;
    }
    initDirectionVector() {
        const size = this.boundingBoxSize;
        if (size.x > size.y && size.x > size.z) {
            this.direction = new Vector3(-1, 0, 0); // Remember, negative to match running directions
        }
        else if (size.y > size.x && size.y > size.z) {
            this.direction = new Vector3(0, 0, 1);
        }
        else {
            this.direction = new Vector3(0, 1, 0);
        }
    }
    initCharacters() {
        const mainCharacter = new Character(this.data, this.trial, 'matcap_blue3');
        this.characters = this.comparisonTrial
            ? [
                mainCharacter,
                new Character(this.comparisonTrial.data, this.comparisonTrial, 'matcap_blue2'),
            ]
            : [mainCharacter];
        this.characters.forEach((character) => {
            const dinamycOpacity = this.haveMultipleCharacters() ? 0.7 : 1;
            character.opacity(dinamycOpacity);
            character.translateToCenter();
            this.group.add(character);
        });
    }
    // TODO: Use SDF text
    initDistanceMarkers() {
        const EVERY = 5;
        const COUNT = 15;
        const txt = (string) => {
            const text = new Text();
            text.text = string;
            text.fontSize = 0.2;
            text.align = 'center';
            text.font = `assets/fonts/IntelClear_WLat_Rg.ttf`;
            text.color = 0x01285a;
            return text;
        };
        // let's start with 10 markers
        for (let i = 1; i < COUNT; i++) {
            const p1 = txt(`${i}`);
            p1.position.set(EVERY * i, 0, 0);
            this.scene.add(p1);
            const p2 = txt(`${i}`);
            p2.position.set(EVERY * -i, 0, 0);
            this.scene.add(p2);
            const p3 = txt(`${i}`);
            p3.position.set(0, 0, EVERY * -i);
            this.scene.add(p3);
            const p4 = txt(`${i}`);
            p4.position.set(0, 0, EVERY * i);
            this.scene.add(p4);
            this.numbers.push(p1, p2, p3, p4);
        }
    }
    async initMetricMarkers() {
        // This is not ideal. It's hardcoded because they come with the other metrics.
        // Should be it's own data property, but this is how we get the data for now.
        const peakAcc = this.data.metrics.find((m) => m.metrics_label === `location of peak acceleration`);
        const peakVel = this.data.metrics.find((m) => m.metrics_label === `location of peak velocity`);
        const txt = (string, color = 0x01285a) => {
            const text = new Text();
            text.text = string;
            text.fontSize = 0.2;
            text.align = 'center';
            text.font = `assets/fonts/IntelClear_WLat_Rg.ttf`;
            text.anchorX = `center`;
            text.position.z = -0.012;
            text.color = color;
            text.name = 'Label';
            text.rotation.x = -Math.PI * 0.5;
            text.rotation.z = Math.PI * 0.5;
            return text;
        };
        if (peakAcc) {
            const distance = peakAcc.metrics_data[0];
            const geometry = new THREE.PlaneGeometry(0.05, 2, 1);
            const material = new THREE.MeshBasicMaterial({
                color: 0x00ff00,
                side: THREE.DoubleSide,
            });
            const plane = new THREE.Mesh(geometry, material);
            plane.geometry.rotateX(Math.PI * 0.5);
            plane.position.x = -distance;
            plane.position.y = -0.01;
            this.scene.add(plane);
            const text = txt(peakAcc.metrics_label);
            text.position.x = -distance;
            this.scene.add(text);
        }
        if (peakVel) {
            const distance = peakVel.metrics_data[0];
            const geometry = new THREE.PlaneGeometry(0.05, 2, 1);
            const material = new THREE.MeshBasicMaterial({
                color: 0x00ff00,
                side: THREE.DoubleSide,
            });
            const plane = new THREE.Mesh(geometry, material);
            plane.geometry.rotateX(Math.PI * 0.5);
            plane.position.x = -distance;
            plane.position.y = -0.01;
            this.scene.add(plane);
            const text = txt(peakVel.metrics_label);
            text.position.x = -distance;
            this.scene.add(text);
        }
    }
    lookAtCamera() {
        this.controls.enableRotate = true;
        this.controls.enablePan = false;
        this.cameraShouldLookAt = true;
        this.cameraShouldFollow = false;
    }
    followCamera() {
        this.controls.enableRotate = false;
        this.controls.enablePan = false;
        this.cameraShouldLookAt = true;
        this.cameraShouldFollow = true;
    }
    defaultCamera() {
        this.controls.enableRotate = true;
        this.controls.enablePan = true;
        this.cameraShouldLookAt = false;
        this.cameraShouldFollow = false;
        this.lookAtTarget = this.lookAt.clone();
    }
    defaultCharacter() {
        return this.characters[0];
    }
    haveMultipleCharacters() {
        return this.characters.length > 1;
    }
    destroy() {
        frame.set(0);
        this.unsubscribe();
        tick.remove(this.loop);
        this.wrapper.removeChild(this.renderer.domElement);
    }
}
