<script type="ts">
  import { highcharts } from '../../../utils/highcharts';
  import { color } from '../../../utils/styles';

  interface LinearGaugeOptions {
    label: string;
    value: number;
    average: number;
  }

  export let data: LinearGaugeOptions;
  export let comparisonData: LinearGaugeOptions = undefined;
  export let unit = 'ms';

  $: handledData = [
    {
      data: [
        {
          target: 0,
          targetOptions: {
            color: color('highlight-main'),
          },
        },
      ],
    },
    {
      data: [
        {
          target: data.average,
          targetOptions: {
            color: color('accent-main'),
          },
        },
      ],
    },
    {
      data: [
        {
          target: Math.round(data.value),
          targetOptions: {
            color: color('secondary-main'),
            height: 5,
          },
        },
      ],
    },
  ].concat(
    comparisonData
      ? {
          data: [
            {
              target: Math.round(comparisonData.value),
              targetOptions: {
                color: color('primary-main'),
                height: 5,
              },
            },
          ],
        }
      : []
  );

  $: config = {
    chart: {
      inverted: true,
      marginLeft: 10,
      type: 'bullet',
      plotBackgroundColor: color('gray-extralight'),
      plotBorderColor: color('error-main'),
    },
    title: {
      text: null,
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      categories: [''],
      lineWidth: 0,
      tickWidth: 0,
      minorGridLineWidth: 0,
      gridLineWidth: 0,
    },
    yAxis: {
      min: -200,
      height: 20,
      gridLineWidth: 0,
      labels: {
        format: `{value}`,
      },
      title: null,
    },
    plotOptions: {
      series: {
        borderRadius: 10,
        pointPadding: 0.25,
        borderWidth: 0,
        color: color('black'),
        targetOptions: {
          height: 3,
          width: 70,
        },
      },
    },
    series: handledData,
    tooltip: {
      enabled: false,
    },
  };

</script>

<div class="linear-gauge-container">
  <div class="typography-h3 label">{data.label}</div>
  <div class="typographies-container">
    <div class="typography-h1 value">
      {Math.round(data.value)}
      <span class="unit">{unit}</span>
    </div>
    {#if comparisonData}
      <div class="typography-h1 divider">/</div>
      <div class="typography-h1 comparison-value">
        {Math.round(comparisonData.value)}
        <span class="unit">{unit}</span>
      </div>
    {/if}
  </div>
  <div>
    <div id="container" use:highcharts={config} class="linear-gauge " />
  </div>
  <div class="linear-gauge-legend-container">
    <span class="legend-tag" />
    Tour avg
  </div>
</div>

<style>
  .linear-gauge-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 300px;
    height: 240px;
    position: relative;
  }

  .typographies-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .label {
    display: flex;
    justify-content: center;
    margin-top: 5px;
  }

  .value {
    color: var(--color-secondary-main);
    display: flex;
    justify-content: center;
  }

  .comparison-value {
    color: var(--color-primary-main);
    display: flex;
    justify-content: center;
  }
  .unit {
    margin-left: 5px;
    font-size: var(--typography-size-regular);
    color: var(--color-gray-lighter);
    transform: rotate(-90deg);
  }

  .linear-gauge {
    height: 85px;
    min-width: 320px;
    max-width: 700px;
    margin: 1em auto;
  }

  .linear-gauge-legend-container {
    display: flex;
    align-items: center;
    z-index: 1;
    position: absolute;
    bottom: 10px;
    right: 0;
  }
  .legend-tag {
    height: 5px;
    width: 28px;
    background-color: var(--color-accent-main);
    margin-right: 5px;
  }
  .divider {
    color: var(--color-gray-lighter);
  }

</style>
