// Hoists a DOM node up to the top level of the DOM
// Useful for elements that need to float on top of everything else (e.g. modals, dropdowns, etc)
// if anchorNode is included, hoisted element will appear below anchorNode
export function hoist(node, anchorNode = null, align = 'bottom left', padding = 0) {
    let x, y;
    const parentRect = anchorNode.getBoundingClientRect();
    const nodeRect = node.getBoundingClientRect();
    if (anchorNode) {
        if (align.includes('right')) {
            x = parentRect.right - node.getBoundingClientRect().width;
        }
        else {
            x = parentRect.x;
        }
        if (align.includes('above')) {
            y = parentRect.top - nodeRect.height - padding;
        }
        else if (align.includes('bottom')) {
            y = parentRect.bottom + padding;
        }
        else {
            y = parentRect.top + padding;
        }
    }
    else {
        x = nodeRect.x;
        y = nodeRect.y + padding;
    }
    node.parentNode.removeChild(node);
    document.body.appendChild(node);
    node.style.left = `${x}px`;
    node.style.top = `${y}px`;
}
