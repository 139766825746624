<script>
  export let title = '';
  export let footSide = POSITIONS.left;
  export let height = '200px';
  export let width = '200px';

  const POSITIONS = { left: 'left', right: 'right' };
  const positionRotation = `${footSide === POSITIONS.left ? '-90' : '90'}deg`;

</script>

<div class="foot-icon-container">
  {#if title.length > 0 && footSide === POSITIONS.left}
    <span class="title" style={`transform: rotate(${positionRotation});`}>
      {title}
    </span>
  {/if}

  <img
    alt={`foot-${footSide}side`}
    src={`assets/images/golf/foot-${footSide}side.svg`}
    class="image-container"
    style={`height: ${height}; width: ${width};`} />

  {#if title.length > 0 && footSide === POSITIONS.right}
    <span
      class="title"
      style={`transform: rotate(${positionRotation}); margin-left: 35px;`}>
      {title}
    </span>
  {/if}
</div>

<style>
  .foot-icon-container {
    display: flex;
    flex-direction: row;
    width: 100px;
    align-items: center;
    z-index: 1;
  }

  .title {
    position: absolute;
    height: 120px;
  }

</style>
