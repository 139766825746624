<script lang="ts">
  import { onDestroy, onMount } from 'svelte';
  import { currentTime, frame } from '../../../stores/playerStore';
  import { trial, comparisonTrial } from '../../../stores/trial';
  import {
    addCurrentFrameAnnotation,
    addKeyFrameAnnotations,
    addOrReplaceAnnotation,
    addSerie,
    chartSeriesColors,
    getAnnotationById,
    getSerieByIndex,
    maxPointAnnotation,
    maxPointAnnotationId,
    Kind,
  } from '../../../utils/charts';
  import BMATooltip from '../panels/biomechanics/BMATooltip.svelte';
  import { isNil, mergeDeepRight } from 'ramda';
  import Highcharts from 'highcharts';
  import { createEventDispatcher } from 'svelte';
  import { color, typography } from '../../../utils/styles';
  import { formatTime } from '../../../utils/math';
  import type { Trial } from '../../../models/trial';

  interface LineChartSerie {
    data: {
      Data: any[];
      Label: string;
    };
  }

  const dispatch = createEventDispatcher();
  const { Main, Comparison } = Kind;

  export let highchartsConfigOverride: Highcharts.Options = {};
  export let series: LineChartSerie[];
  export let comparisonSeries: LineChartSerie[] = undefined;
  export let setYAxis = false;
  export let colors = chartSeriesColors();
  export let unit = 's';

  let chart: Highcharts.Chart;
  let chartDiv: HTMLElement;

  let tooltipVisible = false;
  let tooltipMax;
  let tooltipMaxFrame;
  let tooltipTrigger;

  // Adds the tooltip when hovering over max points on the data series.
  // Hovering over annotations currently not supported in the API -
  // found a workaround here: https://www.highcharts.com/forum/viewtopic.php?f=9&t=45692
  function addMaxTooltip(chart, id) {
    const annotation = getAnnotationById(chart, id);

    if (isNil(annotation)) {
      return;
    }

    annotation.graphic.element.onmouseenter = function () {
      tooltipMaxFrame = annotation.shapes[0].points[0].x;
      tooltipMax = annotation.shapes[0].points[0].y;
      tooltipTrigger = annotation.graphic.element;
      tooltipVisible = true;
    };

    annotation.graphic.element.onmouseleave = function () {
      tooltipVisible = false;
    };
  }

  function addMaxPointAnnotation(serie, kind, index, trial) {
    const annotation = maxPointAnnotation({
      index,
      kind,
      currentTime,
      trial,
      data: serie.data,
      color: colors[index],
      matchYAxisWithIndex: setYAxis,
    });

    addOrReplaceAnnotation(chart, annotation);

    addMaxTooltip(chart, annotation.id);
  }

  function setMaxPointAnnotationVisibility(
    kind: Kind,
    index: number,
    isVisible: boolean
  ) {
    const annotation = getAnnotationById(
      chart,
      maxPointAnnotationId(kind, index)
    );

    annotation.setVisibility(isVisible);
  }

  function cleanSeries() {
    // Don't kill the messenger. The Highcharts folks suggest this way to remove them all.
    while (chart.series.length) {
      chart.series[0].remove();
    }
  }

  function refreshChart(kind: Kind, series: LineChartSerie[], trial: Trial) {
    dispatch('refresh', chart);

    // This happens when a serie is removed from `series` array, but Highcharts doesn't notice that.
    if (chart.series.length > series.length) {
      cleanSeries();
    }

    series.forEach((serie, index) => {
      addSerie({
        chart,
        name: '',
        kind,
        data: serie.data,
        color: colors[index],
        dashStyle: kind === Main ? 'Solid' : 'Dash',
        trial,
        index,
        setYAxis,
      });

      addMaxPointAnnotation(serie, kind, index, trial);
    });
  }

  function removeComparisonSeries() {
    comparisonSeries.forEach((_, index) => {
      getSerieByIndex(chart, Comparison, index).remove();
      chart.removeAnnotation(maxPointAnnotationId(Comparison, index));
    });
  }

  export function handleResize() {
    chart.reflow();
  }

  const defaultConfig: Highcharts.Options = {
    chart: {
      borderWidth: 0,
      events: {
        click: function (e) {
          // @ts-expect-error xAxis attribute not recognized, but present.
          const frame = Math.floor(e.xAxis[0].value);
          currentTime.frame(frame);
        },
      },
      marginRight: 30,
      panKey: 'shift',
      panning: { enabled: true },
      plotBackgroundColor: color('gray-extralight'),
      plotBorderWidth: 1,
      spacingTop: 24,
      zoomType: 'x',
    },
    colors,
    credits: {
      enabled: false,
    },

    title: {
      text: '',
    },
    legend: {
      enabled: false,
    },

    plotOptions: {
      series: {
        label: {
          connectorAllowed: false,
        },
        lineWidth: 2,
        marker: {
          enabled: false,
        },
        point: {
          events: {
            click: function (_event) {
              currentTime.frame(this.x);
            },
          },
        },
        pointStart: 0, // Start of the x axis
        pointInterval: 1, // Interval of the x axis (we are using frames, so the interval is 1)
      },
    },

    series: [],

    tooltip: {
      borderWidth: 0,
      formatter: function () {
        let s = `
<span style="padding-left: 6px; padding-right: 6px">
              <b>${formatTime(this.x)}</b>
            </span>
          `;

        this.points.forEach((point) => {
          s += `
              <span style="color:${point.color};padding-right:6px">
                ${point.y.toFixed(2)}
              </span>
            `;
        });

        return s;
      },
      positioner: function (width, height, point) {
        let chart = this.chart;
        let position = {
          x: Math.min(
            Math.max(5, point.plotX + chart.plotLeft - width / 2),
            chart.chartWidth - width - 5
          ),
          y: chart.plotTop - height + 2,
        };
        return position;
      },
      padding: 0,
      shared: true,
      shadow: false,
      useHTML: true,
    },

    xAxis: {
      crosshair: {
        width: 2,
        color: color('gray-lighter'),
        dashStyle: 'ShortDot',
      },
      gridLineWidth: 1,
      labels: {
        formatter: function () {
          return formatTime(this.value);
        },
      },
      minPadding: 0,
      maxPadding: 0,
      title: {
        text: `Time (${unit})`,
        style: {
          display: 'flex',
          justifyContent: 'flex-start',
          // @ts-expect-error width attribute can be set as string but not supported by the typing.
          width: '100%',
          fontWeight: `${typography.weight('bold')}`,
          fontSize: `${typography.size('small')}`,
        },
      },
      type: 'datetime',
    },

    yAxis: [],
  };

  $: {
    if (chart && series) {
      refreshChart(Main, series, $trial);
    }
  }

  $: {
    if (chart && comparisonSeries) {
      if ($comparisonTrial) {
        refreshChart(Comparison, comparisonSeries, $comparisonTrial);
      } else {
        removeComparisonSeries();
      }
    }
  }

  $: {
    if (chart) {
      addCurrentFrameAnnotation(chart, $frame);
    }
  }

  $: {
    if (chart && comparisonSeries) {
      addCurrentFrameAnnotation(chart, $frame);
    }
  }

  onMount(() => {
    const config = mergeDeepRight(
      defaultConfig,
      highchartsConfigOverride
    ) as Highcharts.Options;

    if ($trial.hasKeyFrames) {
      addKeyFrameAnnotations(config, $trial.keyFrames);
    }

    chart = Highcharts.chart(chartDiv, config);
  });

  onDestroy(() => {
    chart.destroy();
  });

  export function toggleSerieVisibility(index: number) {
    [Main, Comparison].forEach((kind) => {
      const serie = getSerieByIndex(chart, kind, index);

      if (serie) {
        setMaxPointAnnotationVisibility(kind, index, !serie.visible);
        serie.setVisible(!serie.visible);
      }
    });
  }

</script>

<div class="line-chart" bind:this={chartDiv} />

{#if tooltipVisible}
  <BMATooltip
    trigger={tooltipTrigger}
    max={tooltipMax}
    maxframe={tooltipMaxFrame} />
{/if}

<style>
  .line-chart {
    height: 100%;
  }

  :global(.highcharts-tooltip > span) {
    background: var(--color-white);
    font-family: var(--typography-family);
    font-weight: var(--typography-weight-bold);
    border: 1px solid rgba(215, 215, 215, 1);
    border-radius: 4px;
    padding: 0px;
  }

</style>
