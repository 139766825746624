<script>
  import { createEventDispatcher } from 'svelte';

  export let items;

  const dispatch = createEventDispatcher();

  function handleSelect(item) {
    dispatch('select', {
      item: item,
    });
  }

</script>

<ul class="shadow">
  {#each items as item}
    <li on:click={handleSelect(item)} class={item.selected ? 'selected' : ''}>
      {item.text}
    </li>
  {/each}
</ul>

<style>
  li {
    border-left: 5px solid rgba(255, 255, 255, 0);
  }

  li.selected {
    border-left: 5px solid var(--color-secondary-main);
    background: var(--color-secondary-light);
  }

</style>
