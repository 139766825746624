<script lang="ts">
  import { fly } from 'svelte/transition';
  import { quintOut } from 'svelte/easing';
  import { createEventDispatcher } from 'svelte';

  export let error: boolean;

  const dispatch = createEventDispatcher();

  let username = '';
  let password = '';

</script>

<div
  class="wrapper"
  transition:fly={{ duration: 800, x: -500, opacity: 0, easing: quintOut }}>
  <div class="content">
    <img
      class="logo"
      src="/assets/images/logo-intel-white.svg"
      alt="Intel logo" />
    <h1 class="typography-h1 typography-h1-authentication">
      3D Athlete Tracking
    </h1>

    <div class="form">
      <div class="row username" class:error>
        <label for="username">
          <span class="icon">
            <img src="/assets/icons/avatar.svg" alt="Username icon" />
          </span>
        </label>
        <input
          bind:value={username}
          id="username"
          type="text"
          name="username"
          placeholder="username"
          required />
      </div>

      <div class="row password" class:error>
        <label for="password">
          <span class="icon">
            <img src="/assets/icons/lock.svg" alt="Password icon" />
          </span>
        </label>
        <input
          bind:value={password}
          type="password"
          name="password"
          placeholder="password"
          required />
      </div>

      <div class="row">
        <button
          type="submit"
          title="Login"
          on:click={() => dispatch('login', { username, password })}>
          <span class="label">Log in</span>
          <span class="icon icon--login">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              overflow="visible"
              viewBox="0 0 24 22">
              <path
                fill="#fff"
                d="M15 20h5c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2h-5V0h5c2.21 0 4 1.79 4 4v14c0 2.21-1.79 4-4 4h-5v-2zm0 0h5c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2h-5V0h5c2.21 0 4 1.79 4 4v14c0 2.21-1.79 4-4 4h-5v-2zm-1.2-10L9 5.48 10.57 4 18 11l-7.43 7L9 16.52 13.8 12H0v-2h13.8z" />
            </svg>
          </span>
        </button>
      </div>
      <div class="row">
        <button class="link" on:click={() => dispatch('reset')}>Reset Password</button>
      </div>
    </div>
  </div>
</div>

<style>
  .wrapper {
    background: url('/assets/images/hero.jpg');
    background-position: right;
    width: 100%;
    height: 100%;
    background-size: cover;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
  }

  .content {
    width: 380px;
    margin-bottom: 100px;
    margin-left: 80px;
  }

  .logo {
    width: 185px;
  }

  .typography-h1-authentication {
    color: var(--color-white);
    margin-bottom: 4rem;
    font-weight: var(--typography-weight-regular);
    white-space: nowrap;
    margin-top: 5px;
    line-height: 0.75;
  }

  .row {
    margin-bottom: 0.5rem;
    display: flex;
  }

  input,
  label,
  button {
    border: none;
    border-radius: 0;
    padding: 0;
  }

  input:focus,
  label:focus,
  button:focus {
    outline: none;
  }

  label {
    font-size: var(--typography-size-smaller);
    box-sizing: border-box;
  }

  label .icon {
    background: var(--color-primary-main);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s ease-in-out;
  }

  .error label .icon {
    background: var(--color-error-main);
  }

  label .icon img {
    padding: 22px;
  }

  input {
    flex-grow: 1;
    height: 70px;
    line-height: 70px;
    padding: 20px;
    padding-top: 25px;
    font-size: var(--typography-size-small);
    box-sizing: border-box;
    color: var(--color-primary-main);
  }

  input::placeholder {
    opacity: 0.5;
  }

  button {
    background-color: var(--color-primary-main);
    white-space: nowrap;
    height: auto;
    margin-top: 0.5em;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--typography-size-small);
    color: var(--color-white);
    cursor: pointer;
  }

  button.link {
    margin-top: 18px;
    background: none;
    color: var(--color-gray-lighter);
    font-size: var(--typography-size-smaller);
  }

  button.link:hover {
    color: var(--color-white);
  }

  button .label {
    min-width: 100px;
  }

  button .icon {
    background-color: var(--color-primary-dark) !important;
    display: block;
  }

  button .icon img {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 22px;
  }

  .icon--login {
    width: 70px;
    height: 70px;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
  .icon--login svg {
    width: 26px;
    height: 26px;
  }

</style>
