import { find, findLast } from 'ramda';
import { writable, get } from 'svelte/store';
import tick from '../utils/tick';
import { Trial } from '../models/trial';
import { trial } from './trial';
export let STEP;
export const currentTime = createCurrentTime();
export const paused = createPaused();
export const playbackRate = createPlaybackRate();
export const duration = writable(0);
export const frame = writable(0);
export const frames = writable(0);
export const fps = writable(0);
export const initialTime = writable(0);
let videos = [];
let videoPaused = true;
function timeToFrame(time) {
    const frame = Math.round(time * get(fps));
    return Math.min(frame, get(frames));
}
function frameToTime(frame) {
    return frame * STEP;
}
/**
 * First video of the trial is used as master driver to control time, frames and duration.
 */
export const initializeTrialPlayback = (data, recording) => {
    const { fps: framerate } = data.metadata;
    STEP = 1 / framerate;
    videos = data.videos.map((it) => it.element);
    const currentTrial = Trial.fromATPipelineData(data, recording);
    trial.set(currentTrial);
    const begin = frameToTime(currentTrial.firstFrame);
    videos.forEach((v) => (v.currentTime = begin));
    videos[0].loop = true;
    const framesCount = currentTrial.framesCount;
    initialTime.set(begin);
    duration.set(frameToTime(framesCount));
    fps.set(framerate);
    frames.set(framesCount);
};
function createCurrentTime() {
    const updateTime = (value) => {
        videos.forEach((v) => (v.currentTime = value + get(initialTime)));
        paused.set(true);
        return value;
    };
    const { subscribe, set, update } = writable(0);
    return {
        subscribe,
        set: (time, update = true) => {
            if (update && videos.length) {
                videos.forEach((v) => (v.currentTime = time + get(initialTime)));
            }
            set(time);
        },
        stepForward: () => update((n) => updateTime(Math.min(n + STEP, get(duration)))),
        stepBackward: () => update((n) => updateTime(Math.max(n - STEP, 0))),
        nextKeyFrame: (breakPointsKeys) => update((n) => updateTime(frameToTime(nextKeyFrame(timeToFrame(n), breakPointsKeys)))),
        previousKeyFrame: (breakPointsKeys) => update((n) => updateTime(frameToTime(previousKeyFrame(Math.round(timeToFrame(n)), breakPointsKeys)))),
        frame: (frame) => update((_) => updateTime(frameToTime(frame))),
        reset: () => update((_) => updateTime(0)),
        playbackFinished: () => boundedTime() >= get(duration),
    };
}
function createPaused() {
    const { subscribe, set } = writable(true);
    return {
        subscribe,
        set: (paused, update = true) => {
            if (videos.length && update && paused !== videoPaused)
                videos.forEach((v) => {
                    paused ? v.pause() : v.play();
                });
            videoPaused = paused;
            set(paused);
        },
    };
}
const nextKeyFrame = (currentKeyFrame, allKeyFrames) => find((it) => it > currentKeyFrame, allKeyFrames);
const previousKeyFrame = (currentKeyFrame, allKeyFrames) => findLast((it) => it < currentKeyFrame, allKeyFrames);
function createPlaybackRate() {
    const { subscribe } = writable({
        text: '1.0x',
        value: 1.0,
        selected: true,
    });
    return {
        subscribe,
        set: (playbackRate) => {
            if (videos.length) {
                videos.forEach((v) => {
                    v.playbackRate = playbackRate.value;
                });
            }
        },
    };
}
const boundedBetween = (min, max, value) => Math.min(max, Math.max(min, value));
function boundedTime() {
    return boundedBetween(0, get(duration), videos[0].currentTime - get(initialTime));
}
function ensurePaused() {
    if (!get(paused)) {
        paused.set(true);
    }
}
function updateCurrentTime() {
    if (!videos.length)
        return;
    const time = boundedTime();
    if (currentTime.playbackFinished()) {
        ensurePaused();
    }
    currentTime.set(time, false);
    frame.set(timeToFrame(time));
}
tick.add(updateCurrentTime);
