<script>
  export let title = '';
  export let tooltipClassName = '';

  let isHovered = false;

  function mouseOver() {
    isHovered = true;
  }

  function mouseLeave() {
    isHovered = false;
  }

</script>

{#if !title.length}
  <slot />
{:else}
  <div
    on:mouseover={mouseOver}
    on:mouseleave={mouseLeave}
    class={$$props.class ? $$props.class : '' + ' slot-container'}>
    <slot />

    {#if isHovered}
      <div class={tooltipClassName + ' tooltip'}>{title}</div>
    {/if}
  </div>
{/if}

<style>
  .slot-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tooltip {
    top: 25px;
    left: 25px;
    border: 1px solid #ddd;
    box-shadow: 1px 1px 1px #ddd;
    background: white;
    border-radius: 4px;
    padding: 4px;
    position: relative;
  }

</style>
