import { CognitoUserPool } from 'amazon-cognito-identity-js';
// DEV - Account #973825639808
export const REGION = 'eu-north-1';
export const USER_POOL_ID = 'eu-north-1_QORAB3OTw';
export const USER_POOL_CLIENT_ID = 'pgrk9ffvq5qb0cl9g49ruvdd4';
export const IDENTITY_POOL_ID = 'eu-north-1:3ebb8a6d-ba6c-47a4-ba71-9fda5de80596';
export const INPUT_FILE_BUCKET_NAME = '250529813905-eu-north-1-otg-input-videos';
export const OUTPUT_FILE_BUCKET_NAME = '250529813905-eu-north-1-otg-visualizer-output';
// PROD - Account #252312453031
// export const REGION = 'us-west-2';
// export const USER_POOL_ID = 'us-west-2_rTj1FQRWE';
// export const USER_POOL_CLIENT_ID = '792o3ien1ge29d3np017hc23e5';
// export const IDENTITY_POOL_ID = 'us-west-2:fd48d123-8e07-4200-8ec0-0b665829b648';
// export const INPUT_FILE_BUCKET_NAME = '252312453031-us-west-2-otg-input-videos';
// export const OUTPUT_FILE_BUCKET_NAME = '252312453031-us-west-2-otg-visualizer-output';
export const userPool = new CognitoUserPool({
    UserPoolId: USER_POOL_ID,
    ClientId: USER_POOL_CLIENT_ID,
});
export const OTG_LINK = `http://otg-3-otgf-ucadw23ku0kp.eba-xjrh2cm7.eu-north-1.elasticbeanstalk.com/login`;
// Joint Config
export const ANKLE_RIGHT = 0;
export const KNEE_RIGHT = 1;
export const HIP_RIGHT = 2;
export const HIP_LEFT = 3;
export const KNEE_LEFT = 4;
export const ANKLE_LEFT = 5;
export const PELVIS = 6;
export const NECK = 7;
export const HEADBASE = 8;
export const HEAD = 9;
export const WRIST_RIGHT = 10;
export const ELBOW_RIGHT = 11;
export const SHOULDER_RIGHT = 12;
export const SHOULDER_LEFT = 13;
export const ELBOW_LEFT = 14;
export const WRIST_LEFT = 15;
export const TOE_LEFT = 16;
export const TOE_RIGHT = 17;
export const HEEL_LEFT = 18;
export const HEEL_RIGHT = 19;
export const JOINTS = [
    { index: ANKLE_RIGHT, name: 'Ankle Right' },
    { index: KNEE_RIGHT, name: 'Knee Right' },
    { index: HIP_RIGHT, name: 'Hip Right' },
    { index: HIP_LEFT, name: 'Hip Left' },
    { index: KNEE_LEFT, name: 'Knee Left' },
    { index: ANKLE_LEFT, name: 'Ankle Left' },
    { index: PELVIS, name: 'Pelvis' },
    { index: NECK, name: 'Neck' },
    { index: HEADBASE, name: 'Head Base' },
    { index: HEAD, name: 'Head' },
    { index: WRIST_RIGHT, name: 'Wrist Right' },
    { index: ELBOW_RIGHT, name: 'Elbow Right' },
    { index: SHOULDER_RIGHT, name: 'Shoulder Right' },
    { index: SHOULDER_LEFT, name: 'Shoulder Left' },
    { index: ELBOW_LEFT, name: 'Elbow Left' },
    { index: WRIST_LEFT, name: 'Wrist Left' },
    { index: TOE_LEFT, name: 'Toe Left' },
    { index: TOE_RIGHT, name: 'Toe Right' },
    { index: HEEL_LEFT, name: 'Heel Left' },
    { index: HEEL_RIGHT, name: 'Heel Right' },
];
