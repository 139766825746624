<!-- This tooltip appears when hovering over the max values on the time series graph -->
<script>
  import { onMount } from 'svelte';
  import { formatTime } from '../../../../utils/math.ts';

  export let max;
  export let maxframe;
  export let trigger;

  let tooltip;

  onMount(() => {
    let rect = trigger.getBoundingClientRect();
    tooltip.style.left = `${rect.left}px`;
    tooltip.style.top = `${rect.top}px`;
  });

</script>

<div bind:this={tooltip} class="tooltip">
  <dl>
    <div>
      <dt>Peak Value:</dt>
      <dd>{max.toFixed(2)}</dd>
    </div>
    <div>
      <dt>Peak Time:</dt>
      <dd>{formatTime(maxframe)}</dd>
    </div>
  </dl>
</div>

<style>
  .tooltip {
    position: absolute;
    background: var(--color-white);
    border: 1px solid var(--color-gray-lighter);
    font-size: var(--typography-size-xsmall);
    border-radius: 4px;
    padding: 6px;
    top: 0;
    left: 0;

    /* 60px to the left because of sidebar - tooltip will look out of place in debug mode */
    transform: translate(calc(-50% - 60px), calc(-1 * (100% + 15px)));
  }

  .tooltip div {
    display: flex;
  }

  .tooltip dl {
    margin: 0;
  }

  .tooltip dt {
    display: inline;
    margin-right: 6px;
    width: 80px;
    text-align: left;
    font-size: var(--typography-size-xsmall);
    font-weight: var(--typography-weight-bold);
  }

  .tooltip dd {
    display: inline;
    margin: 0;
    text-align: left;
    font-size: var(--typography-size-xsmall);
    font-weight: var(--typography-weight-regular);
  }

</style>
