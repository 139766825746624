import { Object3D, Vector3, InstancedMesh, ArrowHelper, Group, Quaternion, } from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import * as THREE from 'three';
import { ANKLE_LEFT, ANKLE_RIGHT, ELBOW_LEFT, ELBOW_RIGHT, HEADBASE, HEAD, HEEL_LEFT, HEEL_RIGHT, HIP_LEFT, HIP_RIGHT, KNEE_LEFT, KNEE_RIGHT, NECK, PELVIS, SHOULDER_LEFT, SHOULDER_RIGHT, TOE_LEFT, TOE_RIGHT, WRIST_LEFT, WRIST_RIGHT, } from '../utils/config';
import { isEmpty, isNil } from 'ramda';
const textureLoader = new THREE.TextureLoader();
const V = new Vector3();
const UP = new Vector3(0, 1, 0);
const Q = new Quaternion();
const debug = ({ size = 0.04, name = 'Debug Point' } = {}) => {
    const mesh = new THREE.Mesh(new THREE.SphereBufferGeometry(size), new THREE.MeshNormalMaterial());
    mesh.name = name;
    return mesh;
};
const D1 = debug({ name: 'Debug 1' });
const D2 = debug({ name: 'Debug 2' });
const D3 = debug({ name: 'Debug 3' });
// https://stackoverflow.com/questions/31953608/rotate-object-on-specific-axis-anywhere-in-three-js-including-outside-of-mesh/32038265#32038265
class Joint extends InstancedMesh {
    constructor(index, key, data, material) {
        super(Joint.geometry, material, Joint.count);
        this.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
        this.index = index;
        this.key = key;
        this.data = data;
        this.name = key;
    }
    get initialPoint() {
        // Sometimes the joint is created without data, I guess due to concurrency issues.
        if (isEmpty(this.data)) {
            return undefined;
        }
        const [x, y, z] = this.data[0];
        return new Vector3(x, y, z);
    }
    update(frame) {
        const [x, y, z] = this.data[frame];
        Joint.dummy.position.set(x, y, z);
        Joint.dummy.updateMatrix();
        this.vector = Joint.dummy.position.clone();
        this.setMatrixAt(this.index, Joint.dummy.matrix);
        this.instanceMatrix.needsUpdate = true;
    }
}
Joint.geometry = new THREE.SphereBufferGeometry(0.035); // 0.035
Joint.dummy = new Object3D();
Joint.count = 28;
class Bone2 extends InstancedMesh {
    constructor(index, label, j1, j2, material) {
        super(Bone2.geometry, material, Bone2.count);
        this.instanceMatrix.setUsage(THREE.DynamicDrawUsage);
        this.index = index;
        this.label = label;
        this.j1 = j1;
        this.j2 = j2;
        this.name = label;
    }
    // https://github.com/mrdoob/three.js/blob/master/examples/webgl_instancing_dynamic.html
    // https://github.com/mrdoob/three.js/blob/master/examples/webgl_instancing_performance.html
    // https://stackoverflow.com/questions/42075007/align-boxgeometry-between-two-3d-points
    // https://stackoverflow.com/questions/9038465/three-js-object3d-cylinder-rotation-to-align-to-a-vector
    // https://answers.unity.com/questions/613373/rotating-an-object-between-2-points.html?_ga=2.187139240.1680516275.1600195071-710797113.1599762102
    update() {
        const dist = V.subVectors(this.j2.vector, this.j1.vector);
        const pos = this.j1.vector.clone().add(dist.clone().multiplyScalar(0.5));
        Bone2.dummy.position.copy(pos);
        Bone2.dummy.scale.setY(dist.length());
        Bone2.dummy.quaternion.setFromUnitVectors(UP, dist.normalize());
        Bone2.dummy.updateMatrix();
        this.setMatrixAt(this.index, Bone2.dummy.matrix);
        this.instanceMatrix.needsUpdate = true;
    }
}
Bone2.geometry = new THREE.CylinderBufferGeometry(0.01, 0.01, 1, 8, 1, true);
Bone2.dummy = new Object3D();
Bone2.count = 18;
const ArrowHelpersLength = 0;
export class Character extends Object3D {
    constructor(data, trial, bodyMaterial) {
        super();
        this.joints = [];
        this.bones = [];
        this.meshes = [];
        this.showMeshes = true;
        this.lookAtPoint = new Vector3();
        /**
         * Reusable helper utils used to calculate
         * mesh positions and rotations.
         */
        this.helperUtils = () => {
            const faceMaterial = new THREE.MeshBasicMaterial({ color: 0xff2080 });
            // Create a triangular geometry
            this.faceGeometry = new THREE.Geometry();
            this.faceGeometry.vertices.push(new THREE.Vector3(0, 0, 0));
            this.faceGeometry.vertices.push(new THREE.Vector3(0, 0, 0));
            this.faceGeometry.vertices.push(new THREE.Vector3(0, 0, 0));
            const face = new THREE.Face3(0, 1, 2, new THREE.Vector3(0, 0, 1), new THREE.Color(0xffaa00), 0);
            this.faceGeometry.faces.push(face);
            this.faceGeometry.computeFaceNormals();
            this.faceGeometry.computeVertexNormals();
            this.debugFaceMesh = new THREE.Mesh(this.faceGeometry, faceMaterial);
            this.debugFaceMesh.name = 'debugFaceMesh';
            this.add(this.debugFaceMesh);
            this.debugFaceMesh.visible = false;
            this.arrowY = new ArrowHelper(new Vector3(), new Vector3(), 2, 0xff0000);
            this.add(this.arrowY);
            this.arrowX = new ArrowHelper(new Vector3(), new Vector3(), 2, 0x0033ff);
            this.arrowX.name = 'arrowX';
            this.add(this.arrowX);
        };
        const matcapWhite = textureLoader.load('assets/images/textures/matcap_white.png');
        const matcapBlue = textureLoader.load(`assets/images/textures/${bodyMaterial}.png`);
        this.boneMaterial = new THREE.MeshMatcapMaterial({ matcap: matcapWhite });
        this.bodyMaterial = new THREE.MeshMatcapMaterial({
            matcap: matcapBlue,
            opacity: 1,
            transparent: true,
        });
        this.data = data;
        this.trial = trial;
        this.initJoints();
        this.initBones();
        const loader = new GLTFLoader();
        // // ADD DEBUG POINTS
        // this.add(D1);
        // this.add(D2);
        // this.add(D3);
        this.helperUtils();
        this.arrowX.visible = false;
        this.arrowY.visible = false;
        loader.load('assets/models/head.glb', (gltf) => {
            const mesh = gltf.scene.children[0].clone();
            mesh.material = this.bodyMaterial;
            mesh.name = 'Head Mesh';
            const group = new THREE.Group();
            group.add(mesh);
            group.name = 'Head Parent';
            this.head = group;
            this.meshes.push(this.head);
            this.head.rotation.z = Math.PI / 2;
            this.head.position.z = 0.01;
            this.head.position.x = -0.02;
            this.headParent = new Group();
            this.headParent.add(group);
            this.headPosArrow = new ArrowHelper(new Vector3(), new Vector3(), ArrowHelpersLength, 0xff0000);
            this.headPosArrow.name = 'Head Position Arrow';
            this.headPosArrow.add(this.headParent);
            this.add(this.headPosArrow);
            this.lookAtPointHead = new Vector3();
        });
        loader.load('assets/models/chest.glb', (gltf) => {
            const chestGroup = gltf.scene.children[0];
            const mesh = gltf.scene.children[0].getObjectByName('chest');
            mesh.material = this.bodyMaterial;
            mesh.name = 'Chest Mesh';
            const group = new THREE.Group();
            group.add(chestGroup);
            group.name = 'Chest Parent';
            this.chest = group;
            this.meshes.push(this.chest);
            this.chest.position.x = -0.04;
            this.chest.position.z = -0.1;
            this.chest.rotation.x = -Math.PI / 2; // Use these two if aligning with hip center point.
            this.chest.rotation.y = -Math.PI / 2; // Use these two if aligning with hip center point.
            // this.chest.rotation.y = Math.PI / 2; // Use this one if aligning with shoulders top edge.
            this.chestParent = new Group();
            this.chestParent.add(group);
            this.chestPosArrow = new ArrowHelper(new Vector3(), new Vector3(), ArrowHelpersLength, 0x000000);
            this.chestPosArrow.name = 'Chest Position Arrow';
            this.chestPosArrow.add(this.chestParent);
            this.add(this.chestPosArrow);
            this.lookAtPointChest = new Vector3();
        });
        const standardBone = (gltf) => {
            const mesh = gltf.scene.children[0].clone();
            mesh.rotation.set(0, 0, 0);
            mesh.position.set(0, 0, 0);
            mesh.material = this.bodyMaterial;
            const box3 = new THREE.Box3();
            box3.setFromObject(mesh);
            // make a BoxBufferGeometry of the same size as Box3
            const dimensions = new THREE.Vector3().subVectors(box3.max, box3.min);
            const boxGeo = new THREE.BoxBufferGeometry(dimensions.x, dimensions.y, dimensions.z);
            // move new mesh center so it's aligned with the original object
            const matrix = new THREE.Matrix4().setPosition(dimensions.addVectors(box3.min, box3.max).multiplyScalar(0.5));
            boxGeo.applyMatrix4(matrix);
            // make a mesh
            const parent = new THREE.Mesh(boxGeo, new THREE.MeshBasicMaterial({
                color: 0xff0000,
                wireframe: true,
                visible: false,
            }));
            parent.name = `Parent ${mesh.name}`;
            parent.geometry.computeBoundingBox();
            parent.add(mesh);
            this.add(parent);
            this.meshes.push(parent);
            return parent;
        };
        loader.load('assets/models/arm_top.glb', (gltf) => {
            const left = standardBone(gltf);
            left.children[0].rotation.z = Math.PI * 0.5;
            left.children[0].rotation.y = -Math.PI * 0.5;
            this.add(left);
            this.armTopLeft = left;
            this.meshes.push(this.armTopLeft);
            const right = standardBone(gltf);
            right.children[0].rotation.z = Math.PI * 0.5;
            right.children[0].rotation.y = -Math.PI * 0.5;
            this.add(right);
            this.armTopRight = right;
            this.meshes.push(this.armTopRight);
        });
        loader.load('assets/models/arm_bottom.glb', (gltf) => {
            const left = standardBone(gltf);
            left.children[0].rotation.z = Math.PI * 0.5;
            this.add(left);
            this.armBottomLeft = left;
            this.meshes.push(this.armBottomLeft);
            const right = standardBone(gltf);
            right.children[0].rotation.z = Math.PI * 0.5;
            this.add(right);
            this.armBottomRight = right;
            this.meshes.push(this.armBottomRight);
        });
        loader.load('assets/models/leg_top.glb', (gltf) => {
            const left = standardBone(gltf);
            left.children[0].rotation.x = -Math.PI * 0.5;
            left.children[0].rotation.z = -Math.PI * 0.5;
            left.children[0].position.y = 0.025;
            this.add(left);
            this.legTopLeft = left;
            this.meshes.push(this.legTopLeft);
            const right = standardBone(gltf);
            right.children[0].rotation.x = -Math.PI * 0.5;
            right.children[0].rotation.z = -Math.PI * 0.5;
            right.children[0].position.y = 0.025;
            this.add(right);
            this.legTopRight = right;
            this.meshes.push(this.legTopRight);
        });
        loader.load('assets/models/leg_bottom.glb', (gltf) => {
            const left = standardBone(gltf);
            left.children[0].rotation.x = -Math.PI * 0.5;
            this.add(left);
            this.legBottomLeft = left;
            this.meshes.push(this.legBottomLeft);
            const right = standardBone(gltf);
            right.children[0].rotation.x = -Math.PI * 0.5;
            this.add(right);
            this.legBottomRight = right;
            this.meshes.push(this.legBottomRight);
        });
        loader.load('assets/models/hip.glb', (gltf) => {
            const mesh = gltf.scene.children[0].clone();
            mesh.material = this.bodyMaterial;
            mesh.name = 'Hip Mesh';
            const group = new THREE.Group();
            group.add(mesh);
            group.name = 'Hip Parent';
            this.hip = group;
            this.hip.position.z = 0.07;
            this.hip.rotation.y = Math.PI;
            this.hip.rotation.z = -Math.PI / 2;
            this.hipParent = new Group();
            this.hipParent.add(group);
            this.hipPosArrow = new ArrowHelper(new Vector3(), new Vector3(), ArrowHelpersLength, 0x000000);
            this.hipPosArrow.name = 'Hip Position Arrow';
            this.hipPosArrow.add(this.hipParent);
            this.add(this.hipPosArrow);
            this.meshes.push(this.hipPosArrow);
            this.lookAtPointHip = new Vector3();
        });
        loader.load('assets/models/foot.glb', (gltf) => {
            const mesh = gltf.scene.children[0];
            mesh.material = this.bodyMaterial;
            mesh.name = 'Foot Mesh';
            mesh.rotation.y = Math.PI;
            mesh.rotation.z = -Math.PI;
            mesh.position.z = 0.18;
            mesh.position.y = -0.01;
            const group = new Group();
            group.add(mesh);
            group.rotation.z = Math.PI / 2;
            group.position.z = -0.105;
            this.leftFoot = new ArrowHelper(new Vector3(), new Vector3(), ArrowHelpersLength, 0x0000ff);
            this.leftFootParent = group.clone();
            this.leftFootParent.name = 'Foot Left';
            this.leftFoot.name = 'Left Foot Parent';
            this.leftFoot.add(this.leftFootParent);
            this.add(this.leftFoot);
            this.meshes.push(this.leftFoot);
            this.rightFoot = new ArrowHelper(new Vector3(), new Vector3(), ArrowHelpersLength, 0x0000ff);
            this.rightFootParent = group.clone();
            this.rightFootParent.name = 'Foot Right';
            this.rightFoot.name = 'Right Foot Parent';
            this.rightFoot.add(this.rightFootParent);
            this.add(this.rightFoot);
            this.meshes.push(this.rightFoot);
            // Flip one mesh
            this.rightFootParent.children[0].applyMatrix4(new THREE.Matrix4().makeScale(-1, 1, 1));
        });
    }
    initJoints() {
        for (let i = 0; i < this.data['3D_keypoints_data'].length; i++) {
            const data = this.data['3D_keypoints_data'][i];
            const joint = new Joint(i, data['3D_keypoints_label'], this.trial.trimFramesData(data['3D_keypoints_data']), this.boneMaterial);
            this.add(joint);
            this.joints.push(joint);
        }
        // this.joints[NECK].visible = false;
        this.joints[HEADBASE].visible = false;
        // this.joints[PELVIS].visible = false;
        this.joints[HEEL_LEFT].visible = true;
        this.joints[HEEL_RIGHT].visible = true;
        this.joints[TOE_LEFT].visible = true;
        this.joints[TOE_RIGHT].visible = true;
        this.lookAtPoint = this.joints[PELVIS].vector;
    }
    initBones() {
        this.bones = [
            new Bone2(0, `forearm right`, this.joints[ELBOW_RIGHT], this.joints[WRIST_RIGHT], this.boneMaterial),
            new Bone2(1, `forearm left`, this.joints[ELBOW_LEFT], this.joints[WRIST_LEFT], this.boneMaterial),
            new Bone2(2, `arm right`, this.joints[SHOULDER_RIGHT], this.joints[ELBOW_RIGHT], this.boneMaterial),
            new Bone2(3, `arm left`, this.joints[SHOULDER_LEFT], this.joints[ELBOW_LEFT], this.boneMaterial),
            new Bone2(4, `shoulder right`, this.joints[SHOULDER_RIGHT], this.joints[NECK], this.boneMaterial),
            new Bone2(5, `shoulder left`, this.joints[SHOULDER_LEFT], this.joints[NECK], this.boneMaterial),
            new Bone2(6, `back`, this.joints[NECK], this.joints[PELVIS], this.boneMaterial),
            // new Bone2(6, `back`, this.joints[TORSO], this.joints[PELVIS]),
            new Bone2(7, `hip right`, this.joints[PELVIS], this.joints[HIP_RIGHT], this.boneMaterial),
            new Bone2(8, `hip left`, this.joints[PELVIS], this.joints[HIP_LEFT], this.boneMaterial),
            new Bone2(9, `leg right`, this.joints[HIP_RIGHT], this.joints[KNEE_RIGHT], this.boneMaterial),
            new Bone2(10, `leg left`, this.joints[HIP_LEFT], this.joints[KNEE_LEFT], this.boneMaterial),
            new Bone2(11, `lower leg right`, this.joints[KNEE_RIGHT], this.joints[ANKLE_RIGHT], this.boneMaterial),
            new Bone2(12, `lower leg left`, this.joints[KNEE_LEFT], this.joints[ANKLE_LEFT], this.boneMaterial),
            new Bone2(13, `foot right`, this.joints[HEEL_RIGHT], this.joints[TOE_RIGHT], this.boneMaterial),
            new Bone2(14, `foot left`, this.joints[HEEL_LEFT], this.joints[TOE_LEFT], this.boneMaterial),
        ];
        this.add(...this.bones);
        this.forceHideBones = [4, 5, 7, 8, 13, 14];
        this.forceHideBones.forEach((index) => (this.bones[index].visible = false));
    }
    // Stretch bone between points.
    // Make this a part of the BoneMesh Class
    positionBoneMesh(mesh, start, end) {
        if (!mesh)
            return;
        // TODO: Same as sub(start) and then getting length. Optimize here later
        const direction = end.clone().sub(start);
        const distance = direction.length();
        // This shouldn't change and can be cached
        const bounding = mesh.geometry.boundingBox;
        const length = V.subVectors(bounding.min, bounding.max).length();
        // Get scale
        const scale = distance / length;
        mesh.position.copy(start);
        mesh.children[0].scale.setScalar(scale);
        mesh.quaternion.setFromUnitVectors(UP, direction.normalize());
    }
    positionChest(frame) {
        if (!this.chest)
            return;
        // Triangulate
        this.faceGeometry.vertices[0].copy(this.joints[SHOULDER_LEFT].vector);
        this.faceGeometry.vertices[1].copy(this.joints[SHOULDER_RIGHT].vector);
        this.faceGeometry.vertices[2].copy(this.joints[PELVIS].vector);
        // Normal
        this.faceGeometry.computeFaceNormals();
        this.faceGeometry.computeVertexNormals();
        this.faceGeometry.verticesNeedUpdate = true;
        this.faceGeometry.elementsNeedUpdate = true;
        this.faceGeometry.normalsNeedUpdate = true;
        const normal = this.faceGeometry.faces[0].normal;
        // const mid = new Vector3();
        // triangle.getMidpoint(mid);
        const a = this.faceGeometry.vertices[0];
        const b = this.faceGeometry.vertices[1];
        const c = this.faceGeometry.vertices[2];
        /**
         *    a--pos---b
         *    \       /
         *     \ mid /
         *      \   /
         *       \ /
         *        c
         *
         * a = Shoulder left
         * b = Shoulder Right
         * pos = Position
         * mid = Triangle Center
         *
         * rotation = rotate along dist 1 and 2
         */
        // Midt between Shoulders
        const pos = a.clone().lerp(b, 0.5);
        // const pos = this.joints[TORSO].vector.clone();
        // this.spineMid = pos.clone().lerp(c, 0.5);
        const distX = b.clone().sub(a);
        const lengthX = distX.length();
        distX.normalize();
        this.arrowX.position.copy(a);
        this.arrowX.setDirection(distX);
        this.arrowX.setLength(lengthX);
        const distY = c.clone().sub(pos);
        const lengthY = distY.length(); // Length on the spine
        distY.normalize();
        this.arrowY.position.copy(pos);
        this.arrowY.setDirection(distY);
        this.arrowY.setLength(lengthY);
        this.chestPosArrow.position.copy(pos);
        this.chestPosArrow.setDirection(normal);
        // Calculate first "Look At" point
        // Same as: this.lookAtPointChest = pos.clone().add(normal);
        this.chestPosArrow.children[1].getWorldPosition(this.lookAtPointChest);
        this.updateMatrixWorld(true);
        this.updateWorldMatrix(true, true);
        this.chestPosArrow.getWorldDirection(this.chestParent.up);
        this.chestParent.lookAt(this.arrowY.children[1].getWorldPosition(this.lookAtPointChest)); // This one aligns so the chest bottom is pointing towards the hip center point.
        // this.chest.scale.x = lengthX * 3.5; //4 is just a number I though looked good.
        this.chest.scale.x = lengthX * 3.5; // shoulder
        this.chest.scale.y = lengthY * 2;
        this.chest.scale.z = lengthX * 3; // thickness
    }
    positionHead(frame) {
        if (!this.head)
            return;
        // Triangulate
        // this.faceGeometry.vertices[0].copy(this.joints[EAR_LEFT].vector);
        // this.faceGeometry.vertices[1].copy(this.joints[EAR_RIGHT].vector);
        // this.faceGeometry.vertices[2].copy(this.joints[NOSE].vector);
        this.faceGeometry.vertices[0].copy(this.joints[SHOULDER_LEFT].vector);
        this.faceGeometry.vertices[1].copy(this.joints[SHOULDER_RIGHT].vector);
        this.faceGeometry.vertices[2].copy(this.joints[HEAD].vector);
        // Normal
        this.faceGeometry.computeFaceNormals();
        this.faceGeometry.computeVertexNormals();
        this.faceGeometry.verticesNeedUpdate = true;
        this.faceGeometry.elementsNeedUpdate = true;
        this.faceGeometry.normalsNeedUpdate = true;
        // const normal = this.faceGeometry.faces[0].normal;
        const a = this.faceGeometry.vertices[0];
        const b = this.faceGeometry.vertices[1];
        const c = this.faceGeometry.vertices[2];
        // Get mid point between ears (a and b)
        // get direction from that point to the nose.
        // Normalize that value. That's our normal
        const mid = a.clone().lerp(b, 0.5);
        const dir = c.clone().sub(mid);
        const pos = this.joints[HEAD].vector;
        this.headPosArrow.position.copy(pos);
        this.headPosArrow.setDirection(dir);
        this.arrowX.position.copy(a);
        this.arrowX.setDirection(a.clone().sub(b));
        this.arrowX.children[1].getWorldPosition(this.lookAtPointHead);
        this.updateMatrixWorld(true);
        this.updateWorldMatrix(true, true);
        this.headPosArrow.getWorldDirection(this.headParent.up); // 2) Set correct UP vector
        this.headParent.lookAt(this.lookAtPointHead);
    }
    positionHip(frame) {
        if (!this.hip)
            return;
        // Triangulate
        this.faceGeometry.vertices[0].copy(this.joints[NECK].vector);
        this.faceGeometry.vertices[1].copy(this.joints[HIP_RIGHT].vector);
        this.faceGeometry.vertices[2].copy(this.joints[HIP_LEFT].vector);
        // Normal
        this.faceGeometry.computeFaceNormals();
        this.faceGeometry.computeVertexNormals();
        this.faceGeometry.verticesNeedUpdate = true;
        this.faceGeometry.elementsNeedUpdate = true;
        this.faceGeometry.normalsNeedUpdate = true;
        const normal = this.faceGeometry.faces[0].normal;
        const a = this.faceGeometry.vertices[0];
        const b = this.faceGeometry.vertices[1];
        const c = this.faceGeometry.vertices[2];
        this.updateMatrixWorld(true);
        this.updateWorldMatrix(true, true);
        // Midt between Hips
        const pos = c.clone().lerp(b, 0.5);
        const distX = b.clone().sub(c);
        const lengthX = distX.length();
        distX.normalize();
        this.arrowX.position.copy(c);
        this.arrowX.setDirection(distX);
        this.arrowX.setLength(lengthX);
        const distY = a.clone().sub(pos);
        const lengthY = distY.length();
        this.spineLength = lengthY;
        distY.normalize();
        this.arrowY.position.copy(pos);
        this.arrowY.setDirection(distY);
        this.arrowY.setLength(lengthY);
        this.hipPosArrow.position.copy(pos);
        this.hipPosArrow.setDirection(normal);
        // Calculate first "Look At" point
        this.hipPosArrow.children[1].getWorldPosition(this.lookAtPointHip);
        this.updateMatrixWorld(true);
        this.updateWorldMatrix(true, true);
        this.hipPosArrow.getWorldDirection(this.hipParent.up);
        this.hipParent.lookAt(this.arrowY.children[1].getWorldPosition(this.lookAtPointHip));
        this.hip.scale.x = lengthX * 5.8; // Hips Width
        this.hip.scale.y = this.spineLength * 1.3; // butt thickness
        this.hip.scale.z = this.spineLength * 1.5; // height
    }
    positionFoot(mesh, inner, heel, toe, ankle) {
        if (!mesh)
            return;
        const dist = toe.clone().sub(heel);
        mesh.quaternion.setFromUnitVectors(new Vector3(1, 0, 0), dist.normalize());
        mesh.matrixWorldNeedsUpdate = true;
        mesh.updateMatrix();
        mesh.position.copy(heel);
        mesh.scale.setScalar(dist.length() * 1.11);
    }
    positionLeftFoot(mesh, inner, heel, toe, ankle) {
        if (!mesh)
            return;
        // Triangulate
        const a = heel;
        const b = toe;
        const c = ankle;
        let distAB = new THREE.Vector3();
        distAB = b.clone().sub(a);
        let distAC = new THREE.Vector3();
        distAC = c.clone().sub(a);
        // X axis is the bone axis
        // Y axis is vector normal to foot triad of markers
        // Orient Foot Segment with Rotation Matrix, set Quaternion from it.
        const lengthAB = distAB.clone().length();
        const lengthAC = distAC.clone().length();
        const yVect2 = distAC.clone().cross(distAB);
        yVect2.normalize();
        const xVect = distAB.clone().normalize();
        const dist = toe.clone().sub(heel);
        const zVect = xVect.clone().cross(yVect2);
        const yVect = zVect.clone().cross(xVect);
        const matrixBH = new THREE.Matrix4();
        matrixBH.makeBasis(xVect, yVect, zVect);
        const mRx = new THREE.Matrix4();
        const mRy = new THREE.Matrix4();
        const mRz = new THREE.Matrix4();
        /*mRx.makeRotationX(3.14159);
        mRy.makeRotationY(0);
        mRz.makeRotationZ(0);
        matrixBH.multiplyMatrices(mRx,matrixBH);
        matrixBH.multiplyMatrices(mRy,matrixBH);
        matrixBH.multiplyMatrices(mRz,matrixBH);*/
        mesh.matrixWorldNeedsUpdate = true;
        mesh.quaternion.setFromRotationMatrix(matrixBH);
        mesh.updateMatrix();
        // position foot 20% from the heel to align ankles
        const basePosition = heel.clone();
        const relPosition = toe.clone().sub(heel).multiplyScalar(0.2);
        const newPosition = basePosition.add(relPosition);
        mesh.position.copy(newPosition);
        mesh.scale.setScalar(1.11);
    }
    positionRightFoot(mesh, inner, heel, toe, ankle) {
        if (!mesh)
            return;
        // Triangulate
        const a = heel;
        const b = toe;
        const c = ankle;
        let distAB = new THREE.Vector3();
        distAB = b.clone().sub(a);
        let distAC = new THREE.Vector3();
        distAC = c.clone().sub(a);
        // X axis is the bone axis
        // Y axis is vector normal to foot triad of markers
        // Orient Foot Segment with Rotation Matrix, set Quaternion from it.
        const lengthAB = distAB.clone().length();
        const lengthAC = distAC.clone().length();
        const yVect2 = distAC.clone().cross(distAB);
        yVect2.normalize();
        const xVect = distAB.clone().normalize();
        const dist = toe.clone().sub(heel);
        const zVect = xVect.clone().cross(yVect2);
        const yVect = zVect.clone().cross(xVect);
        const matrixBH = new THREE.Matrix4();
        matrixBH.makeBasis(xVect, yVect, zVect);
        const mRx = new THREE.Matrix4();
        const mRy = new THREE.Matrix4();
        const mRz = new THREE.Matrix4();
        /*mRx.makeRotationX(3.14159);
        mRy.makeRotationY(0);
        mRz.makeRotationZ(0);
        matrixBH.multiplyMatrices(mRx,matrixBH);
        matrixBH.multiplyMatrices(mRy,matrixBH);
        matrixBH.multiplyMatrices(mRz,matrixBH);*/
        mesh.matrixWorldNeedsUpdate = true;
        mesh.quaternion.setFromRotationMatrix(matrixBH);
        mesh.updateMatrix();
        // position foot 20% from the heel to align ankles
        const basePosition = heel.clone();
        const relPosition = toe.clone().sub(heel).multiplyScalar(0.2);
        const newPosition = basePosition.add(relPosition);
        mesh.position.copy(newPosition);
        mesh.scale.setScalar(1.11);
    }
    //private positionChestByMarkers(mesh: ArrowHelper, inner: Object3D, pelvis: Vector3, shoulderl: Vector3, shoulderr: Vector3){
    positionChestByMarkers(mesh, inner, pelvis, shoulderl, shoulderr, baseneck) {
        if (!mesh)
            return;
        // Triangulate
        const a = pelvis;
        const b = shoulderl;
        const c = shoulderr;
        let shoulderm = new THREE.Vector3();
        shoulderm = shoulderl.clone().add(shoulderr.clone()).divideScalar(2);
        let torsom = new THREE.Vector3();
        torsom = shoulderm.clone().add(pelvis.clone()).divideScalar(2);
        let distAB = new THREE.Vector3();
        distAB = shoulderm.clone().sub(pelvis.clone());
        let distBC = new THREE.Vector3();
        distBC = shoulderr.clone().sub(shoulderl.clone());
        // Y axis is the bone axis
        // X axis is vector normal to foot triad of markers
        // Orient Chest Segment with Rotation Matrix, set Quaternion from it.
        const yVect = distAB.clone().normalize();
        let xVect = distBC.clone().normalize();
        const zVect = xVect.clone().cross(yVect);
        xVect = yVect.clone().cross(zVect);
        const matrixBH = new THREE.Matrix4();
        matrixBH.makeBasis(xVect, yVect, zVect);
        const mRx = new THREE.Matrix4();
        const mRy = new THREE.Matrix4();
        const mRz = new THREE.Matrix4();
        mesh.matrixWorldNeedsUpdate = true;
        mesh.quaternion.setFromRotationMatrix(matrixBH);
        mesh.updateMatrix();
        // position foot 20% from the heel to align ankles
        const basePosition = torsom.clone();
        const relPosition = shoulderm.clone().sub(torsom).multiplyScalar(0.1);
        const newPosition = baseneck.add(relPosition);
        mesh.position.copy(baseneck);
        //mesh.scale.setScalar(1.11);
        const scaleY = distAB.clone().length();
        const scaleX = distBC.clone().length();
        mesh.scale.setX(scaleX * 3.5);
        mesh.scale.setY(scaleY * 2);
        mesh.scale.setZ(scaleX * 3);
    }
    positionHeadByMarkers(mesh, inner, neck, head, shoulderl, shoulderr) {
        if (!mesh)
            return;
        /*let shoulderm = new THREE.Vector3();
        shoulderm = shoulderl.clone().add(shoulderr.clone()).divideScalar(2);
        let torsom = new THREE.Vector3();
        torsom = shoulderm.clone().add(pelvis.clone()).divideScalar(2);
        */
        let distAB = new THREE.Vector3();
        distAB = neck.clone().sub(head.clone());
        let distBC = new THREE.Vector3();
        distBC = shoulderl.clone().sub(shoulderr.clone());
        // Y axis is the bone axis
        // X axis is vector normal to foot triad of markers
        // Orient Chest Segment with Rotation Matrix, set Quaternion from it.
        const zVect = distAB.clone().normalize();
        // let yVect = distAB.clone().normalize();
        let xVect = distBC.clone().normalize();
        //const zVect = xVect.clone().cross(yVect);
        const yVect = zVect.clone().cross(xVect);
        xVect = yVect.clone().cross(zVect);
        const matrixBH = new THREE.Matrix4();
        matrixBH.makeBasis(xVect, yVect, zVect);
        const mRx = new THREE.Matrix4();
        const mRy = new THREE.Matrix4();
        const mRz = new THREE.Matrix4();
        mesh.matrixWorldNeedsUpdate = true;
        mesh.quaternion.setFromRotationMatrix(matrixBH);
        mesh.updateMatrix();
        // position foot 20% from the heel to align ankles
        const basePosition = neck.clone();
        const relPosition = head.clone().sub(neck).multiplyScalar(0.3);
        const newPosition = neck.add(relPosition);
        // add in a planar offset of marker
        const shoulderVect = distBC.clone().normalize(); //left - right
        const headVect = distAB.clone().normalize();
        const planeVect = headVect.clone().cross(shoulderVect.clone());
        const scalePlane = distBC.length();
        const planeOffset = planeVect.clone().multiplyScalar(scalePlane * 0.25);
        mesh.position.copy(newPosition.add(planeOffset));
        //mesh.scale.setScalar(1.11);
        /*let scaleY = distAB.clone().length();
        let scaleX = distBC.clone().length();
        mesh.scale.setX(scaleX*3.5);
        mesh.scale.setY(scaleY*2);
        mesh.scale.setZ(scaleX*3);
        */
    }
    hideMesh() {
        this.showMeshes = false;
        this.meshes.forEach((mesh) => (mesh.visible = false));
        this.forceHideBones.forEach((index) => (this.bones[index].visible = true));
        this.joints[HEEL_LEFT].visible = true;
        this.joints[HEEL_RIGHT].visible = true;
        this.joints[TOE_LEFT].visible = true;
        this.joints[TOE_RIGHT].visible = true;
    }
    showMesh() {
        this.showMeshes = true;
        this.meshes.forEach((mesh) => (mesh.visible = true));
        this.forceHideBones.forEach((index) => (this.bones[index].visible = false));
        this.joints[HEEL_LEFT].visible = false;
        this.joints[HEEL_RIGHT].visible = false;
        this.joints[TOE_LEFT].visible = false;
        this.joints[TOE_RIGHT].visible = false;
    }
    opacity(value) {
        this.bodyMaterial.opacity = value;
    }
    translateToCenter() {
        const initialPoint = this.pelvis.initialPoint;
        if (isNil(initialPoint)) {
            return;
        }
        const { x, y } = initialPoint;
        this.translateX(-x);
        this.translateY(-y);
    }
    get headBase() {
        return this.findJoint(HEADBASE);
    }
    get pelvis() {
        return this.findJoint(PELVIS);
    }
    // https://stackoverflow.com/questions/31399856/drawing-a-line-with-three-js-dynamically/31411794#31411794
    plotTrail(pointIndex, color = '#ff0000', scale = 1) {
        const joint = this.joints[pointIndex];
        const vectors = joint.data.map((p) => new Vector3(...p));
        const curve = new THREE.CatmullRomCurve3(vectors, false, 'catmullrom', 0.1);
        // Mesh
        const geometry = new THREE.TubeBufferGeometry(curve, vectors.length, 0.01 * scale, 6, false);
        const material = new THREE.LineBasicMaterial({ color });
        const object = new THREE.Mesh(geometry, material);
        // Line
        // const points = curve.getPoints(vectors.length / 2);
        // const geometry = new THREE.BufferGeometry().setFromPoints(points);
        // const material = new THREE.MeshBasicMaterial({color});
        // const object = new THREE.Line(geometry, material);
        this.add(object);
        return () => this.remove(object);
    }
    update(frame) {
        if (frame > this.data.length - 1)
            return;
        for (const joint of this.joints) {
            joint.update(frame);
        }
        for (const bone of this.bones) {
            bone.update();
        }
        if (this.showMeshes) {
            this.positionBoneMesh(this.armTopLeft, this.bones[3].j1.vector, this.bones[3].j2.vector);
            this.positionBoneMesh(this.armTopRight, this.bones[2].j1.vector, this.bones[2].j2.vector);
            this.positionBoneMesh(this.armBottomLeft, this.bones[1].j1.vector, this.bones[1].j2.vector);
            this.positionBoneMesh(this.armBottomRight, this.bones[0].j1.vector, this.bones[0].j2.vector);
            this.positionBoneMesh(this.legTopLeft, this.bones[9].j1.vector, this.bones[9].j2.vector);
            this.positionBoneMesh(this.legTopRight, this.bones[10].j1.vector, this.bones[10].j2.vector);
            this.positionBoneMesh(this.legBottomLeft, this.bones[12].j1.vector, this.bones[12].j2.vector);
            this.positionBoneMesh(this.legBottomRight, this.bones[11].j1.vector, this.bones[11].j2.vector);
            // Call order does matter
            //this.positionChest(frame);
            this.positionChestByMarkers(this.chest, this.chestParent, this.joints[PELVIS].vector, this.joints[SHOULDER_LEFT].vector, this.joints[SHOULDER_RIGHT].vector, this.joints[NECK].vector);
            this.positionHip(frame);
            // this.positionHead(frame);
            this.positionHeadByMarkers(this.head, this.headParent, this.joints[NECK].vector, this.joints[HEAD].vector, this.joints[SHOULDER_LEFT].vector, this.joints[SHOULDER_RIGHT].vector);
            this.positionRightFoot(this.rightFoot, this.rightFootParent, this.joints[HEEL_RIGHT].vector, this.joints[TOE_RIGHT].vector, this.joints[ANKLE_RIGHT].vector);
            this.positionLeftFoot(this.leftFoot, this.leftFootParent, this.joints[HEEL_LEFT].vector, this.joints[TOE_LEFT].vector, this.joints[ANKLE_LEFT].vector);
            /*this.positionFoot(
              this.leftFoot,
              this.leftFootParent,
              this.joints[HEEL_LEFT].vector,
              this.joints[TOE_LEFT].vector,
              this.joints[ANKLE_LEFT].vector
            );*/
        }
    }
    findJoint(type) {
        return this.joints[type];
    }
}
