<script>
  import Tooltip from './Tooltip.svelte';
  import Icon from './Icon.svelte';
  export let text = '';
  export let tooltipClassName = '';
  export let iconClassName = '';

</script>

<button class={($$props.class ? $$props.class : '') + ' button icon'} on:click>
  <Tooltip title={text} {tooltipClassName}>
    <Icon {iconClassName} name="information-outlined" height={26} width={26} />
  </Tooltip>
</button>

<style>
  .button {
    cursor: pointer;
    position: absolute;
    justify-content: center;
    align-items: center;
    height: 26px;
    width: 26px;
    border-radius: 17px;
    margin-top: 13px;
    z-index: 1;
  }

</style>
