import { OUTPUT_FILE_BUCKET_NAME } from '../utils/config';
import { credentials } from '../stores/stores';
import { addIndex, always, compose, descend, evolve, filter, map, range, sort, find, head, values, groupBy, mapObjIndexed, } from 'ramda';
import { get } from 'svelte/store';
import { isDebugMode } from '../stores/debug';
const GENERIC_FILE_NAME = '3d_bma_box-config-joints-bma.json';
const GOLF_FILE_NAME = '3d_bma_box-config-joints-bma-golf.json';
const getFileName = (path) => path.substring(path.lastIndexOf('/') + 1, path.length);
// This parses the trial name out from the file name in AWS
// Name comes from the "Athlete Name" field on intel3dat.com
// This will break if the AWS filename structure changes, or if the tag names change
const getDisplayName = (path) => {
    if (path.includes('-Good-')) {
        return path.substring(path.indexOf('/') + 1, path.indexOf('-Good-'));
    }
    else if (path.includes('-Bad-')) {
        return path.substring(path.indexOf('/') + 1, path.indexOf('-Bad-'));
    }
    else if (path.includes('-Average-')) {
        return path.substring(path.indexOf('/') + 1, path.indexOf('-Average-'));
    }
    else {
        return getFileName(path);
    }
};
// Parses the trial upload date from the file name in AWS
// This will break if the AWS filename structure changes, or if the tag names change
const getDateString = (path) => {
    if (path.includes('-Heavy-')) {
        return path.substring(path.indexOf('-Heavy-') + 7, path.indexOf('-Heavy-') + 17);
    }
    else if (path.includes('-Maintenance-')) {
        return path.substring(path.indexOf('-Maintenance-') + 13, path.indexOf('-Maintenance-') + 23);
    }
    else if (path.includes('-Competition-')) {
        return path.substring(path.indexOf('-Competition-') + 13, path.indexOf('-Competition-') + 23);
    }
    else {
        const date = new Date();
        const dateString = ('0' + (date.getMonth() + 1).toString()).slice(-2) +
            '-' +
            ('0' + date.getDate().toString()).slice(-2) +
            '-' +
            date.getFullYear().toString();
        return dateString;
    }
};
let creds;
credentials.subscribe((value) => {
    creds = value ? value.creds : null;
});
const mapIndexed = addIndex(map);
const fileNamed = (name, recordings) => find((it) => it.key == name, recordings);
const pickBestFile = (recordings) => fileNamed(GOLF_FILE_NAME, recordings) ||
    fileNamed(GENERIC_FILE_NAME, recordings) ||
    head(recordings);
const selectFile = compose(values, mapObjIndexed(pickBestFile), groupBy((it) => it.displayName));
const setIndexAsId = mapIndexed((item, index) => evolve({ id: always(index) }, item));
export const sanitizeRecordings = compose(setIndexAsId, selectFile, sort(descend((it) => new Date(it.dateString).getTime())), filter((it) => it.key.includes('.json')));
function debugRecordings() {
    return range(0, 6).map((it) => ({
        id: it,
        originalKey: 'originalKey',
        key: 'data.json',
        displayName: `Trial ${it}`,
        url: `/assets/data/${it}/data.json`,
        dateString: '09-03-2021',
    }));
}
export const fetchRecordings = async () => {
    if (get(isDebugMode)) {
        return debugRecordings();
    }
    if (!creds) {
        console.error('No credentials found');
        throw `No credentials found`;
    }
    AWS.config.credentials = creds;
    const s3 = new AWS.S3({ signatureVersion: 'v4' });
    const identityId = creds.params.IdentityId;
    // List the contents of the input bucket.
    const config = {
        Bucket: OUTPUT_FILE_BUCKET_NAME,
        Prefix: `${identityId}/`,
    };
    const files = [];
    const getFiles = async (resolve, reject, config) => {
        const objects = await s3.listObjectsV2(config).promise();
        files.push(...objects.Contents.map((obj) => obj.Key).map((key) => {
            return {
                id: 0,
                originalKey: key,
                key: getFileName(key),
                displayName: getDisplayName(key),
                url: s3.getSignedUrl('getObject', {
                    Bucket: OUTPUT_FILE_BUCKET_NAME,
                    Key: key,
                    Expires: 3600 * 24 * 7,
                }),
                dateString: getDateString(key),
            };
        }));
        // If there are > 1000 files, IsTruncated = true, and we need to get the rest using the ContinuationToken
        if (objects.IsTruncated) {
            config.ContinuationToken = objects.NextContinuationToken;
            getFiles(resolve, reject, config);
        }
        else {
            return resolve();
        }
    };
    await new Promise((r, j) => getFiles(r, j, config));
    return sanitizeRecordings(files);
};
