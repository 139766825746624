<script type="ts">
  import { metricIndices, timeSeriesMetrics } from '../../../../stores/stores';
  import { frame, frames } from '../../../../stores/playerStore';
  import {
    addOrReplaceAnnotation,
    chartSeriesColors,
  } from '../../../../utils/charts';
  import LineChart from '../../charts/LineChart.svelte';
  import type Highcharts from 'highcharts';
  import SeriesSelector from './components/SeriesSelector.svelte';
  import { comparisonTrialData } from '../../../../stores/trial';
  import { generic } from '../../../../models/selectors';

  const colors = chartSeriesColors();

  export let data;

  let chartComponent: LineChart;

  $: metrics = data.bma_metrics;
  $: comparisonMetrics = $comparisonTrialData
    ? $comparisonTrialData.bma_metrics
    : undefined;

  $: indexes = fixedIndexesMetrics([getMetricIndexes(0), getMetricIndexes(1)]);

  $: series = buildSeries(metrics, indexes);
  $: comparisonSeries = comparisonMetrics
    ? buildSeries(comparisonMetrics, indexes)
    : undefined;

  $: yValues = series.map((serie) => ({
    y: serie.data.Data[$frame].toFixed(2),
    visible: true,
  }));

  $: comparisonYValues = comparisonSeries
    ? comparisonSeries.map((serie) => ({
        y: serie.data.Data[$frame].toFixed(2),
        visible: true,
      }))
    : undefined;

  // save values in local storage so metrics will default to previously-selected values
  $: localStorage.setItem(
    'timeSeriesMetrics',
    JSON.stringify($timeSeriesMetrics)
  );

  function getMetricIndexes(index) {
    return $metricIndices[$timeSeriesMetrics[index]];
  }

  function buildSeries(metric, serie) {
    return serie.map(({ i, j, k }) => {
      return {
        data: metric[i].Series[j].Series[k],
      };
    });
  }

  function fixedIndexesMetrics(fixedIndexesMetrics) {
    return fixedIndexesMetrics.filter((n) => n);
  }

  function addYAxis(chart: Highcharts.Chart, s, index) {
    const id = `axis-${index}`;
    const previousAxis = chart.get(id) as Highcharts.Axis;
    const text = `${s.data.Label} (${generic.abbreviatedUnit(s.data)})`;

    if (previousAxis) {
      previousAxis.setTitle({ text });
    } else {
      chart.addAxis({
        id,
        title: {
          style: {
            color: colors[index],
          },
          text,
        },
        opposite: index == 1,
      });
    }
  }

  function addZeroPointAnnotation(chart, index) {
    addOrReplaceAnnotation(chart, {
      id: `annotation-zero-point-${index}`,
      draggable: '',
      shapes: [
        {
          stroke: colors[index],
          strokeWidth: 0.5,
          type: 'path',
          points: [
            {
              x: 0,
              xAxis: 0,
              y: 0,
              yAxis: index,
            },
            {
              x: $frames,
              xAxis: 0,
              y: 0,
              yAxis: index,
            },
          ],
        },
      ],
    });
  }

  function addVerticalColorBarAnnotation(chart, index) {
    addOrReplaceAnnotation(chart, {
      id: `annotation-vertical-bar-${index}`,
      draggable: '',
      shapes: [
        {
          stroke: colors[index],
          strokeWidth: 4,
          type: 'path',
          points: [
            {
              x: index == 0 ? 0 : $frames,
              xAxis: 0,
              y: 0,
            },
            {
              x: index == 0 ? 0 : $frames,
              xAxis: 0,
              y: 1000,
            },
          ],
        },
      ],
    });
  }

  function refreshChart({ detail: chart }: { detail: Highcharts.Chart }) {
    series.forEach((s, index) => {
      addYAxis(chart, s, index);
      addZeroPointAnnotation(chart, index);
      addVerticalColorBarAnnotation(chart, index);
    });
  }

  function handleSelect(e) {
    $timeSeriesMetrics[e.detail.index] = e.detail.value.Label; // this will store selected metric in local storage
    series[e.detail.index].data = JSON.parse(JSON.stringify(e.detail.value));
  }

  export function handleResize() {
    chartComponent.handleResize();
  }

  const config: Highcharts.Options = {
    chart: {
      marginRight: 80,
    },
  };

</script>

<div class="chart">
  <SeriesSelector
    {series}
    {comparisonSeries}
    {colors}
    {chartComponent}
    {metrics}
    {yValues}
    {comparisonYValues}
    on:select={handleSelect} />
  <div class="line-chart">
    <LineChart
      {series}
      {comparisonSeries}
      {colors}
      highchartsConfigOverride={config}
      setYAxis={true}
      bind:this={chartComponent}
      on:refresh={refreshChart} />
  </div>
</div>

<style>
  .chart {
    height: 100%;
  }

  .line-chart {
    height: 80%;
  }

</style>
