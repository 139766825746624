<script type="ts">
  import type { ATGolfMetric } from '../../../../../models/pipeline';
  import { getMetricByLabel, golf } from '../../../../../models/selectors';
  import { comparisonTrialData } from '../../../../../stores/trial';
  import CoordinatePlane from '../../../charts/CoordinatePlane.svelte';
  import FootIcon from '../../../ui/FootIcon.svelte';

  export let data;

  function dataToSwayFormat(data) {
    const swayMetrics = golf.sway(data);
    return {
      foreHindFootSway: getMetricByLabel<ATGolfMetric>(
        'Forefoot-Hindfoot Sway',
        swayMetrics
      ),
      toeHeelSway: getMetricByLabel<ATGolfMetric>('Toe-Heel Sway', swayMetrics),
    };
  }

  $: mainSeries = dataToSwayFormat(data);
  $: comparisonSeries = $comparisonTrialData
    ? dataToSwayFormat($comparisonTrialData)
    : undefined;

</script>

<div class="sway-container">
  <div class="inner-container">
    <span class="outside-text toe-container">Toe</span>
    <FootIcon title="Left foot" footSide="left" />
    <CoordinatePlane
      data={mainSeries}
      comparisonData={comparisonSeries}
      unit="(%% of Foot Size from Midfoot)" />
    <FootIcon title="Right foot" footSide="right" />
    <span class="outside-text heel-container">Heel</span>
  </div>
</div>

<style>
  .sway-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }
  .outside-text {
    position: absolute;
    text-align: center;
    z-index: 1;
  }

  .inner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .toe-container {
    margin-bottom: 395px;
    margin-left: 2px;
  }

  .heel-container {
    margin-top: 392px;
    margin-left: 4px;
  }

</style>
