<script>
  import { getContext } from 'svelte';
  import Icon from '../../ui/Icon.svelte';

  let context = getContext('dropdown');

  export let label = 'Metric';
  export let color;
  export let responsive = false;

</script>

<button class="sm" class:responsive on:click={$context.toggle}>
  {#if color}
    <div class="color"><span style="background: {color}" /></div>
  {/if}
  <span title={label} class="label"> {label} </span>
  <div class="icon">
    <Icon name="down" />
  </div>
</button>

<style>
  button {
    margin-right: 0;
    text-align: left;
    padding-right: 4px;
  }

  button.responsive {
    width: 100%;
    /* max-width: 160px; */
  }

  .color {
    width: 12px;
    padding-right: 4px;
  }

  .color span {
    display: block;
    height: 7px;
    width: 7px;
    border-radius: 4px;
  }

  span.label {
    margin-top: 1px;
    height: 16px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .icon {
    height: 12px;
    width: 12px;
    padding-top: 1px;
    padding-left: 2px;
  }

  button :global(svg) {
    height: 14px;
    width: 14px;
    fill: var(--color-primary-main);
  }

</style>
