<script lang="ts">
  import type { Event } from 'aws-sdk/clients/s3';

  import { onMount } from 'svelte';
  import {
    paused,
    currentTime,
    duration,
    STEP,
  } from '../../../stores/playerStore';
  import { trial } from '../../../stores/trial';

  import gsap from '../../../utils/gsap';
  import { TweenMax } from '../../../utils/gsap';
  import Draggable from '../../../utils/gsap/Draggable';
  import { normalize } from '../../../utils/math';
  export let keyFrames: number[];

  gsap.registerPlugin(Draggable);

  let timeline: HTMLElement, knob: HTMLElement, progressbar: HTMLElement;
  let keyFrameKnobs = Array.of<HTMLElement>();
  let draggable: any; // Cant get typescript working for GSAP bonus
  let playAfterDrag = $paused;
  let progress = 0;
  let needsInitialization = true;

  const KNOB_WIDTH = 7;

  currentTime.subscribe((v) => {
    if (!draggable || !progressbar) return;
    initializeKnobsIfNecessary();
    // Get 0-1 progress
    progress = normalize(v, 0, $duration);
    updatePosition(progress);
  });

  /* 
    HACK: for some reason, the draggable doesn't compute its actual width when the component mounts
    and we need to wait for a few milliseconds for that to happen. Without this hack, the knobs
    are wrongly positioned. 
  */
  function initializeKnobsIfNecessary() {
    if (!draggable) {
      return;
    }

    if (needsInitialization) {
      draggable.applyBounds();
      updateKeyFramesPosition();
      needsInitialization = false;
    }
  }

  function updateProgressBarBounds() {
    if (!draggable) {
      return;
    }
    draggable.applyBounds();
    updatePosition(progress);
    updateKeyFramesPosition();
    draggable.update();
  }

  function updatePosition(progress: number) {
    const x = progress * draggable.maxX;
    TweenMax.set(knob, { x });
    progressbar.style.transform = `scaleX(${progress})`;
  }

  function onDrag(e: Event) {
    progress = normalize(draggable.x, 0, draggable.maxX);
    currentTime.set($duration * progress);
    updatePosition(progress);
  }

  function onPress(e: Event) {
    playAfterDrag = $paused;
    $paused = true;
  }

  function onRelease() {
    $paused = playAfterDrag;
  }

  function onClick(e: MouseEvent) {
    progress = normalize(
      e.pageX - timeline.getBoundingClientRect().left,
      0,
      draggable.maxX
    );
    currentTime.set($duration * progress);
  }

  function updateKeyFramesPosition() {
    if (!$trial.hasKeyFrames) {
      return;
    }

    keyFrames.forEach((keyFrame, index) => {
      const progress = normalize(keyFrame * STEP, 0, $duration);
      const previousKnobsWidth = index * KNOB_WIDTH;
      const mainKnobwWidth = KNOB_WIDTH;

      // Both the previous keyframes and the main knob modify this knob position.
      // We subtract them to get the proper x value.
      const x = progress * draggable.maxX - previousKnobsWidth - mainKnobwWidth;

      TweenMax.set(keyFrameKnobs[index], { x });
    });
  }

  function scheduleInitialization() {
    setTimeout(initializeKnobsIfNecessary, 1000);
  }

  onMount(() => {
    [draggable] = Draggable.create(knob, {
      type: `x`,
      bounds: timeline,
      onDrag,
      onPress,
      onRelease,
    });
  });

  $: {
    // Need to compute progress bar bounds when trial changes.
    if ($trial) {
      updateProgressBarBounds();
    }
  }

  $: {
    // Need to initialize again when keyFrames change.
    if (keyFrames) {
      needsInitialization = true;
      scheduleInitialization();
    }
  }

</script>

<div
  bind:this={timeline}
  on:click={onClick}
  class="timeline"
  style="--knob-width: {KNOB_WIDTH}px">
  <div bind:this={progressbar} class="progress" />
  <div bind:this={knob} class="knob" />
  {#each keyFrames as _, index}
    <div
      bind:this={keyFrameKnobs[index]}
      class="keyframe"
      class:hidden={needsInitialization} />
  {/each}
</div>
<svelte:window on:resize={updateProgressBarBounds} />

<style>
  .timeline {
    flex-grow: 2;
    width: 60%;
    height: 5px;
    background-color: var(--color-gray-lighter);
    display: flex;
    align-items: center;
    position: relative;
  }

  .hidden {
    display: none;
  }

  .knob {
    width: var(--knob-width);
    height: 22px;
    border-radius: 7px;
    background: var(--color-secondary-main);
    z-index: 1;
  }

  .keyframe {
    width: var(--knob-width);
    height: 5px;
    background: var(--color-highlight-main);
  }

  .progress {
    position: absolute;
    left: 0;
    width: 100%;
    height: 5px;
    transform-origin: top left;
    transform: scaleX(0);
    background-color: var(--color-primary-main);
  }

</style>
