<script>
  import { isNil } from 'ramda';

  import { frame } from '../../../../stores/playerStore';
  import Button from '../../ui/Button.svelte';

  import Modal from '../../ui/Modal.svelte';
  import BMADropdown from '../biomechanics/BMADropdown.svelte';
  export let modalOpen = false,
    editMode = true,
    handleSelect,
    tempData,
    data,
    selectedIndex,
    saveMetric,
    addMetric,
    metrics,
    removeMetric,
    handleCloseModal;

</script>
{#if modalOpen}
  <Modal
    isOpen={modalOpen}
    title={editMode ? 'Edit Metric' : 'Add Metric'}
    {handleCloseModal}>
    <div class="modalContent">
      <div class="selectionMetric">
        <div>
          <BMADropdown
            on:select={handleSelect}
            label={tempData.Label}
            metrics={data.bma_metrics}
            index={selectedIndex}
            responsive={true} />
        </div>
        <div>
          {#if !isNil(tempData.Data)}
            <div class="metricValue">{tempData.Data[$frame].toFixed(2)}</div>
          {:else}
            <div class="metricValue">{tempData.Value.toFixed(2)}</div>
          {/if}
          <div class="metricUnits">{tempData?.Units}</div>
        </div>
      </div>
      <div class="actionBar">
        <div>
          <Button class="primary" on:click={editMode ? saveMetric : addMetric}>
            {editMode ? 'Save' : 'Add Metric'}
          </Button>
          <Button class="link" on:click={handleCloseModal}>Cancel</Button>
        </div>
        <div>
          {#if editMode && removeMetric && metrics.length > 1}
            <Button class="link" on:click={removeMetric}>Remove Metric</Button>
          {/if}
        </div>
      </div>
    </div>
  </Modal>
{/if}

<style>
  .modalContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 220px;
    min-width: 300px;
  }
  .selectionMetric {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 110px;
    width: 176px;
    padding: 12px;
    box-sizing: border-box;
    border: 1px solid var(--color-gray-lighter);
    border-radius: 8px;
  }
  .metricValue {
    font-size: var(--typography-size-small);
    color: var(--color-gray-dark);
  }
  .metricUnits {
    font-size: var(--typography-size-xsmall);
    color: var(--color-gray-light);
  }
  .actionBar {
    display: flex;
    justify-content: space-between;
  }

</style>
