<script>
  import { onMount } from 'svelte';

  import {
    metricIndices,
    selectedSnapshotsMetrics,
  } from '../../../../../stores/stores';
  import { comparisonTrial, trial } from '../../../../../stores/trial';
  import { hoist } from '../../../../../utils/hoist';
  import ModalMetrics from '../../modalmetrics/ModalMetrics.svelte';

  export let data;
  const ADDRESS = 'Address';
  import BoxSerie from './BoxSerie.svelte';
  import PanelTabs from '../../../ui/PanelTabs.svelte';
  import {
    updateSelectedMetrics,
    findMetricByName,
    findMetricIndexByName,
  } from '../../../../../utils/metrics';
  import { isNil, last, map, zip } from 'ramda';
  import LegendContainer from '../../../ui/LegendContainer.svelte';

  let active = findMetricByName($selectedSnapshotsMetrics, ADDRESS);
  let metrics = [];
  let modalContainer;
  let modalOpen = false;
  let selectedIndex = 0;
  let tempData = {};

  // reactive declaration updates metrics array whenever data changes (i.e., when new trial is selected)
  $: metrics = updateSelectedMetrics({
    selectedLiveMetrics: active.metrics,
    metricIndices: $metricIndices,
    trial: $trial,
    data,
  });

  $: comparisonMetrics = $comparisonTrial
    ? updateSelectedMetrics({
        selectedLiveMetrics: active.metrics,
        metricIndices: $metricIndices,
        trial: $comparisonTrial,
        data: $comparisonTrial.data,
      })
    : undefined;

  $: allMetrics = $comparisonTrial ? zip(metrics, comparisonMetrics) : metrics;

  function handleChange(category) {
    active = findMetricByName($selectedSnapshotsMetrics, category);
  }

  function handleCloseModal() {
    modalOpen = false;
  }

  function handleSelect(e) {
    tempData = JSON.parse(JSON.stringify(e.detail.value));
  }

  function saveMetric() {
    const indexMetricToChange = findMetricIndexByName(
      $selectedSnapshotsMetrics,
      active.tabName
    );
    $selectedSnapshotsMetrics[indexMetricToChange].metrics[selectedIndex] =
      tempData.Label;
    active.metrics[selectedIndex] = tempData.Label;
    modalOpen = false;
  }

  function handleMetricClick(index) {
    selectedIndex = index;
    tempData = metrics[index];
    modalOpen = true;
  }

  function getTabsName() {
    const name = (tab) => tab.tabName;
    return map(name, $selectedSnapshotsMetrics);
  }

  const tabsNames = getTabsName();

  onMount(() => {
    // Hoist modal up to top level - I want to do this in Modal.svelte by default, but
    // that causes an issue with the BMA Info Tip modal - need to debug that first.
    hoist(modalContainer, document.body);
  });

</script>

<PanelTabs {handleChange} active={active.tabName} {tabsNames} />
<div class={`metrics-grid ${$comparisonTrial && 'metrics-grid-compare'}`}>
  {#each allMetrics as data, index}
    <div
      class={$comparisonTrial ? 'card-with-compare' : 'card-without-compare'}>
      <BoxSerie
        data={data[0] || data}
        active={active.tabName}
        footerColor="secondary-main"
        onClick={() => handleMetricClick(index)}
        isComparison={$comparisonTrial} />
      {#if !isNil(last(data))}
        <BoxSerie
          data={last(data)}
          active={active.tabName}
          footerColor="primary-main"
          onClick={() => handleMetricClick(index)}
          isComparison={$comparisonTrial} />
      {/if}
    </div>
  {/each}
</div>
{#if $comparisonTrial}
  <div class="legend-container">
    <LegendContainer
      firstTrialName={$trial.displayName}
      secondTrialName={$comparisonTrial.displayName} />
  </div>
{/if}

<div bind:this={modalContainer}>
  <ModalMetrics
    editMode={true}
    {modalOpen}
    {handleSelect}
    {tempData}
    {data}
    {selectedIndex}
    {saveMetric}
    {metrics}
    {handleCloseModal} />
</div>

<style>
  .metrics-grid {
    display: grid;
    flex-wrap: wrap;
    grid-auto-rows: 14rem;
    grid-template-columns: repeat(
      auto-fill,
      minmax(calc(100% / 3 - 20px), 1fr)
    );
    justify-items: center;
    overflow: auto;
    padding: 20px;
  }
  .metrics-grid-compare {
    grid-template-columns: repeat(
      auto-fill,
      minmax(calc(100% / 2 - 20px), 1fr)
    );
    grid-gap: 15px;
    grid-auto-rows: 12rem;
  }

  .card-with-compare {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .card-without-compare {
    width: 80%;
  }

  .legend-container {
    display: flex;
    justify-content: center;
    padding: 20px 0;
  }

</style>
