import { CognitoUser, AuthenticationDetails, } from 'amazon-cognito-identity-js';
import { userPool, REGION, IDENTITY_POOL_ID } from '../utils/config';
import { credentials } from '../stores/stores';
// TODO:
// [ ] Turn this into an actual Svelte store
const clearCredentialsStore = () => {
    credentials.update((prevCreds) => {
        if (prevCreds)
            prevCreds.creds.clearCachedId();
        AWS.config.credentials = null;
        return null;
    });
};
export const login = (username, password) => {
    return new Promise((resolve, reject) => {
        const authenticationDetails = new AuthenticationDetails({
            Username: username,
            Password: password,
        });
        const cognitoUser = new CognitoUser({
            Username: username,
            Pool: userPool,
        });
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: async function (result) {
                const token = result.getIdToken().getJwtToken();
                const creds = new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: IDENTITY_POOL_ID,
                    Logins: {
                        [`cognito-idp.${REGION}.amazonaws.com/${userPool.getUserPoolId()}`]: token,
                    },
                });
                AWS.config.region = REGION;
                AWS.config.credentials = creds;
                // Clear old stored creds
                clearCredentialsStore();
                creds.clearCachedId();
                const p1 = creds.refreshPromise();
                const p2 = new Promise((resolve, reject) => {
                    cognitoUser.getUserAttributes((err, result) => {
                        if (err)
                            return reject(err);
                        resolve(result);
                    });
                });
                try {
                    const [, userAttrs] = await Promise.all([p1, p2]);
                    const user = userAttrs.reduce((acc, attr) => {
                        acc[attr.getName()] = attr.getValue();
                        return acc;
                    }, {});
                    credentials.set({ creds, user });
                    resolve({ creds, user });
                }
                catch (err) {
                    console.error(err.message || JSON.stringify(err));
                    reject(err);
                }
            },
            onFailure: function (err) {
                console.error(err);
                reject(err);
            },
        });
    });
};
export const logout = () => {
    const user = userPool.getCurrentUser();
    if (user)
        user.signOut();
    clearCredentialsStore();
};
export const forgotPassword = (username) => {
    const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
    });
    return new Promise((resolve, reject) => {
        cognitoUser.forgotPassword({
            onSuccess: (result) => {
                console.log('call result:', result);
                resolve(result);
            },
            onFailure: (err) => {
                reject(err);
            },
        });
    });
};
export const setPassword = (username, code, password) => {
    const cognitoUser = new CognitoUser({
        Username: username,
        Pool: userPool,
    });
    return new Promise((resolve, reject) => {
        cognitoUser.confirmPassword(code, password, {
            onFailure(err) {
                reject(err);
            },
            onSuccess() {
                resolve();
            },
        });
    });
};
