const METRICS_NAME = { 'jump-shot': 'Jumpshot', 'uppercut': 'Uppercut', 'front-squat': 'Front Squat' };
export const fixedMetricName = (metric) => {
    const key = Object.keys(METRICS_NAME).find((metricName) => metric.includes(metricName));
    return METRICS_NAME[key];
};
export function getMetricKey(value) {
    return Object.keys(METRICS_NAME).find((key) => METRICS_NAME[key] === value);
}
export function isIncludeInMovements(metric) {
    return Object.values(METRICS_NAME).includes(metric);
}
export const formatTimeStamp = (event, data) => Math.round((event.timeStamp / 1000000000) * data.metadata.fps);
