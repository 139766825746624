<script>
  import { worldSettingsVisible } from '../../../../stores/stores';
  import { resize } from '../../../../utils/resize';
  import IconButton from '../../ui/IconButton.svelte';
  import TabBar from '../../ui/TabBar.svelte';

  import { tick, createEventDispatcher } from 'svelte';
  import { fly } from 'svelte/transition';

  export let data;

  let activeIndex = 0;
  let selected = 0;
  let container;
  let showAll = true;
  let fullscreen = false;
  let tabs = [];

  let videoPanel;
  let videoButton;
  let content;
  let aspect = 1.77;
  let widthClass = 'w50';

  let videoPanelOpen = false;

  const dispatch = createEventDispatcher();

  updateTabs();

  // updates trial videos when data changes (i.e., when new trial is selected)
  $: if (data) updateTrial();

  // $: if (fullscreen && videoPanel) videoPanel.style.width = '100%';

  function updateTabs() {
    tabs = [
      {
        // text: 'All Videos',
        text: 'All',
        value: 0,
      },
    ];

    data.videos.forEach((video, i) => {
      tabs.push({
        // text: 'Video ' + (i + 1),
        text: i + 1,
        value: i + 1,
      });
    });
  }

  // activates a single video
  function activateVideo(index) {
    for (const v of container.children) {
      v.style.opacity = `0`;
    }
    let selectedVideo = container.children[index];
    selectedVideo.style.opacity = `1`;
    activeIndex = index;
    showAll = false;

    container.style.aspectRatio = aspect;
  }

  // shows all videos in a gallery view
  function showAllVideos() {
    for (const v of container.children) {
      v.style.opacity = `1`;
    }
    showAll = true;

    container.style.aspectRatio = 'auto';
  }

  function loadVideos() {
    if (container) {
      container.innerHTML = '';
      data.videos.forEach((video, i) => {
        video.element.setAttribute('index', i);
        video.element.addEventListener('click', onVideoClick);
        container.appendChild(video.element);
      });
      if (selected == 0) {
        showAllVideos();
      } else {
        activateVideo(activeIndex);
      }

      // get the aspect ratio - will use this for sizing the container later
      let w = data.videos[0].element.videoWidth;
      let h = data.videos[0].element.videoHeight;
      aspect = w / h;

      widthClass = data.videos.length > 4 ? 'w33' : 'w50';
    }
  }

  function onVideoClick() {
    if (showAll) {
      activeIndex = Number(this.getAttribute('index'));
      activateVideo(activeIndex);
      selected = activeIndex + 1;
    }
  }

  // opens/closes the Live Metrics panel
  async function toggleVisibility() {
    if (videoPanelOpen) {
      videoPanelOpen = false;
      await tick;
      dispatch('resize', videoButton.getBoundingClientRect());
    } else {
      videoPanelOpen = true;
      await tick;
      loadVideos();
      // videoPanel.style.width = `500px`;
      dispatch('resize', videoPanel.getBoundingClientRect());
    }
  }

  function toggleFullscreen() {
    if (fullscreen) {
      fullscreen = false;
      videoPanel.style.width = '500px';
    } else {
      fullscreen = true;
      videoPanel.style.width = '100%';
    }
  }

  function handleVideoSelect(e) {
    if (selected == 0 && !showAll) {
      showAllVideos();
    } else if (selected != 0) {
      activateVideo(selected - 1);
    }
  }

  function handleResize(e) {
    if (
      e.detail.x >= window.innerWidth - 860 &&
      e.detail.x <= window.innerWidth - 300
    ) {
      videoPanel.style.width = `${window.innerWidth - e.detail.x}px`;
      dispatch('resize', videoPanel.getBoundingClientRect());
    }
  }

  function updateTrial() {
    updateTabs();
    loadVideos();
  }

</script>

{#if videoPanelOpen}
  <div
    bind:this={videoPanel}
    transition:fly={{ x: 500, duration: 200 }}
    class="videoPanel panel shadow {fullscreen ? 'fullscreen' : ''}"
    class:fade={$worldSettingsVisible}>
    <div class="wrapper">
      <div use:resize on:resize={handleResize} class="dragHandle" />
      <div class="nav">
        <div>
          <h4 class="typography-h4">Videos</h4>
        </div>
        <div>
          {#if fullscreen}
            <IconButton on:click={toggleFullscreen} icon="shrink" />
          {:else}
            <IconButton on:click={toggleFullscreen} icon="expand" />
          {/if}
          <IconButton on:click={toggleVisibility} icon="close-max" />
        </div>
      </div>
      <TabBar {tabs} bind:selected on:select={handleVideoSelect} />
      <div bind:this={content} class="content">
        <div
          class="videoContainer {showAll ? 'showAll' : ''} {widthClass}"
          bind:this={container} />
      </div>
    </div>
  </div>
{:else}
  <div
    bind:this={videoButton}
    class="videoButton shadow"
    class:fade={$worldSettingsVisible}>
    <button on:click={toggleVisibility}>Videos</button>
  </div>
{/if}

<style>
  .videoPanel {
    position: absolute;
    top: 75px;
    right: 0px;
    height: auto;
    width: 500px;
    box-sizing: border-box;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid var(--color-gray-lighter);
    /* border: 4px solid yellow; */
    border-left: none;
    border-right: none;
  }

  .videoPanel.fullscreen {
    top: 0px;
    z-index: 5;
    border-radius: 8px 0px 0px 0px;
    width: 100%;
    height: 100%;
  }

  .videoButton {
    position: absolute;
    top: 75px;
    right: -93px;
    height: auto;
    width: auto;
    transform: rotate(90deg);
    transform-origin: top left;
    background: none;
    border-radius: 0px 0px 8px 8px;
  }

  .videoButton button {
    height: 44px;
    margin: 0;
    padding: 0px 24px;
    border-radius: 0px 0px 8px 8px;
  }

  .dragHandle {
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    cursor: ew-resize;
    z-index: 1;
  }

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: var(--color-white);
    border-radius: 0px 8px 0px 8px;
    box-sizing: border-box;
    /* border: 1px solid green; */
  }

  .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* height: calc(100% - 40px); */
    box-sizing: border-box;
    margin-top: -1px;
    border-bottom: 1px solid var(--color-gray-lighter);
    /* border: 4px solid blue; */
    background: var(--color-gray-dark);
  }

  .nav {
    display: flex;
    padding: 0px 12px 0px 16px;
    border-radius: 8px 0px 0px 0px;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background-color: var(--color-gray-extralight);
  }

  .nav div {
    display: flex;
    align-items: center;
  }

  .videoContainer {
    position: relative;
    width: 100%;
    height: 100%;
  }

  :global(video) {
    width: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    box-sizing: border-box;
    border: 3px solid var(--color-gray-dark);
  }

  /* Gallery view */
  .videoContainer.showAll {
    display: flex;
    flex-wrap: wrap;
    padding-top: 3px;
    padding-left: 3px;
  }

  :global(.videoContainer.showAll video) {
    position: relative;
    /* width: 50%; */
    border: none;
    border-right: 3px solid var(--color-gray-dark);
    border-bottom: 3px solid var(--color-gray-dark);
    cursor: pointer;
  }

  :global(.videoContainer.showAll.w50 video) {
    width: 50%;
  }

  :global(.videoContainer.showAll.w33 video) {
    width: 33%;
  }

  /* Full screen */
  :global(.videoPanel.fullscreen videoContainer) {
    display: flex;
    flex-wrap: wrap;
  }

  :global(.videoPanel.fullscreen video) {
    height: 100%;
    object-fit: contain;
  }

  :global(.videoPanel.fullscreen .showAll.w50 video) {
    height: 50%;
  }

  :global(.videoPanel.fullscreen .showAll.w33 video) {
    height: 33%;
  }

  .videoPanel.fullscreen .content {
    height: calc(100% - 68px);
  }

  .videoPanel.fullscreen .videoContainer {
    margin: 0 auto;
  }

  .fade {
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease-in;
  }

</style>
