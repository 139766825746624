<script>
  export let firstTrialName = '';
  export let secondTrialName = '';

</script>

<div class="legend-container">
  <div class="inner-legend-container">
    <span class="legend-icon main-series-legend" />
    <p class="legend-text">{firstTrialName}</p>
  </div>

  <div class="inner-legend-container" style="margin-left: 12px;">
    <span class="legend-icon comparison-series-legend" />
    <p class="legend-text">{secondTrialName}</p>
  </div>
</div>

<style>
  .legend-container {
    display: flex;
    justify-content: space-around;
    background-color: var(--color-gray-extralight);
    border-radius: 5px;
    width: 80%;
  }

  .inner-legend-container {
    display: flex;
    align-items: center;
  }

  .legend-icon {
    width: 19px;
    height: 8px;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 17px;
  }

  .main-series-legend {
    background-color: var(--color-secondary-main);
  }

  .comparison-series-legend {
    background-color: var(--color-primary-main);
  }

  .legend-text {
    font-size: var(--typography-size-smaller);
    font-weight: var(--typography-weight-bold);
    color: var(--color-gray-dark);
    cursor: pointer;
  }

</style>
