export class Tick {
    constructor() {
        this.queue = [];
        let last = performance.now();
        const tick = (time) => {
            const delta = Math.min(150, time - last);
            last = time;
            for (let i = this.queue.length - 1; i >= 0; i--) {
                const callback = this.queue[i];
                callback(delta, time);
            }
            requestAnimationFrame(tick);
        };
        requestAnimationFrame(tick);
    }
    add(callback) {
        if (!this.queue.includes(callback))
            this.queue.unshift(callback);
    }
    remove(callback) {
        if (this.queue.includes(callback))
            this.queue.splice(this.queue.indexOf(callback), 1);
    }
}
export default new Tick();
