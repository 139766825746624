<script type="ts">
  import Button from '../ui/Button.svelte';
  import { trialMap } from '../../../stores/stores';
  import { createEventDispatcher, onMount, onDestroy, tick } from 'svelte';
  import Menu from '../ui/Menu.svelte';
  import { hoist } from '../../../utils/hoist';
  import Icon from '../ui/Icon.svelte';
  import { comparisonTrial, trial } from '../../../stores/trial';

  export let items = [];
  export let isMainTrial = false;
  export let selectedTrials;
  export let onSelect; 

  let matchingItems = items;
  let selectedTrialIndex;
  let isOpen = false;
  let dropdown;
  let toggle;
  let menu;
  let isSeekerOpen = false;
  let trialToSearch = '';

  // update the dropdown items whenever selectedTrials changes (i.e., when the date filter is applied)
  $: {
    items = [];
    let selectionFound = false;
    selectedTrials.forEach((trial) => {
      if (trial.id == selectedTrialIndex) {
        selectionFound = true;
      }
      items.push({
        id: trial.id,
        text: trial.displayName,
        value: trial,
        selected: trial.id == selectedTrialIndex,
      });
    });

    // If currently selected trial is not in the new set of filtered trials,
    // select the first trial in the new set
    if (!selectionFound) {
      selectedTrialIndex = selectedTrials[0].id;
      dispatch('select', {
        item: {
          id: selectedTrials[0].id,
          selected: true,
          text: selectedTrials[0].displayName,
          value: selectedTrials[0],
        },
      });
      items[0].selected = true;
    }
  }

  $: matchingItems = items.filter((item) =>
    item.text.toLowerCase().includes(trialToSearch.toLowerCase())
  );

  const dispatch = createEventDispatcher();

  function handleSelection({ detail }) {
    onSelect(detail)
    selectedTrialIndex = detail.item.id;
    matchingItems.forEach((item) => {
      item.selected = item.id == selectedTrialIndex;
    });
    reset();

    dispatch('select', { item: detail.item });
  }
  
  // Close the dropdown menu if user clicks anywhere else in the document body
  function handleWindowEvent(e) {
    if (!dropdown.contains(e.target) && isMainTrial && !isSeekerOpen) {
      isOpen = false;
    }
  }
  
  function handleKeydown(e) {
    if (e.key === 'Escape') {
      isOpen = false;
    }
  }
  
  // Hoist the dropdown to the top level of the DOM so it will appear on top of everything else
  $: if (isOpen) {
    handleHoist();
  }
  
  async function handleHoist() {
    await tick(); // needed so getBoundingClientRect will give correct coordinates
    hoist(menu, toggle, 'bottom right', 4); // there is a display bug here - shouldn't need to do this twice... need to investigate more.
  }

  function handleResize() {
    if (isOpen) {
      handleHoist();
    }
  }

  function removeComparisonTrial() {
    isOpen = !isOpen;
    dispatch('removeComparisonTrial');
  }

  function openSeeker() {
    isSeekerOpen = true;
  }

  function reset() {
    isSeekerOpen = false;
    trialToSearch = '';
    isOpen = false;
  }

  function openCompare() {
    isOpen = false;
    dispatch('trialComparisonStart');
  }

  onMount(() => {
    if (!isMainTrial) {
      isOpen = true;
    }
  });

  onDestroy(() => {
    if (menu && menu.parentNode) {
      menu.parentNode.removeChild(menu);
    }
  });

</script>

<svelte:window on:resize={handleResize} />
<svelte:body on:click={handleWindowEvent} on:keydown={handleKeydown} />

<div bind:this={dropdown} class="dropdown">
  <div bind:this={toggle} class="toggle" on:click={() => (isOpen = !isOpen)}>
    <slot name="toggle">
      <Button icon="down" class="icon-r">
        {$trialMap.get(selectedTrialIndex).displayName}
      </Button>
    </slot>
  </div>
  {#if isOpen}
    <div bind:this={menu} class="menu shadow">
      <div class="gray-input seeker" on:click={openSeeker}>
        {#if isSeekerOpen}
          <input
            id="seeker"
            name="seeker"
            class="input"
            bind:value={trialToSearch} />
        {/if}
        <div class="icon">
          <Icon name="search" height={25} />
        </div>
      </div>
      <slot name="menu">
        <div class="items">
          <Menu on:select={handleSelection} items={matchingItems} />
        </div>
      </slot>
      {#if isMainTrial && $trial.supportsComparison}
        <div class="gray-input compare-with">
          {#if !$comparisonTrial}
            <Button class="primary" on:click={openCompare}>
              <Icon name="compare" height={25} fill="white" />
              Compare with...
            </Button>
          {/if}
        </div>
      {:else}
        <div class="gray-input save-cancel">
          {#if $comparisonTrial}
            <Button class="primary" on:click={removeComparisonTrial}>
              Remove
            </Button>
          {/if}
        </div>
      {/if}
    </div>
  {/if}
</div>

<style>
  .toggle {
    margin-bottom: 6px;
  }

  .menu {
    position: absolute;
    max-height: 310px;
    min-width: 250px;
    background: var(--color-white);
  }
  .items {
    overflow-y: scroll;
    height: 205px;
  }
  .gray-input {
    background: #eeeeee;
    padding: 10px;
    text-align: end;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .seeker {
    border-radius: 5px 5px 0px 0px;
    padding: 10px;
    border: 1px solid var(--color-gray-lighter);
  }
  .input {
    padding: 6px;
    border-radius: 10px;
    border: 1px solid var(--color-gray-lighter);
  }
  .icon {
    cursor: pointer;
  }
  .input:focus-visible {
    border: 1px solid var(--color-gray-lighter);
  }
  .compare-with {
    border-radius: 5px 5px 0px 0px;
  }
  .save-cancel {
    display: flex;
    justify-content: space-between;
  }

</style>
