import { compose, concat, head, identity, isEmpty, last, map, max, repeat, slice, sortBy, without, } from 'ramda';
import { golf } from './selectors';
export class Trial {
    constructor(data, recording) {
        this.data = data;
        this.displayName = recording.displayName;
        this.duration = data.videos[0].element.duration;
        this.fps = data.metadata.fps;
        this.discipline = data.metadata.Discipline;
    }
    static fromATPipelineData(data, recording, mainTrial) {
        var _a, _b;
        return ((_b = (_a = data.metadata) === null || _a === void 0 ? void 0 : _a.Discipline) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === 'golf'
            ? mainTrial
                ? new ComparisonGolfTrial(data, recording, mainTrial)
                : new GolfTrial(data, recording)
            : new GenericTrial(data, recording);
    }
    static withMainTrial(data, recording, mainTrial) {
        return this.fromATPipelineData(data, recording, mainTrial);
    }
    get supportsComparison() {
        return false;
    }
    get defaultTab() {
        return this.tabs[0];
    }
    get tabs() {
        return ['Time Series', 'Sequence', 'Metrics'];
    }
    get firstFrame() {
        return 0;
    }
    get lastFrame() {
        return this.framesCount;
    }
    get framesCount() {
        // The pipeline output has data for (frames - 1) frames.
        // We are subtracting 2 because data array is zero based.
        return Math.floor(this.duration * this.fps) - 2;
    }
    get keyFramesPoints() {
        return [];
    }
    get keyFrames() {
        return [];
    }
    get hasKeyFrames() {
        return !isEmpty(this.keyFramesPoints);
    }
    get metricKeys() {
        return [];
    }
    supportsTab(tabName) {
        return this.tabs.includes(tabName);
    }
    trimFramesData(data) {
        return data;
    }
    adjustFrameNumber(frame) {
        return frame;
    }
}
export class GenericTrial extends Trial {
}
export class GolfTrial extends Trial {
    constructor(data, recording) {
        super(data, recording);
        this.golfKeyFrames = golf.keyFrames(data);
    }
    get supportsComparison() {
        return true;
    }
    get tabs() {
        return ['X-Factor', 'Sway', 'Kinetic Chain', 'Snapshots'].concat(without(['Sequence'], super.tabs));
    }
    get keyFramesPoints() {
        const makePoints = compose(sortBy(identity), map((it) => it.value));
        return makePoints(this.keyFrames);
    }
    get keyFrames() {
        return Object.entries(this.golfKeyFrames).map(([name, value]) => ({
            name,
            value: value - this.firstFrame,
        }));
    }
    get metricKeys() {
        return [
            'Stance',
            'Mid-Backswing',
            'Peak Backswing',
            'Mid-Downswing',
            'Impact',
            'Follow Through',
        ];
    }
    get firstFrame() {
        return this.golfKeyFrames.Address;
    }
    get lastFrame() {
        return this.golfKeyFrames.FollowThrough;
    }
    get framesCount() {
        return this.lastFrame - this.firstFrame;
    }
    trimFramesData(data) {
        return slice(this.firstFrame - 1, this.lastFrame + 1, data);
    }
    adjustFrameNumber(frame) {
        return frame - this.firstFrame + 1;
    }
}
class ComparisonGolfTrial extends GolfTrial {
    constructor(data, recording, mainTrial) {
        super(data, recording);
        this.mainTrial = mainTrial;
    }
    impactOffset() {
        return this.golfKeyFrames.Impact - this.mainTrial.golfKeyFrames.Impact;
    }
    trimFramesData(data) {
        const base = super.trimFramesData(data);
        const main = this.mainTrial.trimFramesData(data);
        return base.length < main.length
            ? this.fillMissingData(base, main.length - base.length)
            : base;
    }
    fillMissingData(data, difference) {
        const needsFillingAtTheEnd = this.lastFrame + 1 > data.length;
        const fill = repeat(needsFillingAtTheEnd ? last(data) : head(data), difference);
        return needsFillingAtTheEnd ? concat(data, fill) : concat(fill, data);
    }
    get firstFrame() {
        return max(this.mainTrial.firstFrame + this.impactOffset(), 1);
    }
    get lastFrame() {
        return this.mainTrial.lastFrame + this.impactOffset();
    }
}
