<script>
  import { golf } from '../../../../../models/selectors';
  import { fps } from '../../../../../stores/playerStore';
  import { color, typography } from '../../../../../utils/styles';
  import { maxValue } from '../../../../../utils/math';
  import Gauge from '../../../charts/Gauge.svelte';
  import LinearGauge from '../../../charts/LinearGauge.svelte';
  import { comparisonTrialData } from '../../../../../stores/trial';

  export let data;

  $: metrics = golf.kineticMetrics(data);
  $: comparisonMetrics = $comparisonTrialData
    ? golf.kineticMetrics($comparisonTrialData)
    : undefined;

  $: series = buildSerie(metrics);
  $: comparisonSeries = comparisonMetrics
    ? buildSerie(comparisonMetrics)
    : undefined;

  const timingToMilliseconds = (value) => (value / $fps) * 1000;

  const INITIAL_STEPS = [0, 200, 400, 600, 800, 1000];
  const UNIT_STYLE = `font-size: ${typography.size(
    'xsmall'
  )} !important; color: ${color('error-main')} !important;`;

  function buildSerie(serie) {
    const {
      angularVelocities: {
        pelvisRotationVelocity,
        trunkRotationVelocity,
        armRotationVelocity,
      },
      peakTimings: { pelvisPeakTiming, trunkPeakTiming, armPeakTiming },
    } = serie;

    return {
      angularVelocities: {
        pelvisRotationVelocity: {
          ...pelvisRotationVelocity,
          Data: maxValue(pelvisRotationVelocity.Data),
        },
        trunkRotationVelocity: {
          ...trunkRotationVelocity,
          Data: maxValue(trunkRotationVelocity.Data),
        },
        armRotationVelocity: {
          ...armRotationVelocity,
          Data: maxValue(armRotationVelocity.Data),
        },
      },
      peakTimings: {
        pelvisPeakTiming: {
          ...pelvisPeakTiming,
          value: timingToMilliseconds(pelvisPeakTiming),
        },
        trunkPeakTiming: {
          ...trunkPeakTiming,
          value: timingToMilliseconds(trunkPeakTiming),
        },
        armPeakTiming: {
          ...armPeakTiming,
          value: timingToMilliseconds(armPeakTiming),
        },
      },
    };
  }

</script>

<div class="outer-container">
  <div class="grid">
    <div>
      <Gauge
        orientation="vertical"
        data={{ ...series.angularVelocities.pelvisRotationVelocity, Average: 600 }}
        comparisonData={comparisonSeries?.angularVelocities?.pelvisRotationVelocity}
        steps={INITIAL_STEPS}
        unit="deg/s"
        unitStyle={UNIT_STYLE}
        outterUnit=" " />
    </div>

    <span class="divider" />

    <div>
      <LinearGauge
        data={{ ...series.peakTimings.pelvisPeakTiming, average: -180, label: 'Pelvis Peak Timing' }}
        comparisonData={comparisonSeries?.peakTimings?.pelvisPeakTiming}
        unit="ms" />
    </div>
  </div>

  <span class="horizontal-divider" />

  <div class="grid">
    <div>
      <Gauge
        orientation="vertical"
        data={{ ...series.angularVelocities.trunkRotationVelocity, Average: 700 }}
        comparisonData={comparisonSeries?.angularVelocities?.trunkRotationVelocity}
        steps={INITIAL_STEPS}
        unit="deg/s"
        unitStyle={UNIT_STYLE}
        outterUnit=" " />
    </div>

    <span class="divider" />

    <div>
      <LinearGauge
        data={{ ...series.peakTimings.trunkPeakTiming, average: -140, label: 'Trunk Peak Timing' }}
        comparisonData={comparisonSeries?.peakTimings?.trunkPeakTiming}
        unit="ms" />
    </div>
  </div>

  <span class="horizontal-divider" />

  <div class="grid">
    <div>
      <Gauge
        orientation="vertical"
        data={{ ...series.angularVelocities.armRotationVelocity, Label: 'Arm Rotation Velocity', Average: 900 }}
        comparisonData={comparisonSeries?.angularVelocities?.armRotationVelocity}
        steps={INITIAL_STEPS}
        unit="deg/s"
        unitStyle={UNIT_STYLE}
        outterUnit=" " />
    </div>

    <span class="divider" />

    <div>
      <LinearGauge
        data={{ ...series.peakTimings.armPeakTiming, label: 'Arm Peak Timing', average: -60 }}
        comparisonData={comparisonSeries?.peakTimings?.armPeakTiming}
        unit="ms"
        unitStyle={UNIT_STYLE}
        outterUnit=" " />
    </div>
  </div>
</div>

<style>
  .grid {
    display: flex;
    flex-direction: row;
    justify-content: center;
    height: 240px;
  }

  .outer-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .divider {
    margin: 0 10px 0 10px;
    width: 1px;
    background-color: var(--color-gray-lighter);
  }

  .horizontal-divider {
    height: 1px;
    width: 100%;
    background-color: var(--color-gray-lighter);
    z-index: 1;
  }

</style>
