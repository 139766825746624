export function resize(node) {
    let x;
    let y;
    function handleMousedown(e) {
        x = e.clientX;
        y = e.clientY;
        window.addEventListener('mousemove', handleMousemove);
        window.addEventListener('mouseup', handleMouseup);
    }
    function handleMousemove(e) {
        const dx = e.clientX - x;
        const dy = e.clientX - y;
        x = e.clientX;
        y = e.clientY;
        node.dispatchEvent(new CustomEvent('resize', {
            detail: { x, y, dx, dy },
        }));
    }
    function handleMouseup(e) {
        x = e.clientX;
        y = e.clientY;
        node.dispatchEvent(new CustomEvent('resizeend', {
            detail: { x, y },
        }));
        window.removeEventListener('mousemove', handleMousemove);
        window.removeEventListener('mouseup', handleMouseup);
    }
    node.addEventListener('mousedown', handleMousedown);
    return {
        destroy() {
            node.removeEventListener('mousedown', handleMousedown);
        },
    };
}
