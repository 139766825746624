<script>
  export let leftValue;
  export let rightValue;

</script>

<div>
  L:
  <span>{leftValue?.toFixed(2)}</span>
  / R:
  <span>{rightValue?.toFixed(2)}</span>
</div>

<style>
  span {
    font-weight: var(--typography-weight-bold);
  }

</style>
