<script>
  import { getContext, onDestroy } from 'svelte';
  import BMAMenuItem from './BMAMenuItem.svelte';

  export let metrics;
  export let expanded = false;
  export let subMenu = false;

  let menu;
  let context = getContext('dropdown');

  function handleMouseover(e) {
    if (menu && subMenu && !menu.parentNode.contains(e.target)) {
      expanded = false;
      document.body.removeEventListener('mouseover', handleMouseover);
    }
  }

  onDestroy(() => {
    document.body.removeEventListener('mouseover', handleMouseover);
  });

</script>

<svelte:body on:mouseover={handleMouseover} />

{#if $context.isOpen}
  <div bind:this={menu} class="menu" class:subMenu class:expanded>
    <ul class="dropdownOptions shadow">
      {#each metrics as metric}
        <BMAMenuItem on:select {metric} />
      {/each}
    </ul>
  </div>
{/if}

<style>
  .menu {
    display: none;
    position: relative;
    max-height: 300px;
    top: 8px;
  }

  .menu.expanded {
    display: block;
  }

  .subMenu {
    left: 100%;
    top: -36px;
  }

  ul {
    position: absolute;
    width: 200px;
  }

</style>
