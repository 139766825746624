<script>
  import { createEventDispatcher } from 'svelte';
  const dispatch = createEventDispatcher();

</script>

<div class="wrapper">
  <div class="message">
    <p>Error: Recordings could not be loaded.</p>
    <button on:click={() => dispatch('logout')}>Logout</button>
  </div>
</div>

<style>
  .wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background: var(--color-gray-extralight);
  }

  .message {
    padding: 20px;
    background: var(--color-white);
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    width: 260px;
    height: 120px;
  }

</style>
