<script lang="ts">
  import { isNil } from 'ramda';
  import LeftRightCell from './LeftRightCell.svelte';
  import TabValueWithColor from './TabValueWithColor.svelte';

  interface CellValue {
    left?: number;
    right?: number;
    value?: number;
  }

  export let main: CellValue = {};

  export let comparison: CellValue = undefined;
  export let border = false;
  export let title: string = undefined;

  $: hasComparisonValue = !isNil(comparison);


</script>

{#if !isNil(main.left) && !isNil(main.right)}
  <td class={border && 'borderStyle'}>

    <TabValueWithColor {hasComparisonValue}>

      <div slot="main-value">
        <LeftRightCell leftValue={main.left} rightValue={main.right} />
      </div>
      <div slot="comparison-value">

        {#if hasComparisonValue}

          <LeftRightCell
            leftValue={comparison.left}
            rightValue={comparison.right} />
        {/if}
      </div>
    </TabValueWithColor>
  </td>
{:else if !isNil(main.value)}
  <td class={border && 'borderStyle'}>

    <TabValueWithColor {hasComparisonValue}>
      <span slot="main-value">{main.value?.toFixed(2)}</span>
      <span slot="comparison-value">
        {#if hasComparisonValue}{comparison.value?.toFixed(2)}{/if}

      </span>
    </TabValueWithColor>
  </td>
{:else if title}
  <td class="borderStyle"><span class="blueTitle">{title}</span></td>
{:else}
  <td class={border && 'borderStyle'}><span>N/A</span></td>
{/if}

<style>
  td {
    min-width: 150px;
    height: 30px;
    font-size: var(--typography-size-xsmall);
    padding: 8px 4px 8px 16px;
    border: 0px solid var(--color-gray-lighter);
  }
  span {
    font-weight: var(--typography-weight-bold);
  }
  .borderStyle {
    border-right: 2px solid var(--color-gray-lighter);
  }
  .blueTitle {
    color: var(--color-primary-main);
    font-weight: var(--typography-weight-regular);
  }

</style>
