<script lang="ts">
  import { fade } from 'svelte/transition';
  import { quintOut } from 'svelte/easing';
  import { createEventDispatcher } from 'svelte';

  export let error: boolean;
  export let errorMsg = '';
  export let codeSent = false;

  const dispatch = createEventDispatcher();

  let username = '';
  let password = '';
  let code = '';

  function resetForm() {
    error = false;
    errorMsg = '';
    codeSent = false;
    username = '';
    password = '';
    code = '';
  }

  function cancel() {
    resetForm();
    dispatch('cancelReset');
  }

  function requestCode() {
    error = false;
    errorMsg = '';
    dispatch('requestCode', { username });
  }

  function setPassword() {
    error = false;
    errorMsg = '';
    dispatch('setPassword', { username, code, password });
  }

</script>

<div class="wrapper" transition:fade={{ duration: 400, easing: quintOut }}>
  <div class="content">
    {#if !codeSent}
      <h2 class="typography-h2 color-and-margin">Forgot Password?</h2>
      <div class="form">
        {#if errorMsg}
          <div class="row errorMsg">{errorMsg}</div>
        {/if}
        <div class="row username" class:error>
          <label for="username">
            Enter your email and we will send you a code to reset your password.
          </label>
          <input
            bind:value={username}
            id="username"
            type="text"
            name="username"
            placeholder="email"
            required />
        </div>

        <div class="row action">
          <button class="secondary" on:click={cancel}>Cancel</button>
          <button
            type="submit"
            title="Request Code"
            class="primary"
            on:click={requestCode}>
            <span class="label">Request Code</span>
          </button>
        </div>
      </div>
    {:else}
      <h2 class="typography-h2 color-and-margin">Set Password</h2>
      <div class="form">
        {#if errorMsg}
          <div class="row errorMsg">{errorMsg}</div>
        {/if}
        <div class="row" class:error>
          <label for="code"> Enter verification code: </label>
          <input
            bind:value={code}
            id="code"
            type="text"
            name="code"
            placeholder="verification code"
            required />
        </div>

        <div class="row" class:error>
          <label for="password"> New password: </label>
          <input
            bind:value={password}
            type="password"
            name="password"
            placeholder="password"
            required />
        </div>

        <div class="row action">
          <button class="secondary" on:click={cancel}>Cancel</button>
          <button
            type="submit"
            title="Set Password"
            class="primary"
            on:click={setPassword}>
            <span class="label">Set Password</span>
          </button>
        </div>
      </div>
    {/if}
  </div>
</div>

<style>
  .wrapper {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('/assets/images/hero.jpg');
    background-position: right;
    width: 100%;
    height: 100%;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
  }

  .content {
    background: var(--color-gray-extralight);
    border-radius: 10px;
    padding: 20px;
    width: 380px;
  }

  .color-and-margin {
    color: var(--color-primary-main);
    margin-bottom: 1.5rem;
    margin-top: 5px;
  }

  .row {
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
  }

  .row.action {
    flex-direction: row;
    justify-content: right;
  }

  input,
  label,
  button {
    border: none;
    border-radius: 0;
    padding: 0;
  }

  label {
    margin-bottom: 8px;
  }

  input:focus,
  label:focus,
  button:focus {
    outline: none;
  }

  label {
    font-size: var(--typography-size-smaller);
    box-sizing: border-box;
  }

  input {
    flex-grow: 1;
    height: 70px;
    line-height: 70px;
    padding: 20px;
    padding-top: 25px;
    font-size: var(--typography-size-small);
    box-sizing: border-box;
    border: 1px solid var(--color-gray-lighter);
    color: var(--color-primary-main);
  }

  input::placeholder {
    opacity: 0.5;
  }

  button {
    background-color: var(--color-primary-main);
    white-space: nowrap;
    height: auto;
    margin-top: 0.5em;
    margin-left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--typography-size-small);
    color: var(--color-white);
    cursor: pointer;
  }

  button.primary {
    height: 52px;
    padding: 0 16px;
  }

  button.secondary {
    background: none;
    color: var(--color-primary-main);
    margin-right: 30px;
  }

  button .label {
    min-width: 100px;
  }

  .errorMsg {
    border: 1px solid var(--color-error-light);
    background: var(--color-error-lighter);
    color: 1px solid var(--color-error-main);
    margin-bottom: 12px;
    padding: 8px;
    border-radius: 4px;
  }

</style>
