<script type="ts">
  import BMADropdown from '../BMADropdown.svelte';
  import Switch from '../../../ui/Switch.svelte';
  import CustomLegendItem from '../../../ui/CustomLegendItem.svelte';

  export let yValues;
  export let comparisonYValues = undefined;
  export let series;
  export let comparisonSeries = undefined;
  export let colors;
  export let chartComponent;
  export let metrics;

  function toggleSerieVisibility(e) {
    const serieIndex = e.target.id;
    chartComponent.toggleSerieVisibility(serieIndex);
    yValues[serieIndex].visible = !yValues[serieIndex].visible;
  }

</script>

<div class="metricFooterContainer">
  {#each series as s, index}
    <div class="columns">
      <div class="dropdownAndTogglerContainer">
        <div class="dropdownAndValueContainer">
          <BMADropdown
            on:select={chartComponent.handleSelect}
            on:select
            label={s.data.Label}
            color={colors[index]}
            responsive={true}
            {metrics}
            {index} />
          {#if yValues[index].visible}
            <div class="legends">
              <CustomLegendItem
                data={yValues[index].y}
                variant="contained"
                iconVisible={!!comparisonSeries}
                backgroundColor={colors[index]} />

              {#if comparisonSeries && comparisonYValues[index].visible}
                <CustomLegendItem
                  data={comparisonYValues[index].y}
                  variant="outlined"
                  backgroundColor={colors[index]} />
              {/if}
            </div>
          {/if}
        </div>
        <Switch on:click={toggleSerieVisibility} id={index} />
      </div>
    </div>
  {/each}
</div>

<style>
  .metricFooterContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 0 12px 0 24px;
  }

  .columns {
    flex-basis: 50%;
  }

  .dropdownAndValueContainer {
    margin-right: 5px;
    margin-bottom: 5px;
    width: 70%;
  }

  .yValue {
    font-size: var(--typography-size-smaller);
    color: var(--color-gray);
    margin-top: 3px;
    margin-left: 3px;
  }

  .dropdownAndTogglerContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .legends {
    width: 100%;
    display: flex;
  }

</style>
