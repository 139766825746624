<script>
  import { hoist } from '../../../../utils/hoist.ts';
  import { createEventDispatcher, onDestroy, tick, setContext } from 'svelte';
  import { writable } from 'svelte/store';
  import BMADropdownToggle from './BMADropdownToggle.svelte';
  import BMAMenu from './BMAMenu.svelte';

  export let metrics;
  export let label;

  // if color == null, no color bubble will be shown
  export let color = null;

  // needed for dropdowns associated with Highcharts (e.g., Time Series, Sequence)
  // index lets Highcharts know which series is supposed to change on selection
  export let index;

  // set to true for responsive dropdown toggle
  export let responsive = false;

  let isOpen;
  let dropdown;
  let menu;
  let toggle;

  let context = writable({});
  setContext('dropdown', context);

  $: {
    context.update(() => {
      return {
        isOpen,
        toggle: () => (isOpen = !isOpen),
      };
    });
  }

  // Forward the selection event to the container element
  const dispatch = createEventDispatcher();

  function handleSelect(e) {
    isOpen = false;
    dispatch('select', {
      index: index,
      value: e.detail.value,
    });
  }

  // Hoist the menu to the top of the DOM so it floats over the rest of the content
  $: if (isOpen) {
    handleHoist();
  }

  async function handleHoist() {
    await tick(); // needed so the getBoundingClientRect will give correct coordinates
    hoist(menu, toggle);
  }

  // Close the dropdown menu if user clicks anywhere else in the document body
  function handleWindowEvent(e) {
    if (!dropdown.contains(e.target) && !menu.contains(e.target)) {
      isOpen = false;
    }
  }

  function handleKeydown(e) {
    if (e.key === 'Escape') {
      isOpen = false;
    }
  }

  onDestroy(() => {
    menu.parentNode.removeChild(menu);
  });

</script>

<svelte:body on:click={handleWindowEvent} on:keydown={handleKeydown} />

<div bind:this={dropdown} class="dropdown">
  <span bind:this={toggle}>
    <BMADropdownToggle {color} {label} {responsive} />
  </span>
  <div bind:this={menu} class="menu">
    <BMAMenu on:select={handleSelect} {metrics} expanded={isOpen} />
  </div>
</div>

<style>
  .dropdown {
    position: relative;
    display: inline;
  }

  .menu {
    position: absolute;
    z-index: var(--z-index-modal);
  }

</style>
