<script>
  import IconButton from '../ui/IconButton.svelte';

  export let title = '';
  export let dismissable = true;
  export let isOpen = true;
  export let handleCloseModal;

  let modal;
  let background;

  function handleClick(e) {
    if (e.target.contains(background)) {
      isOpen = false;
    }
  }

  function handleKeydown(e) {
    if (e.key == 'Escape') {
      isOpen = false;
    }
  }

</script>

<svelte:body on:keydown={handleKeydown} />
<div bind:this={background} on:click={handleClick} class="container">
  <div bind:this={modal} class="modal">
    <div class="nav">
      <h4 class="typography-h4">{title}</h4>
      {#if dismissable}
        <IconButton on:click={handleCloseModal} icon="close-max" />
      {/if}
    </div>
    <div class="content">
      <slot />
    </div>
  </div>
</div>

<style>
  .container {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(38, 38, 38, 0.5);
    z-index: 2;
  }

  .modal {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    min-height: 200px;
    max-width: 50%;
    max-height: 80%;
    margin-top: 80px;
    border-radius: 8px;
    background: var(--color-white);
  }

  .content {
    height: 100%;
    padding: 16px;
    overflow-y: auto;
    font-size: var(--typography-size-smaller);
  }

  .nav {
    display: flex;
    padding: 0px 12px 0px 16px;
    border-radius: 8px 8px 0px 0px;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background-color: var(--color-gray-extralight);
  }

</style>
