// https://svelte.dev/repl/d283589caa554badb16644ad40682802?version=3.38.2
import Highcharts from 'highcharts';
import Annotations from 'highcharts/modules/annotations';
import HighchartsMore from 'highcharts/highcharts-more.js';
import SolidGauge from 'highcharts/modules/solid-gauge.js';
import Bullet from 'highcharts/modules/bullet';
Annotations(Highcharts);
HighchartsMore(Highcharts);
SolidGauge(Highcharts);
Bullet(Highcharts);
export function highcharts(node, config) {
    const redraw = true;
    const oneToOne = true;
    const chart = Highcharts.chart(node, config);
    return {
        update(config) {
            chart.update(config, redraw, oneToOne);
            node.dispatchEvent(new CustomEvent('update', {
                detail: { chart: chart },
            }));
        },
        destroy() {
            chart.destroy();
        },
    };
}
