export function toTitleCase(text) {
    return text
        .toLowerCase()
        .split(' ')
        .map((word) => word[0].toUpperCase() + word.slice(1))
        .join(' ');
}
export function toSentenceCase(text) {
    return text[0].toUpperCase() + text.slice(1).toLowerCase();
}
