<script>
  import { onMount, createEventDispatcher } from 'svelte';
  import Icon from '../Icon.svelte';
  import Calendar from '../datepicker/Calendar.svelte';

  let dateinput;
  let calendar;
  let focused;
  let calendarVisible = false;

  export let selected = null;

  const dispatch = createEventDispatcher();

  function focus() {
    dateinput.focus();
    focused = true;
  }

  function handleSelect(e) {
    selected = e.detail.selected;
    calendarVisible = false;
    dispatch('select', {
      selected: selected,
    });
  }

  function handleBodyClick(e) {
    if (calendarVisible && !calendar.parentNode.contains(e.target)) {
      calendarVisible = false;
    }
  }

  onMount(() => {
    dateinput.addEventListener('focus', () => {
      calendarVisible = true;
    });
  });

</script>

<svelte:body on:click={handleBodyClick} />

<div class="container">
  <div class="datefield" class:focused on:click={focus}>
    <Icon name="calendar-outlined" height="16" width="16" />
    <input
      bind:this={dateinput}
      type="text"
      value={selected ? selected.toLocaleDateString() : 'All Dates'}
      on:blur={() => (focused = false)} />
  </div>
  {#if calendarVisible}
    <div bind:this={calendar} class="calendar">
      <Calendar on:select={handleSelect} />
    </div>
  {/if}
</div>

<style>
  .container {
    position: relative;
  }

  .datefield {
    position: relative;
    display: flex;
    align-items: center;
    height: 36px;
    box-sizing: border-box;
    margin-right: 12px;
    padding: 0 16px 0 12px;
    border: 1px solid var(--color-primary-main);
    border-radius: 18px;
    background: var(--color-white);
    font-size: var(--typography-size-smaller);
    color: var(--color-primary-main);
    transition: background var(--transition-button-background);
  }

  .datefield:hover {
    background: var(--color-gray-extralight);
  }

  .datefield.focused {
    border: 1px solid var(--color-primary-main);
    box-shadow: 0 0 0 2px var(--color-primary-main);
  }

  input {
    height: 36px;
    width: 96px;
    box-sizing: border-box;
    padding: 0 8px;
    color: var(--color-primary-main);
    font-size: var(--typography-size-smaller);
    background: none;
    border: none;
  }

  input:focus {
    outline: none;
  }

  .calendar {
    position: absolute;
    top: 42px;
    right: 12px;
    background: var(--color-white);
    border: var(--color-gray-lighter);
    border-radius: 8px;
  }

</style>
