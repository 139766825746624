<script>
  import { color } from '../../../utils/styles';

  import Icon from './Icon.svelte';
  export let icon = null;
  export let isDisabled = false;
  export let borderless = false;
  let classes = $$props.class ? $$props.class + ' icon' : 'icon';
  let primary = $$props.class && $$props.class.includes('primary');

  export let fill = '';

  if (!fill && primary) {
    fill = 'rgba(255, 255, 255, 1)'; // white icon for primary button
  } else {
    fill = 'rgba(0, 104, 181, 1)'; // blue icon by default
  }

  $: activeFill = isDisabled ? color('gray-light') : fill;

</script>

<button class={classes} on:click disabled={isDisabled} style={borderless ? 'border:0' : ''}>
  <div class="icon">
    <Icon name={icon} fill={activeFill} />
  </div>
</button>

<style>
</style>
