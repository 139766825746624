<script>
  export let isCollapsed = true;

</script>

<td class={`collapsible-cell ${isCollapsed ? 'collapsed' : 'uncollapsed'}`}>
  <slot>
    <div />
  </slot>
</td>

<style>
  .collapsible-cell {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  .collapsed {
    height: 39px;
  }

  .uncollapsed {
    min-height: 39px;
    height: 100%;
  }

</style>
