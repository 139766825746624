<script>
  import Icon from './Icon.svelte';
  export let icon = null;

  let classes = $$props.class;
  let iconR = $$props.class && $$props.class.includes('icon-r');
  let primary = $$props.class && $$props.class.includes('primary');

  export let fill = '';

  if (!fill && primary) {
    fill = 'rgba(255, 255, 255, 1)'; // white icon for primary button
  } else {
    fill = 'rgba(0, 104, 181, 1)'; // blue icon by default
  }

</script>

<button on:click class={classes}>
  {#if icon && !iconR}
    <div class="icon">
      <Icon name={icon} {fill} />
    </div>
  {/if}
  <slot />
  {#if icon && iconR}
    <div class="icon">
      <Icon name={icon} {fill} />
    </div>
  {/if}
</button>
