<script>
  import { createEventDispatcher } from 'svelte';
  import BMAMenu from './BMAMenu.svelte';
  import Modal from '../../ui/Modal.svelte';
  import Icon from '../../ui/Icon.svelte';
  import { tips } from '../../../../stores/infotip';

  export let metric;

  let expanded = false;
  let tipOpen = false;

  const dispatch = createEventDispatcher();

  function selectOption(option) {
    dispatch('select', {
      value: option,
    });
  }

  function handleCloseModal() {
    tipOpen = false;
  }

</script>

<li on:mouseover={() => (expanded = true)}>
  {#if metric.Data}
    <div
      on:click={() => {
        if (metric.Data) selectOption(metric);
      }}
      class="container">
      <div class="label">{metric.Label}</div>
      <div class="icon" on:click|stopPropagation={() => (tipOpen = true)}>
        <Icon name="information-outlined" />
      </div>
    </div>
  {:else}
    {metric.Label}
    {#if expanded}
      <BMAMenu metrics={metric.Series} bind:expanded on:select subMenu={true} />
    {/if}
  {/if}

  {#if tipOpen}
    <Modal bind:isOpen={tipOpen} title={metric.Label} {handleCloseModal}>
      {#if metric.Label in $tips}
        <div class="tipcontent">
          <img src={$tips[metric.Label].img} alt={metric.Label} />
          <p>{$tips[metric.Label].desc}</p>
        </div>
      {:else}
        <div class="tipcontent">
          <img src={$tips['Default'].img} alt={metric.Label} />
          <p>{$tips['Default'].desc}</p>
        </div>
      {/if}
    </Modal>
  {/if}
</li>

<style>
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .label {
    max-width: 150px;
  }

  .icon {
    cursor: pointer;
    height: 16px;
    width: 16px;
  }

  .icon :global(svg) {
    height: 16px;
    width: 16px;
    fill: #808080;
  }

  .icon:hover :global(svg) {
    fill: #0068b5;
  }

  .tipcontent {
    display: flex;
    align-items: center;
    max-height: 260px;
    max-width: 500px;
    overflow: auto;
    font-size: var(--typography-size-smaller);
  }

  .tipcontent img {
    padding-right: 16px;
  }

  .tipcontent p {
    margin-top: 0px;
    /* padding-top: 0px; */
  }

</style>
