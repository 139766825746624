<!-- https://stackoverflow.com/questions/66069647/best-way-to-import-svg-icons-into-a-svelte-app -->
<script>
  import { color } from '../../../utils/styles';

  export let name;
  export let fill = color('primary-main');
  export let height = null;
  export let width = null;
  export let iconClassName = '';

  const icons = [
    {
      box: 30,
      name: 'search',
      label: 'search',
      svg: `<path d="M22.6042 20.4167H21.4521L21.0438 20.0229C22.4729 18.3604 23.3333 16.2021 23.3333 13.8542C23.3333 8.61875 19.0896 4.375 13.8542 4.375C8.61875 4.375 4.375 8.61875 4.375 13.8542C4.375 19.0896 8.61875 23.3333 13.8542 23.3333C16.2021 23.3333 18.3604 22.4729 20.0229 21.0438L20.4167 21.4521V22.6042L27.7083 29.8813L29.8813 27.7083L22.6042 20.4167ZM13.8542 20.4167C10.2229 20.4167 7.29167 17.4854 7.29167 13.8542C7.29167 10.2229 10.2229 7.29167 13.8542 7.29167C17.4854 7.29167 20.4167 10.2229 20.4167 13.8542C20.4167 17.4854 17.4854 20.4167 13.8542 20.4167Z"/>`,
    },
    {
      box: 30,
      name: 'compare',
      label: 'compare',
      svg: `<path d="M10.1363 15.75H2.25V18H10.1363V21.375L14.625 16.875L10.1363 12.375V15.75ZM16.8638 14.625V11.25H24.75V9H16.8638V5.625L12.375 10.125L16.8638 14.625Z"/>`,
    },
    {
      box: 18,
      name: 'calendar-outlined',
      label: 'calendar',
      svg: `<g>
                    <path d="M10.3257,8.6834c0,0.426,0.178,0.509,0.486,0.509h1.569v4.438c0,0.621,0.246,0.91,0.772,0.91s0.772-0.289,0.772-0.91v-6.49
                        c0-0.6-0.287-0.681-0.567-0.681c-0.409,0-0.548,0.263-0.724,0.596c-0.196,0.368-0.439,0.827-1.139,0.978l-0.423,0.093
                        C10.4647,8.2434,10.3257,8.3474,10.3257,8.6834z"/>
                    <path d="M6.7166,13.3624c-0.933,0-1.179-0.482-1.377-0.87c-0.154-0.3-0.274-0.536-0.669-0.536c-0.42,0-0.714,0.29-0.714,0.704
                        c0,0.614,0.666,1.88,2.725,1.88c1.444,0,2.987-0.677,2.987-2.577c0-0.624-0.2-1.424-1.152-1.691l-0.229-0.064l0.21-0.113
                        c0.618-0.332,0.919-0.827,0.919-1.516c0-0.497-0.188-2.1201-2.609-2.1201c-1.108,0-2.669,0.587-2.669,1.892
                        c0,0.513,0.42,0.59,0.67,0.59c0.349,0,0.484-0.222,0.656-0.5031c0.218-0.357,0.49-0.801,1.308-0.801
                        c0.752,0,1.238,0.433,1.238,1.102c0,0.592-0.349,0.952-0.958,0.987c-0.701,0.037-0.903,0.185-0.903,0.659
                        c0,0.396,0.435,0.476,0.844,0.52c0.768,0.078,1.132,0.446,1.132,1.127C8.1256,12.8405,7.5726,13.3624,6.7166,13.3624z"/>
                    <path d="M12.5,5c0.4136,0,0.75-0.3364,0.75-0.75v-2.5C13.25,1.3364,12.9136,1,12.5,1s-0.75,0.3364-0.75,0.75v2.5
                        C11.75,4.6636,12.0864,5,12.5,5z"/>
                    <path d="M16.4995,3.4805C16.4761,2.5811,15.8877,2,15,2h-0.75v1.5059L15,3.5063l-0.0063,11.9932
                        C14.9922,15.5,14.9902,15.5,14.9883,15.5L3,15.4937L3.0063,3.5L3.75,3.5004V2.0005H2.9863C2.0835,2.0244,1.5,2.6133,1.5,3.5
                        v12.0186C1.5225,16.4185,2.1113,17,3,17h12.0098l0.0098-0.0005C15.9189,16.9766,16.5,16.3877,16.5,15.5L16.4995,3.4805z"/>
                    <rect x="7.25" y="2.0002" width="3.5" height="1.5029"/>
                    <path d="M5.5,5c0.4136,0,0.75-0.3364,0.75-0.75v-2.5C6.25,1.3364,5.9136,1,5.5,1S4.75,1.3364,4.75,1.75v2.5
                        C4.75,4.6636,5.0864,5,5.5,5z"/>
                </g>`,
    },
    {
      box: 18,
      name: 'close-max',
      label: 'close',
      svg: `<path d="M10.2373,9l5.0068-5.0063c0.3408-0.3418,0.3408-0.8955,0-1.2373c-0.3418-0.3418-0.8965-0.3418-1.2383,0L9,7.7627
                    L3.9941,2.7563c-0.3418-0.3418-0.8965-0.3418-1.2383,0c-0.3408,0.3418-0.3408,0.8955,0,1.2373L7.7627,9l-5.0068,5.0063
                    c-0.3408,0.3418-0.3408,0.8955,0,1.2373c0.3418,0.3418,0.8965,0.3418,1.2383,0L9,10.2373l5.0059,5.0063
                    C14.1768,15.4145,14.4014,15.5,14.625,15.5s0.4482-0.0854,0.6191-0.2563c0.3408-0.3418,0.3408-0.8955,0-1.2373L10.2373,9z"/>`,
    },
    {
      box: 18,
      name: 'down',
      label: 'down arrow',
      svg: `<path d="M14.8228,5.7854c-0.3418-0.3418-0.8955-0.3418-1.2373,0l-4.5852,4.5848L4.4146,5.7854
                    c-0.3418-0.3418-0.8955-0.3398-1.2373,0c-0.3418,0.3418-0.3418,0.8965,0,1.2383l5.1924,5.1914
                    c0.1709,0.1709,0.3945,0.2559,0.6187,0.2559c0.0041,0,0.0079-0.0023,0.0119-0.0023c0.0041,0.0001,0.0079,0.0023,0.012,0.0023
                    c0.2241,0,0.4478-0.085,0.6187-0.2559l5.1919-5.1914C15.1646,6.6819,15.1646,6.1272,14.8228,5.7854z"/>`,
    },
    {
      box: 18,
      name: 'close-arrow',
      label: 'close arrow',
      svg: `<path d="M19.25 2.98375L14.6212 7.6125L17.5 10.5H10.5V3.5L13.3787 6.37875L18.0163 1.75L19.25 2.98375ZM2.98375 19.25L7.6125 14.6212L10.5 17.5V10.5H3.5L6.37875 13.3787L1.75 18.0163L2.98375 19.25Z"/>`,
    },
    {
      box: 18,
      name: 'expand',
      label: 'expand',
      svg: `<g>
                    <path d="M7.4694,9.4699L4.963,11.981l-1.517-1.517C3.097,10.115,2.5,10.363,2.5,10.8559v4.09
                        c0,0.306,0.248,0.554,0.554,0.554h4.09c0.493,0,0.741-0.597,0.392-0.946l-1.512-1.512l2.507-2.5116
                        c0.2925-0.2939,0.2925-0.7686-0.001-1.0615C8.2355,9.1779,7.7609,9.1769,7.4694,9.4699z"/>
                    <path d="M14.946,2.5h-4.09c-0.493,0-0.7401,0.5969-0.391,0.946l1.514,1.514L9.4699,7.4699
                        c-0.293,0.293-0.293,0.7676,0,1.0605c0.1465,0.1465,0.3384,0.2197,0.5303,0.2197s0.3838-0.0732,0.5303-0.2197l2.5091-2.5099
                        L14.554,7.535C14.903,7.8839,15.5,7.637,15.5,7.144v-4.09C15.5,2.748,15.252,2.5,14.946,2.5z"/>
                </g>`,
    },
    {
      box: 18,
      name: 'information-outlined',
      label: 'information',
      svg: `<g>
                    <path d="M9,1.2465c-4.2734,0-7.75,3.4766-7.75,7.75s3.4766,7.75,7.75,7.75s7.75-3.4766,7.75-7.75
                        S13.2734,1.2465,9,1.2465z M9,15.2465c-3.4463,0-6.25-2.8037-6.25-6.25s2.8037-6.25,6.25-6.25s6.25,2.8037,6.25,6.25
                        S12.4463,15.2465,9,15.2465z"/>
                    <circle cx="9.0001" cy="5.3796" r="1.033"/>
                    <path d="M9,7.4467c-0.4834,0-0.875,0.3916-0.875,0.875v4.4502c0,0.4834,0.3916,0.875,0.875,0.875
                        s0.875-0.3916,0.875-0.875V8.3217C9.875,7.8383,9.4834,7.4467,9,7.4467z"/>
                </g>`,
    },
    {
      box: 18,
      name: 'left-previous',
      label: 'left',
      svg: `<path d="M7.6295,9.0002l4.584-4.5845c0.3418-0.3418,0.3418-0.8965,0-1.2383s-0.8955-0.3418-1.2373,0L5.7843,8.3699
                    C5.6105,8.5437,5.5273,8.7723,5.5302,9.0002c-0.0029,0.228,0.0803,0.4565,0.2542,0.6304l5.1919,5.1924
                    c0.1709,0.1709,0.3945,0.2559,0.6187,0.2559s0.4478-0.085,0.6187-0.2559c0.3418-0.3418,0.3418-0.8965,0-1.2383L7.6295,9.0002z"/>`,
    },
    {
      box: 18,
      name: 'pause',
      label: 'pause',
      svg: `<g>
                    <path d="M7,3.2854c-0.4141,0-0.75,0.3359-0.75,0.75v9.9292c0,0.4141,0.3359,0.75,0.75,0.75s0.75-0.3359,0.75-0.75V4.0354
                        C7.75,3.6213,7.4141,3.2854,7,3.2854z"/>
                    <path d="M11,3.2854c-0.4141,0-0.75,0.3359-0.75,0.75v9.9292c0,0.4141,0.3359,0.75,0.75,0.75s0.75-0.3359,0.75-0.75V4.0354
                        C11.75,3.6213,11.4141,3.2854,11,3.2854z"/>
                </g>`,
    },
    {
      box: 63,
      name: 'play',
      label: 'play',
      svg: `<path d="M21 13.125V49.875L49.875 31.5L21 13.125Z" />`,
    },
    {
      box: 18,
      name: 'right-next',
      label: 'right',
      svg: `<path d="M12.2157,8.3699L7.0238,3.1775c-0.3418-0.3418-0.8955-0.3418-1.2373,0s-0.3418,0.8965,0,1.2383l4.584,4.5845
                    l-4.584,4.5845c-0.3418,0.3418-0.3418,0.8965,0,1.2383c0.1709,0.1709,0.3945,0.2559,0.6187,0.2559s0.4478-0.0859,0.6187-0.2559
                    l5.1919-5.1924c0.1738-0.1738,0.2571-0.4024,0.2542-0.6304C12.4727,8.7723,12.3895,8.5437,12.2157,8.3699z"/>`,
    },
    {
      box: 40,
      name: 'previous-keyframe',
      label: 'previous keyframe',
      svg: `<path d="M10 10H13.3333V30H10V10ZM15.8333 20L30 30V10L15.8333 20Z"/>`,
    },
    {
      box: 40,
      name: 'next-keyframe',
      label: 'next keyframe',
      svg: `<path d="M30 30H26.6667L26.6667 10H30L30 30ZM24.1667 20L10 10L10 30L24.1667 20Z" />`,
    },
    {
      box: 18,
      name: 'shrink',
      label: 'shrink',
      svg: `<g>
                    <path d="M7.83,3.7307L6.3106,5.2501L3.2803,2.2197c-0.293-0.293-0.7676-0.293-1.0605,0s-0.293,0.7676,0,1.0605
                        l3.0303,3.0303L3.741,7.8196c-0.349,0.349-0.102,0.946,0.392,0.946h4.089c0.306,0,0.554-0.248,0.554-0.554V4.1226
                        C8.776,3.6286,8.18,3.3817,7.83,3.7307z"/>
                    <path d="M15.7803,14.7197l-3.031-3.031l1.5097-1.51c0.349-0.3491,0.102-0.946-0.391-0.946h-4.09
                        c-0.306,0-0.5541,0.248-0.5541,0.554v4.09c0,0.494,0.597,0.741,0.946,0.392l1.5189-1.5192l3.0308,3.0308
                        C14.8662,15.9268,15.0576,16,15.25,16s0.3838-0.0732,0.5303-0.2197C16.0732,15.4873,16.0732,15.0127,15.7803,14.7197z"/>
                </g>`,
    },
    {
      box: 40,
      name: 'step-back',
      label: 'step back',
      svg: '<path d="M18.3332 30V10L4.1665 20L18.3332 30ZM19.1665 20L33.3332 30V10L19.1665 20Z" />',
    },
    {
      box: 40,
      name: 'step-forward',
      label: 'step forward',
      svg: '<path d="M21.6668 10V30L35.8335 20L21.6668 10ZM20.8335 20L6.66683 10L6.66683 30L20.8335 20Z" />',
    },
    {
      box: 18,
      name: 'up',
      label: 'up',
      svg: `<path d="M14.8225,10.9757L9.6306,5.7843C9.4566,5.6103,9.228,5.5271,9,5.5303
                    c-0.228-0.0031-0.4566,0.08-0.6306,0.254l-5.1919,5.1914c-0.3418,0.3418-0.3418,0.8965,0,1.2383
                    c0.1709,0.1709,0.3945,0.2559,0.6187,0.2559s0.4478-0.085,0.6187-0.2559L9,7.6292l4.5852,4.5848
                    c0.1709,0.1709,0.3945,0.2559,0.6187,0.2559s0.4478-0.085,0.6187-0.2559C15.1643,11.8722,15.1643,11.3175,14.8225,10.9757z"/>`,
    },
    {
      box: 18,
      name: 'user',
      label: 'user',
      svg: `<g>
                    <path d="M15.9946,12.8838l-0.0215-0.1606c-0.0532-0.2139-0.1992-0.4585-0.3779-0.6343l-2.6729-2.6733
                        C12.6523,9.1475,12.2949,9,11.915,9H6.0869C5.7061,9,5.3472,9.1479,5.0742,9.4185l-2.6646,2.6675
                        c-0.2144,0.2124-0.353,0.4834-0.4019,0.7837l-0.0107,0.0732l0.0015,0.0688C2,13.0806,2.0005,13.5493,2.001,13.9771
                        c0.0005,0.3638,0.001,0.6982,0.0034,0.7808C2.0469,15.4541,2.6304,16,3.333,16h11.3379c0.7124,0,1.2969-0.5649,1.332-1.3213
                        C16.0029,14.6284,15.999,13.0337,15.9946,12.8838z M3.502,14.5c-0.0005-0.1362-0.0005-0.3262-0.001-0.5254
                        C3.5005,13.6548,3.5,13.312,3.499,13.1177L6.0869,10.5l5.7769-0.022l2.6333,2.5864c0.0024,0.3306,0.0049,1.1128,0.0059,1.4355
                        H3.502z"/>
                    <path d="M9.002,8c1.6543,0,3-1.3457,3-3s-1.3457-3-3-3s-3,1.3457-3,3S7.3477,8,9.002,8z M9.002,3.5c0.8271,0,1.5,0.6729,1.5,1.5
                        s-0.6729,1.5-1.5,1.5s-1.5-0.6729-1.5-1.5S8.1748,3.5,9.002,3.5z"/>
                </g>`,
    },
  ];

  $: displayIcon = icons.find((e) => e.name === name);

</script>

<svg
  {fill}
  class={iconClassName}
  style={height ? `height:${height}px;` : `` + width ? ` width:${width}px;` : ``}
  viewBox="0 0 {displayIcon.box} {displayIcon.box}"
  aria-label={displayIcon.label}>{@html displayIcon.svg}
</svg>
