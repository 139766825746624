import { readable } from 'svelte/store';
export const tips = readable({
    'Left Ankle Flexion': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Ankle Flexion description text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis aliquet elit. Nam ultrices mauris laoreet nisl ultrices, sit amet condimentum est hendrerit. Morbi dictum feugiat tincidunt.`,
    },
    'Right Ankle Flexion': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Ankle Flexion description text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis aliquet elit. Nam ultrices mauris laoreet nisl ultrices, sit amet condimentum est hendrerit. Morbi dictum feugiat tincidunt.`,
    },
    'Left Knee Flexion': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Knee Flexion description text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis aliquet elit. Nam ultrices mauris laoreet nisl ultrices, sit amet condimentum est hendrerit. Morbi dictum feugiat tincidunt.`,
    },
    'Left Knee Valgus': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Ankle Flexion description text. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis aliquet elit. Nam ultrices mauris laoreet nisl ultrices, sit amet condimentum est hendrerit. Morbi dictum feugiat tincidunt.`,
    },
    'Right Knee Flexion': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Knee Flexion description text.`,
    },
    'Right Knee Valgus': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Knee Valgus description text.`,
    },
    'Left Hip Flexion': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Hip Flexion description text.`,
    },
    'Left Hip Rotation': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Hip Rotation description text.`,
    },
    'Left Hip Abduction': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Hip Abduction description text.`,
    },
    'Right Hip Flexion': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Hip Flexion description text.`,
    },
    'Right Hip Rotation': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Hip Rotation description text.`,
    },
    'Right Hip Abduction': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Hip Abduction description text.`,
    },
    'Left Shoulder Abduction': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Shoulder Abduction description text.`,
    },
    'Left Shoulder Rotation': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Shoulder Rotation description text.`,
    },
    'Left Shoulder Horizontal Abduction': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Shoulder Horizontal Abduction description text.`,
    },
    'Right Shoulder Abduction': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Shoulder Abduction description text.`,
    },
    'Right Shoulder Rotation': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Shoulder Rotation description text.`,
    },
    'Right Shoulder Horizontal Abduction': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Shoulder Horizontal Abduction description text.`,
    },
    'Left Elbow Flexion': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Elbow Flexion description text.`,
    },
    'Right Elbow Flexion': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Elbow Flexion description text.`,
    },
    'Spine Flexion': {
        img: 'assets/images/tip/test_image.png',
        desc: `Spine Flexion description text.`,
    },
    'Spine Rotation': {
        img: 'assets/images/tip/test_image.png',
        desc: `Spine Rotation description text.`,
    },
    'Spine Lateral Tilt': {
        img: 'assets/images/tip/test_image.png',
        desc: `Spine Lateral Tilt description text.`,
    },
    'Pelvis Global Flexion': {
        img: 'assets/images/tip/test_image.png',
        desc: `Pelvis Global Flexion description text.`,
    },
    'Pelvis Global Rotation': {
        img: 'assets/images/tip/test_image.png',
        desc: `Pelvis Global Rotation description text.`,
    },
    'Pelvis Global Lateral Tilt': {
        img: 'assets/images/tip/test_image.png',
        desc: `Pelvis Global Lateral Tilt description text.`,
    },
    'Trunk Global Flexion': {
        img: 'assets/images/tip/test_image.png',
        desc: `Trunk Global Flexion description text.`,
    },
    'Trunk Global Rotation': {
        img: 'assets/images/tip/test_image.png',
        desc: `Trunk Global Rotation description text.`,
    },
    'Trunk Global Lateral Tilt': {
        img: 'assets/images/tip/test_image.png',
        desc: `Trunk Global Lateral Tilt description text.`,
    },
    'Left Ankle Flexion Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Ankle Flexion Velocity description text.`,
    },
    'Right Ankle Flexion Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Ankle Flexion Velocity description text.`,
    },
    'Left Knee Flexion Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Knee Flexion Velocity description text.`,
    },
    'Right Knee Flexion Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Knee Flexion Velocity description text.`,
    },
    'Left Hip Flexion Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Hip Flexion Velocity description text.`,
    },
    'Left Hip Rotation Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Hip Rotation Velocity description text.`,
    },
    'Left Hip Abduction Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Hip Abduction Velocity description text.`,
    },
    'Right Hip Flexion Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Hip Flexion Velocity description text.`,
    },
    'Right Hip Rotation Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Hip Rotation Velocity description text.`,
    },
    'Right Hip Abduction Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Hip Abduction Velocity description text.`,
    },
    'Left Shoulder Abduction Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Shoulder Abduction Velocity description text.`,
    },
    'Left Shoulder Rotation Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Shoulder Rotation Velocity description text.`,
    },
    'Left Shoulder Horizontal Abduction Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Shoulder Horizontal Abduction Velocity description text.`,
    },
    'Right Shoulder Abduction Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Shoulder Abduction Velocity description text.`,
    },
    'Right Shoulder Rotation Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Shoulder Rotation Velocity description text.`,
    },
    'Right Shoulder Horizontal Abduction Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Shoulder Horizontal Abduction Velocity description text.`,
    },
    'Left Elbow Flexion Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Elbow Flexion Velocity description text.`,
    },
    'Right Elbow Flexion Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Elbow Flexion Velocity description text.`,
    },
    'Spine Flexion Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Spine Flexion Velocity description text.`,
    },
    'Spine Rotation Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Spine Rotation Velocity description text.`,
    },
    'Spine Lateral Tilt Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Spine Lateral Tilt Velocity description text.`,
    },
    'Pelvis Flexion Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Pelvis Flexion Velocity description text.`,
    },
    'Pelvis Rotation Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Pelvis Rotation Velocity description text.`,
    },
    'Pelvis Lateral Tilt Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Pelvis Lateral Tilt Velocity description text.`,
    },
    'Trunk Flexion Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Trunk Flexion Velocity description text.`,
    },
    'Trunk Rotation Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Trunk Rotation Velocity description text.`,
    },
    'Trunk Lateral Tilt Velocity': {
        img: 'assets/images/tip/test_image.png',
        desc: `Trunk Lateral Tilt Velocity description text.`,
    },
    'Left Foot Angular Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Foot Angular Velocity Magnitude description text.`,
    },
    'Right Foot Angular Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Foot Angular Velocity Magnitude description text.`,
    },
    'Left Shank Angular Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Shank Angular Velocity Magnitude description text.`,
    },
    'Right Shank Angular Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Shank Angular Velocity Magnitude description text.`,
    },
    'Left Thigh Angular Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Thigh Angular Velocity Magnitude description text.`,
    },
    'Right Thigh Angular Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Thigh Angular Velocity Magnitude description text.`,
    },
    'Left Bicep Angular Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Bicep Angular Velocity Magnitude description text.`,
    },
    'Right Bicep Angular Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Bicep Angular Velocity Magnitude description text.`,
    },
    'Left Forearm Angular Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Forearm Angular Velocity Magnitude description text.`,
    },
    'Right Forearm Angular Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Forearm Angular Velocity Magnitude description text.`,
    },
    'Left Toe Linear Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Toe Linear Velocity Magnitude description text.`,
    },
    'Right Toe Linear Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Toe Linear Velocity Magnitude description text.`,
    },
    'Left Heel Linear Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Heel Linear Velocity Magnitude description text.`,
    },
    'Right Heel Linear Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Heel Linear Velocity Magnitude description text.`,
    },
    'Left Ankle Linear Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Ankle Linear Velocity Magnitude description text.`,
    },
    'Right Ankle Linear Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Ankle Linear Velocity Magnitude description text.`,
    },
    'Left Knee Linear Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Knee Linear Velocity Magnitude description text.`,
    },
    'Right Knee Linear Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Knee Linear Velocity Magnitude description text.`,
    },
    'Left Hip Linear Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Hip Linear Velocity Magnitude description text.`,
    },
    'Right Hip Linear Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Hip Linear Velocity Magnitude description text.`,
    },
    'Pelvis Linear Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Pelvis Linear Velocity Magnitude description text.`,
    },
    'Mid Shoulders Linear Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Mid Shoulders Linear Velocity Magnitude description text.`,
    },
    'Left Shoulder Linear Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Shoulder Linear Velocity Magnitude description text.`,
    },
    'Right Shoulder Linear Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Shoulder Linear Velocity Magnitude description text.`,
    },
    'Left Elbow Linear Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Elbow Linear Velocity Magnitude description text.`,
    },
    'Right Elbow Linear Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Elbow Linear Velocity Magnitude description text.`,
    },
    'Left Wrist Linear Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Wrist Linear Velocity Magnitude description text.`,
    },
    'Right Wrist Linear Velocity Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Wrist Linear Velocity Magnitude description text.`,
    },
    'Left Toe Linear Acceleration Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Toe Linear Acceleration Magnitude description text.`,
    },
    'Right Toe Linear Acceleration Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Toe Linear Acceleration Magnitude description text.`,
    },
    'Left Heel Linear Acceleration Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Heel Linear Acceleration Magnitude description text.`,
    },
    'Right Heel Linear Acceleration Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Heel Linear Acceleration Magnitude description text.`,
    },
    'Left Ankle Linear Acceleration Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Ankle Linear Acceleration Magnitude description text.`,
    },
    'Right Ankle Linear Acceleration Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Ankle Linear Acceleration Magnitude description text.`,
    },
    'Left Knee Linear Acceleration Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Knee Linear Acceleration Magnitude description text.`,
    },
    'Right Knee Linear Acceleration Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Knee Linear Acceleration Magnitude description text.`,
    },
    'Left Hip Linear Acceleration Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Hip Linear Acceleration Magnitude description text.`,
    },
    'Right Hip Linear Acceleration Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Hip Linear Acceleration Magnitude description text.`,
    },
    'Pelvis Linear Acceleration Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Pelvis Linear Acceleration Magnitude description text.`,
    },
    'Mid Shoulders Linear Acceleration Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Mid Shoulders Linear Acceleration Magnitude description text.`,
    },
    'Left Shoulder Linear Acceleration Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Shoulder Linear Acceleration Magnitude description text.`,
    },
    'Right Shoulder Linear Acceleration Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Shoulder Linear Acceleration Magnitude description text.`,
    },
    'Left Elbow Linear Acceleration Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Elbow Linear Acceleration Magnitude description text.`,
    },
    'Right Elbow Linear Acceleration Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Elbow Linear Acceleration Magnitude description text.`,
    },
    'Left Wrist Linear Acceleration Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Left Wrist Linear Acceleration Magnitude description text.`,
    },
    'Right Wrist Linear Acceleration Magnitude': {
        img: 'assets/images/tip/test_image.png',
        desc: `Right Wrist Linear Acceleration Magnitude description text.`,
    },
    'Front Squat': {
        img: 'assets/images/tip/test_image.png',
        desc: `Front Squat description text.`,
    },
    'Default': {
        img: 'assets/images/tip/test_image.png',
        desc: `Default description text.`,
    },
}, () => { });
