import { find, mergeDeepLeft, pathEq } from 'ramda';
import { color } from './styles';
export function addOrReplaceAnnotation(chart, options) {
    chart.removeAnnotation(options.id);
    chart.addAnnotation(options);
}
export function addCurrentFrameAnnotation(chart, currentFrame) {
    addOrReplaceAnnotation(chart, {
        id: 'annotation-current-frame',
        draggable: 'x',
        shapes: [
            {
                stroke: color('primary-main'),
                strokeWidth: 2,
                type: 'path',
                points: [
                    {
                        x: currentFrame,
                        y: 0,
                        xAxis: 0,
                    },
                    {
                        x: currentFrame,
                        y: 1000,
                        xAxis: 0,
                    },
                ],
            },
        ],
    });
}
export function chartSeriesColors() {
    return [
        color('secondary-main'),
        color('primary-main'),
        color('highlight-main'),
        color('accent-main'),
    ];
}
export var Kind;
(function (Kind) {
    Kind["Main"] = "main";
    Kind["Comparison"] = "comparison";
})(Kind || (Kind = {}));
export function getSerieByIndex(chart, kind, index) {
    return chart.get(seriesId(kind, index));
}
function seriesId(kind, index) {
    return `${kind}-series-${index}`;
}
export function addSerie({ chart, name = '', kind, trial, data, index, setYAxis = true, color = null, dashStyle = null, showInLegend = true, }) {
    // Highcharts keeps an internal counter and increments it when a serie is removed.
    // Here we force the index to match with the serie, so the same color is always used for it.
    const colorIndex = index;
    // @ts-expect-error Type attribute is not mandatory, but typing requires it.
    addOrReplaceSerie(chart, {
        id: seriesId(kind, index),
        name: name ? name : data.Label,
        yAxis: setYAxis ? index : undefined,
        colorIndex,
        data: trial.trimFramesData(data.Data),
        color,
        dashStyle,
        showInLegend,
    });
}
export function addOrReplaceSerie(chart, options) {
    const previousSerie = chart.get(options.id);
    if (previousSerie) {
        previousSerie.remove();
    }
    chart.addSeries(options);
}
export function maxPointAnnotationId(kind, index) {
    return `annotation-max-point-${kind}-${index}`;
}
export function maxPointAnnotation({ index = 0, kind, currentTime, trial, data, color, matchYAxisWithIndex = false, }) {
    return {
        id: maxPointAnnotationId(kind, index),
        draggable: '',
        events: {
            // @ts-expect-error Click event not present on type, but it works.
            click: function () {
                currentTime.frame(trial.adjustFrameNumber(data.MaxF));
            },
        },
        shapes: [
            {
                fill: kind === 'main' ? color : 'white',
                strokeWidth: 3,
                stroke: color,
                point: {
                    x: trial.adjustFrameNumber(data.MaxF),
                    xAxis: 0,
                    y: data.Max,
                    yAxis: matchYAxisWithIndex ? index : 0,
                },
                r: 5,
                type: 'circle',
            },
        ],
    };
}
export function getAnnotationById(chart, id) {
    // @ts-expect-error annotations attribute not recognized, but present.
    return find(pathEq(['options', 'id'], id), chart.annotations);
}
function generateKeyframesLines(keyFramesArray, { showLabel }) {
    return keyFramesArray.map(({ name, value }) => ({
        value,
        color: color('gray-light'),
        width: 2,
        xAxis: 0,
        label: showLabel ? { text: name } : undefined,
        dashStyle: 'dot',
        id: name,
    }));
}
function replaceKeyframesLines(chart, keyFramesArray, plotLinesOptions) {
    keyFramesArray.forEach((it) => chart.xAxis[0].removePlotLine(it.name));
    generateKeyframesLines(keyFramesArray, plotLinesOptions).forEach((it) => chart.xAxis[0].addPlotLine(it));
}
export function addKeyFrameAnnotations(config, keyFramesArray) {
    config.xAxis.plotLines = generateKeyframesLines(keyFramesArray, {
        showLabel: false,
    });
    config.plotOptions.series.events = mergeDeepLeft(config.plotOptions.series.events || {}, {
        mouseOver: function (e) {
            replaceKeyframesLines(e.target.chart, keyFramesArray, {
                showLabel: true,
            });
        },
        mouseOut: function (e) {
            replaceKeyframesLines(e.target.chart, keyFramesArray, {
                showLabel: false,
            });
        },
    });
}
