<script>
  export let id;

</script>

<input
  on:click
  type="checkbox"
  name="checkbox"
  class="cm-toggle"
  checked
  {id} />

<style>
  /* Toggle Button */

  .cm-toggle {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    border: 0;
    outline: 0;
    cursor: pointer;
    top: 5px;
  }

  /* To create surface of toggle button */
  .cm-toggle:after {
    content: '';
    width: 30px;
    height: 14px;
    display: inline-block;
    background: rgba(196, 195, 195, 0.55);
    border-radius: 9px;
    clear: both;
  }

  /* Contents before checkbox to create toggle handle */
  .cm-toggle:before {
    content: '';
    width: 16px;
    height: 16px;
    display: block;
    position: absolute;
    left: 0;
    top: -2px;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
  }

  /* Shift the handle to left on check event */
  .cm-toggle:checked:before {
    left: 15px;
    /*box-shadow: -1px 1px 3px rgba(0, 0, 0, 0.6);*/
  }
  /* Background color when toggle button will be active */
  .cm-toggle:checked:after {
    background: var(--color-primary-main);
  }

</style>
