<script lang="ts">
  import { onMount } from 'svelte';
  import { fade } from 'svelte/transition';
  import { World } from '../../../world/world';
  import { JOINTS } from '../../../utils/config';
  import type { Plot, PlotPayload } from '../../../utils/types';
  import WorldViewSettings from './WorldViewSettings.svelte';
  import { worldSettingsVisible } from '../../../stores/stores';
  import { comparisonTrial, trial } from '../../../stores/trial';

  export let data: any;

  let wrapper: HTMLElement;
  let world: World;
  let cameraMode = 0;
  let icon = ``;

  let plots: Plot[] = [];
  let meshes = true;
  let opaque = true;

  function plot(event: CustomEvent) {
    const { scale, index, color } = event.detail as PlotPayload;
    const remove = world.characters[0].plotTrail(
      index,
      color,
      map(scale, -100, 100, 0.3, 2)
    );
    const label = JOINTS.find((joint) => joint.index === index).name;
    plots = [...plots, { remove, label, color }];
  }

  // --- Events

  function remove(event: CustomEvent) {
    const index = event.detail as number;
    plots[index].remove();
    plots = plots.filter((_, i) => i !== index);
  }

  function close() {
    worldSettingsVisible.set(false);
  }

  function showMesh(event: CustomEvent) {
    meshes = event.detail as boolean;

    world.characters.forEach((character) => {
      meshes ? character.showMesh() : character.hideMesh();
    });
  }

  function opaqueMesh(event: CustomEvent) {
    opaque = event.detail as boolean;

    world.characters.forEach((character) => {
      character.opacity(opaque ? 1.0 : 0.5);
    });
  }

  function camera() {
    toggleCameraMode();
    updateCameraMode();
  }

  function toggleCameraMode() {
    cameraMode += 1;
    if (cameraMode > 2) cameraMode = 0;
    updateCameraMode();
  }

  function updateCameraMode() {
    switch (cameraMode) {
      case 0:
        icon = `assets/icons/lookat-camera.svg`;
        world.lookAtCamera();
        break;
      case 1:
        icon = `assets/icons/follow-camera.svg`;
        world.followCamera();
        break;
      case 2:
        icon = `assets/icons/default-camera.svg`;
        world.defaultCamera();
        break;
    }
  }

  function resize(_: Event) {
    world.resize();
  }

  function map(
    value: number,
    start1: number,
    stop1: number,
    start2: number,
    stop2: number
  ) {
    return ((value - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
  }

  onMount(() => {
    world = new World(wrapper, data, $trial, $comparisonTrial);
    updateCameraMode();
  });

  $: if (wrapper && data) {
    world.destroy();
    world = new World(wrapper, data, $trial, $comparisonTrial);
    updateCameraMode();
  }

</script>

<svelte:window on:resize={resize} />

<div class="wrapper" bind:this={wrapper}>
  {#if $worldSettingsVisible}
    <div transition:fade={{ duration: 300 }} class="overlay">
      <WorldViewSettings
        {plots}
        {meshes}
        {opaque}
        on:close={close}
        on:plot={plot}
        on:remove={remove}
        on:meshes={showMesh}
        on:opaque={opaqueMesh} />
    </div>
  {/if}
  <img
    class="btn btn--camera"
    src={icon}
    alt="look at character"
    on:click={camera} />
  <img
    class="btn btn--settings"
    src="assets/icons/settings.svg"
    alt="change perspective"
    on:click={() => worldSettingsVisible.set(true)} />
</div>

<style>
  .wrapper {
    height: 100%;
  }

  .btn {
    position: absolute;
    display: block;
    width: 44px;
    height: 44px;
    bottom: 30px;
    user-select: none;
  }

  .btn--settings {
    left: 90px;
  }

  .btn--camera {
    left: 30px;
  }

  :global(canvas) {
    width: 100%;
    height: 100%;
  }

  .overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: auto;
  }

</style>
