import { writable } from 'svelte/store';
// Open and close the secondary menu
export const secondaryOpen = writable(true);
// Are the settings view open?
export const worldSettingsVisible = writable(false);
//Biomechanics size
export const biomechanicsIsExpanded = writable(false);
// Control loader overlay
export const loading = writable(false);
// Contain AWS auth credentials, otherwise it's null.
export const credentials = writable(null);
// List of trials (json files)
export const trials = writable(null);
// List of asenseiFiles (json files)
export const asenseiFiles = writable(new Map());
// Map of dates and associated trials
// Using this in lieu of a database
// key = datestring
// value = list of RecordingObjects (see list.ts)
// example format:
// {'09-01-2021', [{
//     id: number;
//     originalKey: string;
//     key: string;
//     displayName: string;
//     url: string;
//     dateString: string;
// }]}
export const dateMap = writable(new Map());
// Use to get trial by id
// key = id
// value = RecordingObject (see list.ts)
export const trialMap = writable(new Map());
// Discrete metrics (min/max values) for Biomechanics Panel - Metrics tab
// Initialized in fetch.ts - generated in metrics.ts
export const discreteMetrics = writable(null);
// Map of BMA Metric Names (string) to their indices in data.bma_metrics (i, j, k)
// Example:
// {
//     "Left Ankle Flexion": {i: 0, j: 0, k: 0},
//     "Right Ankle Flexion": {i: 0, j: 0, k: 1}, etc. etc.
// }
// Used to store which metrics the user is currently using in Time Series, Sequence, and Live Metrics panels
// Initialized in fetch.ts
export const metricIndices = writable(null);
// List of metrics selected for the Live Metrics panel
// Example: ["Left Ankle Flexion", "Right Ankle Flexion"]
// pulls from localStorage if available, so user selections will persist across sessions
export const selectedLiveMetrics = memoizable('selectedLiveMetrics', [
    'Left Ankle Flexion',
    'Right Ankle Flexion',
    'Left Knee Flexion',
]);
function memoizable(storageKey, defaultValue = undefined) {
    const store = writable(JSON.parse(localStorage.getItem(storageKey)) || defaultValue);
    store.subscribe((value) => localStorage.setItem(storageKey, JSON.stringify(value)));
    return store;
}
// Stores the two metrics currently selected on the Time Series chart
export const timeSeriesMetrics = writable(localStorage.getItem('timeSeriesMetrics')
    ? JSON.parse(localStorage.getItem('timeSeriesMetrics'))
    : ['Left Ankle Flexion', 'Right Ankle Flexion']);
// Stores the four metrics currently selected on the Sequence chart
export const sequenceMetrics = writable(localStorage.getItem('sequenceMetrics')
    ? JSON.parse(localStorage.getItem('sequenceMetrics'))
    : [
        'Pelvis Rotation Velocity',
        'Trunk Rotation Velocity',
        'Left Bicep Angular Velocity Magnitude',
        'Left Forearm Angular Velocity Magnitude',
    ]);
// gets LiveMetric panel width from previous session
export const liveMetricPanelWidth = writable(localStorage.getItem('liveMetricPanelWidth')
    ? JSON.parse(localStorage.getItem('liveMetricPanelWidth'))
    : 360);
// gets LiveMetric panel width class from previous session
export const liveMetricWidthClass = writable(localStorage.getItem('liveMetricWidthClass')
    ? localStorage.getItem('liveMetricWidthClass')
    : 'c3');
const defaultMetrics = [
    'Pelvis Global Rotation',
    'Trunk Global Rotation',
    'Spine Rotation',
    'Trunk Global Flexion',
    'Right Knee Flexion',
    'Left Knee Flexion',
    'Left Shoulder Abduction',
    'Left Elbow Flexion',
    'Right Elbow Flexion',
];
export const defaultSnapshotMetrics = [
    {
        tabName: 'Address',
        metrics: [
            'Right Foot Angle',
            'Left Foot Angle',
            'Stride Length',
            'Stride Width',
            'Right Knee Flexion',
            'Left Knee Flexion',
            'Pelvis Global Rotation',
            'Pelvis Global Flexion',
            'Trunk Global Flexion',
        ],
    },
    { tabName: 'Mid-Backswing', metrics: defaultMetrics },
    { tabName: 'Peak Backswing', metrics: defaultMetrics },
    { tabName: 'Mid-Downswing', metrics: defaultMetrics },
    { tabName: 'Impact', metrics: defaultMetrics },
];
export const selectedSnapshotsMetrics = memoizable('selectedSnapshotsMetrics', defaultSnapshotMetrics);
