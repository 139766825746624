<script type="ts">
  export let hasComparisonValue: boolean;

</script>

{#if hasComparisonValue}
  <div class="container">
    <div class="circle primary-main" />
    <slot name="main-value" />
  </div>
  <div class="container">
    <div class="circle secondary-main" />
    <slot name="comparison-value" />
  </div>
{:else}
  <slot name="main-value" />
{/if}

<style>
  .container {
    display: flex;
    padding: 2px;
    align-items: center;
  }
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 7px;
  }
  .primary-main {
    background: var(--color-primary-main);
  }
  .secondary-main {
    background: var(--color-secondary-main);
  }

</style>
