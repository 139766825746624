<script>
  import PanelTabs from '../../../ui/PanelTabs.svelte';
  import SequenceChart from '../SequenceChart.svelte';
  import KineticDashboard from './KineticDashboard.svelte';

  export let data;
  let chartComponent;

  const tabs = ['Dashboard', 'Graph'];
  let active = tabs[0];

  const handleChange = (category) => {
    active = category;
  };

  export function handleResize() {
    chartComponent.handleResize();
  }

</script>

<div class="container">
  <PanelTabs {handleChange} {active} tabsNames={tabs} />
  {#if active == 'Dashboard'}
    <KineticDashboard {data} />
  {:else if active == 'Graph'}
    <SequenceChart bind:this={chartComponent} {data} />
  {/if}
</div>

<style>
  .container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

</style>
