<script>
  import { createEventDispatcher } from 'svelte';

  export let selected = '';
  export let tabs;

  $: {
    if (tabs[0].value == undefined) {
      tabs = tabs.map((tab) => {
        return { text: tab, value: tab };
      });
    }
  }

  const dispatch = createEventDispatcher();

</script>

<div class="tabBar">
  {#each tabs as tab}
    <span
      class="tab {selected == tab.value ? 'selected' : ''}"
      tabindex={selected == tab.value ? '0' : '-1'}
      on:click={() => {
        selected = tab.value;
        dispatch('select', tab.value);
      }}>
      {tab.text}
    </span>
  {/each}
</div>

<style>
  .tabBar {
    width: 100%;
    display: flex;
    justify-content: space-around;
  }

  .tab {
    width: 100%;
    padding: 4px;
    text-align: center;
    font-size: var(--typography-size-smaller);
    color: var(--color-primary-main);
    cursor: pointer;
    border: 1px solid var(--color-gray-lighter);
    border-left: none;
  }

  .tab:first-child {
    border-left: none;
  }

  .tab:last-child {
    border-right: none;
  }

  .tab:hover {
    background: var(--color-gray-extralight);
  }

  .tab.selected {
    background: var(--color-primary-main);
    color: var(--color-white);
  }

</style>
