<script type="ts">
  import { golf } from '../../../models/selectors';
  import LineChart from './LineChart.svelte';
  import type Highcharts from 'highcharts';
  import { color } from '../../../utils/styles';

  export let data;
  export let comparisonData;

  let chartComponent: LineChart;

  const config: Highcharts.Options = {
    xAxis: {
      labels: {},
    },

    yAxis: [
      {
        title: {
          style: {
            fontWeight: 'bold',
          },
          text: 'Spine Rotation',
          // @ts-expect-error labels attribute not recognized, but present.
          labels: {
            style: {
              backgroundColor: color('error-main'),
            },
          },
        },
      },
    ],
  };

  $: mainChartData = golf.spineRotation(data);
  $: comparisonChartData = comparisonData
    ? golf.spineRotation(comparisonData)
    : null;

  $: mainSerie = {
    data: mainChartData,
  };

  $: series = comparisonChartData
    ? [
        mainSerie,
        {
          data: comparisonChartData,
        },
      ]
    : [mainSerie];

  export function handleResize() {
    chartComponent.handleResize();
  }

</script>

<div class="chart">
  <LineChart
    bind:this={chartComponent}
    highchartsConfigOverride={config}
    unit="ms"
    {series} />
</div>

<style>
  .chart {
    height: 75%;
  }

</style>
