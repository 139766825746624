import { initializeTrialPlayback } from '../stores/playerStore';
import { getDiscreteMetrics, getMetricIndices } from '../utils/metrics';
import { discreteMetrics, metricIndices } from '../stores/stores';
import { OUTPUT_FILE_BUCKET_NAME } from '../utils/config';
import { mergeDeepLeft } from 'ramda';
import { get } from 'svelte/store';
import { isDebugMode } from '../stores/debug';
function localVideoUrl(trialIndex, videoIndex) {
    return `/assets/data/${trialIndex}/cam${videoIndex + 1}.mp4`;
}
export async function fetchAsenseiFileIfPresent(trial) {
    const s3 = new AWS.S3({ signatureVersion: 'v4' });
    const [user, folder] = trial.originalKey.split('/');
    return fetch(s3.getSignedUrl('getObject', {
        Bucket: OUTPUT_FILE_BUCKET_NAME,
        Key: [user, folder, 'asensei.json'].join('/'),
        Expires: 3600 * 24 * 7,
    }))
        .then((response) => {
        if (response.status >= 400) {
            throw new Error('could not download asensei.json file');
        }
        return response.json();
    })
        .catch((e) => {
        return null;
    });
}
async function fetchVideos(jsonVideos, options) {
    const s3 = new AWS.S3({ signatureVersion: 'v4' });
    const videos = jsonVideos.map((video, i) => {
        video.url = get(isDebugMode)
            ? localVideoUrl(options.debugDataIndex, i)
            : s3.getSignedUrl('getObject', {
                Bucket: OUTPUT_FILE_BUCKET_NAME,
                Key: video.videos_src,
                Expires: 3600 * 24 * 7,
            });
        video.element = document.createElement(`video`);
        video.element.loop = true;
        video.element.muted = true;
        video.element.setAttribute('playsinline', true);
        return video;
    });
    try {
        return Promise.all(videos.map((video) => new Promise((resolve, reject) => {
            video.element.addEventListener(`canplay`, () => resolve(video));
            video.element.addEventListener(`error`, (e) => reject(e));
            setTimeout(() => reject(new Error(`load timeout`)), 5 * 60000); // 5 minutes
            video.element.src = video.url;
            video.element.load();
        })));
    }
    catch (e) {
        console.error(e);
    }
}
function setMetricsCache(data) {
    // Parse data for the Biomechanics Data / Metrics tab
    discreteMetrics.set(getDiscreteMetrics(data));
    // Add metric indices to a map so we can retrieve metrics by name later
    metricIndices.set(getMetricIndices(data.bma_metrics));
}
function sanitizeData(data) {
    const defaultOptions = { metadata: { fps: 60 } };
    return Object.assign(Object.assign({}, mergeDeepLeft(data[0], defaultOptions)), { bma_metrics: data[1].bma_metrics });
}
// Generate signed URLs for videos to prevent bucket 403.
// They expire after 7 days. We have no error handling for this.
export async function fetchTrialData(recording, options = {}) {
    const finalOptions = mergeDeepLeft(options, {
        debugDataIndex: recording.id,
        isMainTrial: true,
    });
    const json = await fetch(recording.url).then((response) => response.json());
    const data = sanitizeData(json);
    data.videos = await fetchVideos(data.videos, finalOptions);
    if (finalOptions.isMainTrial) {
        setMetricsCache(data);
        initializeTrialPlayback(data, recording);
    }
    return data;
}
