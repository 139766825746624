<script>
  let params = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop) => searchParams.get(prop),
  });
  let idle = 0;
  let timeout = params.timeout || 10000;
  let tick = 1000;

  setInterval(() => {
    idle += tick;
    bubbleIdle(idle > timeout);
  }, tick);

  function handleReset(event) {
    idle = 0;
    bubbleIdle(false);
  }

  function bubbleIdle(idle) {
    window.top.postMessage({ idle }, '*');
  }

</script>

<svelte:window
  on:mousedown={handleReset}
  on:mouseenter={handleReset}
  on:mousemove={handleReset}
  on:keypress={handleReset}
  on:drag={handleReset} />
