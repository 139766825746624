<script>
  import {
    worldSettingsVisible,
    metricIndices,
    selectedLiveMetrics,
    liveMetricPanelWidth,
    liveMetricWidthClass,
  } from '../../../../stores/stores';
  import { trial } from '../../../../stores/trial';
  import { frame, frames } from '../../../../stores/playerStore';
  import { resize } from '../../../../utils/resize';
  import { hoist } from '../../../../utils/hoist';
  import IconButton from '../../ui/IconButton.svelte';
  import Button from '../../ui/Button.svelte';
  import { tick, onMount, onDestroy } from 'svelte';
  import { fly } from 'svelte/transition';
  import ModalMetrics from '../modalmetrics/ModalMetrics.svelte';
  import { updateSelectedMetrics } from '../../../../utils/metrics';
  import { generic } from '../../../../models/selectors';
  import { isNil, prop, range, reverse, uniqBy } from 'ramda';
  import {
    fixedMetricName,
    formatTimeStamp,
    getMetricKey,
    isIncludeInMovements,
  } from '../../../../utils/asensei';

  export let data;
  export let metricOffsetY;

  let liveMetricsPanel;
  let liveMetricsButton;
  let content;

  let panelWidth = $liveMetricPanelWidth;
  let widthClass = $liveMetricWidthClass;

  let liveMetricsOpen = false;
  let modalOpen = false;
  let editMode = false; // switches the modal between add/edit mode

  let modalContainer;

  let selectedIndex; // index of the metric that is currently being altered
  let tempData = {}; // store selection values temporarily while the modal is open until user confirms selection

  let currentMetric = '';
  let metrics = [];

  export let asenseiFile = null;

  // reactive declaration updates metrics array whenever data changes (i.e., when new trial is selected)
  $: {
    metrics = updateSelectedMetrics({
      selectedLiveMetrics: $selectedLiveMetrics,
      metricIndices: $metricIndices,
      trial: $trial,
      data,
    });

    const movemententsToConcat = $selectedLiveMetrics
      .filter((selectedMetric) => isIncludeInMovements(selectedMetric))
      .map((selectedMetric) => {
        const fixedMovementName = selectedMetric.Label
          ? selectedMetric.Label
          : selectedMetric;

        return createMovementData(
          selectedMetric,
          getMetricKey(fixedMovementName)
        );
      });

    if (movemententsToConcat && movemententsToConcat?.length > 0) {
      metrics = metrics.concat(movemententsToConcat);
    }

    const haveAsenseiInMetrics = data.bma_metrics.find(
      ({ Label }) => Label === 'Asensei'
    );

    if (asenseiFile && !haveAsenseiInMetrics) {
      const series = asenseiFile.events.map((event) => ({
        Label: fixedMetricName(event.identifier),
        Data: [],
      }));

      const filteredSeries = uniqBy(prop('Label'), series);
      const mappedSeries = filteredSeries.map((serie) =>
        createMovementData(serie.Label, getMetricKey(serie.Label))
      );

      data = {
        ...data,
        bma_metrics: [
          ...data.bma_metrics,
          {
            Label: 'Asensei',
            Series: mappedSeries,
          },
        ],
      };
    }
  }

  $: {
    if (liveMetricsButton) {
      liveMetricsButton.style.top = `${metricOffsetY}px`;
    } else if (liveMetricsPanel) {
      liveMetricsPanel.style.top = `${metricOffsetY}px`;
    }
  }

  // save values in local storage so the Live Metrics will default to previously-selected values
  $: localStorage.setItem('liveMetricPanelWidth', JSON.stringify(panelWidth));
  $: localStorage.setItem('liveMetricWidthClass', widthClass);

  function getMovementData(eventData = [], movement) {
    return range(0, $frames + 1).map((frameNumber) => {
      const countEvent = reverse(eventData).find((event) => {
        const timeStamp = formatTimeStamp(event, data);

        return (
          timeStamp <= frameNumber &&
          event.count &&
          event.identifier.includes(movement)
        );
      });

      return countEvent ? countEvent.count : 0;
    });
  }

  function createMovementData(label, movement) {
    return {
      Data: getMovementData(asenseiFile?.events, movement),
      Label: label,
      Max: 10,
      MaxF: 10,
      Min: 1,
      MinF: 1,
      Units: 'reps',
    };
  }
  // opens/closes the Live Metrics panel
  async function toggleVisibility() {
    if (liveMetricsOpen) {
      liveMetricsOpen = false;
    } else {
      liveMetricsOpen = true;
      await tick;
      liveMetricsPanel.style.width = `${panelWidth}px`;
    }
  }

  function handleMetricClick(index) {
    selectedIndex = index;
    tempData = metrics[index];
    editMode = true;
    modalOpen = true;
  }

  function handleAdd() {
    editMode = false;
    tempData = JSON.parse(
      JSON.stringify({ ...data.bma_metrics[0].Series[0].Series[0] })
    );
    modalOpen = true;
  }

  function handleSelect(e) {
    tempData = JSON.parse(JSON.stringify(e.detail.value));
  }

  function addMetric() {
    metrics = [...metrics, tempData];
    $selectedLiveMetrics = [...$selectedLiveMetrics, tempData.Label];

    if (metrics.length == 7) {
      liveMetricsPanel.style.width = '360px';
      widthClass = 'c3';
    } else if (metrics.length == 6) {
      liveMetricsPanel.style.width = '720px';
      widthClass = 'c6';
    } else if (metrics.length == 5) {
      liveMetricsPanel.style.width = '600px';
      widthClass = 'c5';
    } else if (metrics.length == 4) {
      liveMetricsPanel.style.width = '480px';
      widthClass = 'c4';
    } else if (metrics.length == 3) {
      liveMetricsPanel.style.width = '360px';
      widthClass = 'c3';
    } else if (metrics.length == 2) {
      liveMetricsPanel.style.width = '240px';
      widthClass = 'c2';
    }

    modalOpen = false;
  }

  function saveMetric() {
    $selectedLiveMetrics[selectedIndex] = tempData.Label;
    modalOpen = false;
  }

  function removeMetric() {
    metrics = metrics.filter((metric) => metric.Label !== currentMetric);
    $selectedLiveMetrics = $selectedLiveMetrics.filter(
      (metric) => metric !== currentMetric
    );

    modalOpen = false;
  }

  // adds/removes classes to container in order to handle responsive resizing of metric boxes
  function handleResize(e) {
    if (
      e.detail.x >= window.innerWidth - 720 &&
      e.detail.x <= window.innerWidth - 240
    ) {
      if (metrics.length >= 2 && e.detail.x >= window.innerWidth - 300) {
        widthClass = 'c2';
      } else if (metrics.length >= 3 && e.detail.x >= window.innerWidth - 420) {
        widthClass = 'c3';
      } else if (metrics.length >= 4 && e.detail.x >= window.innerWidth - 540) {
        widthClass = 'c4';
      } else if (metrics.length >= 5 && e.detail.x >= window.innerWidth - 660) {
        widthClass = 'c5';
      } else if (metrics.length >= 6) {
        widthClass = 'c6';
      }
      panelWidth = window.innerWidth - e.detail.x;
      liveMetricsPanel.style.width = `${panelWidth}px`;
    }
  }
  function handleCloseModal() {
    modalOpen = false;
  }

  onMount(() => {
    // Hoist modal up to top level - I want to do this in Modal.svelte by default, but
    // that causes an issue with the BMA Info Tip modal - need to debug that first.
    hoist(modalContainer, document.body);
  });

  onDestroy(() => {
    modalContainer.parentNode.removeChild(modalContainer);
  });

</script>

{#if liveMetricsOpen}
  <div
    bind:this={liveMetricsPanel}
    transition:fly={{ x: 500, duration: 200 }}
    class="liveMetrics panel shadow"
    class:fade={$worldSettingsVisible}>
    <div class="wrapper">
      <div use:resize on:resize={handleResize} class="dragHandle" />
      <div class="nav">
        <div>
          <h4 class="typography-h4">Live Metrics</h4>
          <Button class="sm" on:click={handleAdd}>Add Metric</Button>
        </div>
        <IconButton on:click={toggleVisibility} icon="close-max" />
      </div>
      <div bind:this={content} class="content {widthClass}">
        {#each metrics as metric, index}
          <div
            class="metric"
            on:click={() => {
              currentMetric = metric.Label;
              handleMetricClick(index);
            }}>
            <div class="metricHeader">{metric.Label}</div>
            <div class="metricBody">
              <div class="metricValue">
                {metric.Data && !isNil(metric.Data[$frame]) ? metric.Data[$frame].toFixed(2) : 'N/A'}
              </div>
              <div class="metricUnits">{generic.abbreviatedUnit(metric)}</div>
            </div>
          </div>
        {/each}
      </div>
    </div>
  </div>
{:else}
  <div
    bind:this={liveMetricsButton}
    class="liveMetricsButton shadow"
    class:fade={$worldSettingsVisible}>
    <button on:click={toggleVisibility}>Live Metrics</button>
  </div>
{/if}

<div bind:this={modalContainer}>
  <ModalMetrics
    {modalOpen}
    {editMode}
    {handleSelect}
    {tempData}
    {data}
    {selectedIndex}
    {saveMetric}
    {addMetric}
    {metrics}
    {removeMetric}
    {handleCloseModal} />
</div>

<style>
  .liveMetrics {
    position: absolute;
    top: 186px;
    right: 0px;
    height: auto;
    width: auto;
    box-sizing: border-box;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid var(--color-gray-lighter);
    border-left: none;
    border-right: none;
  }

  .liveMetricsButton {
    position: absolute;
    top: 186px;
    right: -125px;
    height: auto;
    width: auto;
    transform: rotate(90deg);
    transform-origin: top left;
    background: none;
    border-radius: 0px 0px 8px 8px;
  }

  .liveMetricsButton button {
    height: 44px;
    margin: 0;
    padding: 0px 24px;
    border-radius: 0px 0px 8px 8px;
  }

  .dragHandle {
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    cursor: ew-resize;
  }

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: var(--color-white);
    border-radius: 0px 8px 0px 8px;
    box-sizing: border-box;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    border-bottom: 1px solid var(--color-gray-lighter);
  }

  :global(.content.c2 .metric) {
    width: 50%;
  }
  :global(.content.c3 .metric) {
    width: 33.333%;
  }
  :global(.content.c4 .metric) {
    width: 25%;
  }
  :global(.content.c5 .metric) {
    width: 20%;
  }
  :global(.content.c6 .metric) {
    width: 16.666%;
  }

  .metric {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100px;
    padding: 6px 12px;
    box-sizing: border-box;
    border-top: 1px solid var(--color-gray-lighter);
    border-right: 1px solid var(--color-gray-lighter);
    border-bottom: 1px solid var(--color-gray-lighter);
    margin-top: -1px;
    cursor: pointer;
  }

  .metric:hover {
    background: var(--color-gray-extralight);
  }

  .metricHeader {
    font-size: var(--typography-size-xsmall);
    color: var(--color-primary-main);
  }

  .metricBody {
    display: flex;
    flex-direction: column;
  }

  .metricValue {
    font-size: var(--typography-size-small);
    color: var(--color-gray-dark);
  }

  .metricUnits {
    font-size: var(--typography-size-xsmall);
    color: var(--color-gray-light);
  }

  .nav {
    display: flex;
    padding: 0px 12px 0px 16px;
    border-radius: 8px 0px 0px 0px;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background-color: var(--color-gray-extralight);
  }

  .nav div {
    display: flex;
    align-items: center;
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 220px;
    min-width: 300px;
  }

  .actionBar {
    display: flex;
    justify-content: space-between;
  }

  .selectionMetric {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 110px;
    width: 176px;
    padding: 12px;
    box-sizing: border-box;
    border: 1px solid var(--color-gray-lighter);
    border-radius: 8px;
  }

  .fade {
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease-in;
  }

</style>
