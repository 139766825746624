const COLOR_VARIABLE_PREFIX = '--color-';
const TYPOGRAPHY_VARIABLE_PREFIX = '--typography-';
export const color = (varName) => computedStyle(varName, COLOR_VARIABLE_PREFIX);
const typographyStyle = (property) => (name) => computedStyle(`${property}-${name}`, TYPOGRAPHY_VARIABLE_PREFIX);
export const typography = {
    size: typographyStyle('size'),
    weight: typographyStyle('weight'),
    family: () => computedStyle(`family`, TYPOGRAPHY_VARIABLE_PREFIX),
};
function computedStyle(style, prefix) {
    const propertyName = `${prefix}${style}`;
    return getComputedStyle(document.body).getPropertyValue(propertyName);
}
