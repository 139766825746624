import { compose, curry, find, omit, propEq } from 'ramda';
import { renameKeys, renameKeysWith } from 'ramda-adjunct';
import { toSentenceCase } from '../utils/strings';
function getByLabel(label, data) {
    return find(propEq('Label', label), data);
}
export function getMetricByLabel(label, data) {
    return getByLabel(label, data);
}
export const getSeriesByLabel = curry((label, data) => getByLabel(label, data).Series);
const sanitizeName = (name) => name.replace(/[_ -]/g, '');
const specificSeries = (data) => getSeriesByLabel('Golf Specific', data.bma_metrics);
const discreteMetrics = (data) => getSeriesByLabel('Discrete Metrics', specificSeries(data));
const handedness = (data) => {
    const metric = getMetricByLabel('Handedness', discreteMetrics(data));
    return toSentenceCase((metric === null || metric === void 0 ? void 0 : metric.Value) || 'Right');
};
const getMetricByBodySectionAndLabel = (metric, bodySection, data) => {
    return getMetricByLabel(metric, getSeriesByLabel(bodySection, data));
};
const kineticMetrics = (data) => {
    const angularVelocities = getSeriesByLabel('Angular Velocities', data.bma_metrics);
    const pelvisRotationVelocity = getMetricByBodySectionAndLabel('Pelvis Rotation Velocity', 'Pelvis', angularVelocities);
    const trunkRotationVelocity = getMetricByBodySectionAndLabel('Trunk Rotation Velocity', 'Trunk', angularVelocities);
    const armRotationVelocity = getMetricByBodySectionAndLabel(`${handedness(data)} Forearm Angular Velocity Magnitude`, 'Forearms', angularVelocities);
    console.log({
        angularVelocities: {
            pelvisRotationVelocity,
            trunkRotationVelocity,
            armRotationVelocity,
        },
    });
    return {
        angularVelocities: {
            pelvisRotationVelocity,
            trunkRotationVelocity,
            armRotationVelocity,
        },
        peakTimings: {
            pelvisPeakTiming: peakTimingFrame(data, pelvisRotationVelocity),
            trunkPeakTiming: peakTimingFrame(data, trunkRotationVelocity),
            armPeakTiming: peakTimingFrame(data, armRotationVelocity),
        },
    };
};
const sway = (data) => getSeriesByLabel('Sway', specificSeries(data));
const keyFrames = (data) => compose(renameKeysWith(sanitizeName), renameKeys({ Stance: 'Address' }), omit(['Label', 'Units']))(getMetricByLabel('Key Frames', discreteMetrics(data)));
const peakTimingFrame = (data, metric) => keyFrames(data).Impact - metric.MaxF;
export const golf = {
    handedness,
    discreteMetrics,
    sway,
    kineticMetrics,
    spineRotation: (data) => compose((data) => getMetricByLabel('Spine Rotation', data), getSeriesByLabel('Spine'), getSeriesByLabel('Angles'))(data.bma_metrics),
    keyFrames,
    peakTimingFrame,
};
export const generic = {
    abbreviatedUnit(metric) {
        return (metric['Unit Abbreviation'] ||
            metric.Units.replace(/[()]/g, '').substring(0, 5));
    },
    abbreviatedLabel(metric) {
        return metric.replace('Left', 'L.').replace('Right', 'R.');
    },
};
