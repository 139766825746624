<script>
  import {
    worldSettingsVisible,
    metricIndices,
    selectedLiveMetrics,
    liveMetricPanelWidth,
    liveMetricWidthClass,
  } from '../../../../stores/stores';
  import { currentTime } from '../../../../stores/playerStore';
  import { trial } from '../../../../stores/trial';
  import { resize } from '../../../../utils/resize';
  import IconButton from '../../ui/IconButton.svelte';
  import { tick } from 'svelte';
  import { fly } from 'svelte/transition';
  import { updateSelectedMetrics } from '../../../../utils/metrics';
  import TableCell from './TableCell.svelte';
  import { formatTimeStamp } from '../../../../utils/asensei';
  export let data;
  export let asenseiFile;
  export let metricOffsetY;

  let liveMetricsPanel;
  let liveMetricsButton;
  let content;

  let panelWidth = $liveMetricPanelWidth;
  let widthClass = $liveMetricWidthClass;

  let liveMetricsOpen = false;

  let metrics = [];

  let events = (asenseiFile?.events || [])
    .map((event) => ({
      ...event,
      timeStamp: formatTimeStamp(event, data),
      isCollapsed: true,
    }))
    .filter(({ timeStamp }) => timeStamp !== 0);

  // reactive declaration updates metrics array whenever data changes (i.e., when new trial is selected)
  $: {
    metrics = updateSelectedMetrics({
      selectedLiveMetrics: $selectedLiveMetrics,
      metricIndices: $metricIndices,
      trial: $trial,
      data,
    });
  }

  $: {
    if (liveMetricsButton) {
      liveMetricsButton.style.top = `${metricOffsetY}px`;
    } else if (liveMetricsPanel) {
      liveMetricsPanel.style.top = `${metricOffsetY}px`;
    }
  }

  // save values in local storage so the Live Metrics will default to previously-selected values
  $: localStorage.setItem('liveMetricPanelWidth', JSON.stringify(panelWidth));
  $: localStorage.setItem('liveMetricWidthClass', widthClass);

  // opens/closes the Live Metrics panel
  async function toggleVisibility() {
    if (liveMetricsOpen) {
      liveMetricsOpen = false;
    } else {
      liveMetricsOpen = true;
      await tick;
      liveMetricsPanel.style.width = `${panelWidth}px`;
    }
  }

  // adds/removes classes to container in order to handle responsive resizing of metric boxes
  function handleResize(e) {
    if (
      e.detail.x >= window.innerWidth - 720 &&
      e.detail.x <= window.innerWidth - 240
    ) {
      if (metrics.length >= 2 && e.detail.x >= window.innerWidth - 300) {
        widthClass = 'c2';
      } else if (metrics.length >= 3 && e.detail.x >= window.innerWidth - 420) {
        widthClass = 'c3';
      } else if (metrics.length >= 4 && e.detail.x >= window.innerWidth - 540) {
        widthClass = 'c4';
      } else if (metrics.length >= 5 && e.detail.x >= window.innerWidth - 660) {
        widthClass = 'c5';
      } else if (metrics.length >= 6) {
        widthClass = 'c6';
      }
      panelWidth = window.innerWidth - e.detail.x;
      liveMetricsPanel.style.width = `${panelWidth}px`;
    }
  }

</script>

{#if liveMetricsOpen}
  <div
    style="height: 325px;"
    bind:this={liveMetricsPanel}
    transition:fly={{ x: 500, duration: 200 }}
    class="liveMetrics panel shadow"
    class:fade={$worldSettingsVisible}>
    <div class="wrapper">
      <div use:resize on:resize={handleResize} class="dragHandle" />
      <div class="nav">
        <div class="asensei-container">
          <img
            alt="logo"
            src="assets/images/asensei/asenseiLogo.png"
            width="24"
            height="24" />

          <img
            alt="name"
            src="assets/images/asensei/asenseiName.png"
            width="65"
            height="15"
            style="margin-left: 4px;" />
        </div>

        <IconButton on:click={toggleVisibility} icon="close-max" />
      </div>

      <div bind:this={content} class="content {widthClass}">
        <table class="table">
          <thead>
            <tr class="table-row">
              <th colspan={2} class="tableTitle">Key Frame</th>
              <th class="tableTitle">Comment</th>
              <th />
            </tr>
          </thead>
          <tbody class="tbodyScroll">
            {#each events as event}
              <tr class="table-row">
                <TableCell isCollapsed={event.isCollapsed}>
                  {event.timeStamp}
                </TableCell>

                <TableCell isCollapsed={event.isCollapsed}>
                  <IconButton
                    class="md"
                    icon="next-keyframe"
                    borderless={true}
                    on:click={() => {
                      currentTime.frame(event.timeStamp);
                    }} />
                </TableCell>

                <TableCell isCollapsed={event.isCollapsed}>
                  {event.comment}
                </TableCell>

                <TableCell isCollapsed={event.isCollapsed}>
                  <IconButton
                    icon={event.isCollapsed ? 'down' : 'up'}
                    fill="#F4E230"
                    borderless={true}
                    on:click={() => {
                      event.isCollapsed = !event.isCollapsed;
                    }} />
                </TableCell>
              </tr>
            {/each}
          </tbody>
        </table>
      </div>
    </div>
  </div>
{:else}
  <div
    bind:this={liveMetricsButton}
    class="liveMetricsButton shadow asensei-button"
    class:fade={$worldSettingsVisible}>
    <button on:click={toggleVisibility}>
      <div class="asensei-container">
        <img
          alt="logo"
          src="assets/images/asensei/asenseiLogo.png"
          height="36"
          width="36" />

        <img
          alt="name"
          src="assets/images/asensei/asenseiName.png"
          height="24"
          width="80" />
      </div>
    </button>
  </div>
{/if}

<style>
  .liveMetrics {
    position: absolute;
    top: 186px;
    right: 0px;
    height: auto;
    width: auto;
    box-sizing: border-box;
    border-radius: 8px 0px 0px 8px;
    border: 1px solid var(--color-gray-lighter);
    border-left: none;
    border-right: none;
  }

  .liveMetricsButton {
    position: absolute;
    top: 186px;
    right: -100px;
    height: auto;
    width: auto;
    transform: rotate(90deg);
    transform-origin: top left;
    background: none;
    border-radius: 0px 0px 8px 8px;
  }

  .liveMetricsButton button {
    height: 44px;
    margin: 0;
    padding: 0px 24px;
    border-radius: 0px 0px 8px 8px;
  }

  .dragHandle {
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    cursor: ew-resize;
  }

  .tableTitle {
    color: var(--color-primary-main);
  }

  .table-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    width: 100%;
  }

  .table tbody {
    margin-top: 5px;
  }

  .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: var(--color-white);
    border-radius: 0px 8px 0px 8px;
    box-sizing: border-box;
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    border-bottom: 1px solid var(--color-gray-lighter);
    width: 100%;
    height: 50vh;
  }

  :global(.table tbody tr) {
    width: 100%;
  }

  :global(.table tbody tr td:nth-child(1)) {
    width: 40px;
  }
  :global(.table tbody tr td:nth-child(2)) {
    width: 80px;
    margin-right: 4px;
    justify-content: flex-end;
  }
  :global(.table tbody tr td:nth-child(3)) {
    display: flex;
    width: 100%;
    align-items: baseline;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
    overflow: hidden;
    text-align: start;
  }

  :global(.table tbody tr td:nth-child(4)) {
    width: 40px;
  }

  :global(.tableTitle:nth-child(2)) {
    text-align: start;
    margin-left: 8px;
  }

  :global(.collapsed) {
    height: 40px;
  }

  :global(.uncollapsed) {
    height: 100%;
  }

  .nav {
    display: flex;
    padding: 0px 12px 0px 16px;
    border-radius: 8px 0px 0px 0px;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background-color: var(--color-gray-lighter);
  }

  .nav div {
    display: flex;
    align-items: center;
  }

  .tbodyScroll {
    height: 262px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }

  .fade {
    opacity: 0;
    pointer-events: none;
    transition: opacity 250ms ease-in;
  }

  .asensei-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
    width: 124px;
  }

  .asensei-button {
    right: -176px;
  }

</style>
