<script>
  import Menu from './Menu.svelte';
  import { onDestroy, tick } from 'svelte';
  import { hoist } from '../../../utils/hoist.ts';

  export let items = [];
  export let value = '';
  let isOpen = false;
  let dropdown;
  let toggle;
  let menu;
  export let align = 'bottom right'; // anchor position for hoisting the menu

  // Close the dropdown menu if user clicks anywhere else in the document body
  function handleWindowEvent(e) {
    if (!dropdown.contains(e.target)) {
      isOpen = false;
    }
  }

  function handleKeydown(e) {
    if (e.key === 'Escape') {
      isOpen = false;
    }
  }

  // Hoist the dropdown to the top level of the DOM so it will appear on top of everything else
  $: if (isOpen) {
    handleHoist();
  }

  async function handleHoist() {
    await tick(); // needed so getBoundingClientRect will give correct coordinates
    hoist(menu, toggle, align, 4); // there is a display bug here - shouldn't need to do this twice... need to investigate more.
  }

  function handleResize() {
    if (isOpen) {
      handleHoist();
    }
  }

  onDestroy(() => {
    if (menu && menu.parentNode) {
      menu.parentNode.removeChild(menu);
    }
  });

</script>

<svelte:window on:resize={handleResize} />
<svelte:body on:click={handleWindowEvent} on:keydown={handleKeydown} />

<div bind:this={dropdown} class="dropdown">
  <div bind:this={toggle} class="toggle" on:click={() => (isOpen = !isOpen)}>
    <slot name="toggle">
      <button class="dropdownButton">{value ? value : 'Select'}</button>
    </slot>
  </div>
  {#if isOpen}
    <div bind:this={menu} class="menu shadow">
      <slot name="menu">
        <Menu on:select {items} />
      </slot>
    </div>
  {/if}
</div>

<style>
  .toggle {
    margin-bottom: 6px;
  }

  .menu {
    position: absolute;
    max-height: 300px;
    min-width: 250px;
    background: var(--color-white);
    overflow-y: auto;
  }

</style>
