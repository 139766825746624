<script>
  import { onMount } from 'svelte';
  import { createEventDispatcher } from 'svelte';
  import Button from '../Button.svelte';
  import IconButton from '../IconButton.svelte';

  import { dateMap } from '../../../../stores/stores';

  export let selected = null;

  let month;
  let date;
  let year;

  let days = [];

  const dispatch = createEventDispatcher();

  const dayLabels = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
  const numDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const isLeapYear = (year) => {
    return year % 400 === 0 || (year % 4 === 0 && year % 100 != 0);
  };

  // return number of days in the month
  function getMonthDays(month, year) {
    return month !== 1 ? numDays[month] : isLeapYear(year) ? 29 : 28;
  }

  // return number of trials on a given day
  function getTrials(month, date, year) {
    let datestring =
      ('0' + (month + 1).toString()).slice(-2) +
      '-' +
      ('0' + date.toString()).slice(-2) +
      '-' +
      year.toString();
    return $dateMap.has(datestring) ? $dateMap.get(datestring).length : null;
  }

  // generates the array that populates the calendar
  function generateDays(month, year) {
    let days = [];

    // pad the array so it starts on the right day
    let padding = new Date(year, month, 1).getDay();
    for (let i = 0; i < padding; i++) {
      days.push({ date: null, trials: null });
    }

    for (let date = 1; date <= getMonthDays(month, year); date++) {
      days.push({
        date: date,
        trials: getTrials(month, date, year),
      });
    }

    return days;
  }

  function nextMonth() {
    if (month == 11) {
      month = 0;
      year += 1;
    } else {
      month += 1;
    }
    days = generateDays(month, year);
  }

  function prevMonth() {
    if (month == 0) {
      month = 11;
      year -= 1;
    } else {
      month -= 1;
    }
    days = generateDays(month, year);
  }

  function handleSelect(day) {
    if (!day) {
      selected = null;
    } else if (day.trials) {
      selected = new Date(year, month, day.date);
    }
    dispatch('select', {
      selected: selected,
    });
  }

  onMount(() => {
    if (selected) {
      month = selected.getMonth();
      date = selected.getDate();
      year = selected.getFullYear();
      days = generateDays(month, year);
    } else {
      let today = new Date();
      month = today.getMonth();
      date = today.getDate();
      year = today.getFullYear();
      days = generateDays(month, year);
    }
  });

</script>

<div class="container shadow">
  <div class="nav">
    <IconButton class="lg" icon="left-previous" on:click={prevMonth} />
    <span class="header">{monthNames[month]} {year}</span>
    <IconButton class="lg" icon="right-next" on:click={nextMonth} />
  </div>
  <div class="dayLabels">
    {#each dayLabels as day}
      <div>{day}</div>
    {/each}
  </div>
  <div class="dates">
    {#each days as day}
      <div
        class={day.trials ? 'day selectable' : 'day'}
        on:click={() => handleSelect(day)}>
        <div class="date">{day.date ? day.date : ''}</div>
        <div class={day.trials ? 'trials' : ''}>
          {day.trials ? day.trials : ''}
        </div>
      </div>
    {/each}
  </div>
  <div class="alldates">
    <Button class="sm" on:click={() => handleSelect(null)}>Reset</Button>
  </div>
</div>

<style>
  .container {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 12px 12px 12px 16px;
    background: var(--color-white);
    border: var(--color-gray-lighter);
    border-radius: 8px;
    color: var(--color-gray-dark);
  }

  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
  }

  .header {
    font-size: var(--typography-size-small);
    font-weight: var(--typography-weight-light);
  }

  .dayLabels {
    display: flex;
    align-items: center;
  }

  .dayLabels div {
    width: 40px;
    height: 36px;
    margin-right: 2px;
    line-height: 36px;
    box-sizing: border-box;
    font-size: var(--typography-size-xsmall);
    text-align: center;
  }

  .dates {
    display: flex;
    flex-wrap: wrap;
  }

  .day {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40px;
    margin-right: 2px;
    height: 54px;
    box-sizing: border-box;
  }

  .day.selectable {
    cursor: pointer;
  }

  .date {
    font-size: var(--typography-size-xsmall);
  }

  .trials {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 28px;
    background: var(--color-white);
    color: var(--color-primary-main);
    font-weight: var(--typography-weight-bold);
    border: 1px solid var(--color-gray-lighter);
    border-radius: 28px;
    transition: transform 0.1s ease;
  }

  .day.selectable:hover .date {
    font-weight: var(--typography-weight-bold);
  }

  .day.selectable:hover .trials {
    transform: scale(1.15);
  }

  .alldates {
    display: flex;
    justify-content: center;
    align-items: center;
  }

</style>
